<template>
  <div>
    <OuterHeader />
    <main role="main">
      <div id="main-wrapper" class="login-wrap">
        <div class="container-fluid">
          <div class="row justify-content-md-center bg-white" style="height:100vh !important;">
            <div class="col-lg-10 bg-white py-4 mt-3">
              <div class="row justify-content-center">
                <div class="col-lg-8 mt-5">
                  <div class="card card-body shadow">
                    <div class="
                        card-header
                        bg-white
                        rounded-10
                        text-center
                        border-0
                      ">
                      <h4 class="mb-0">Register</h4>
                    </div>
                    <div class="pt-lg-3">
                      <form class="form-style floating-form">
                        <Form @submit="signup">
                          <div class="row">
                            <div class="col-md-6 mb-3">
                              <div class="floating-form floating-form-error">
                                <div class="floating-form-group">
                                  <Field type="text" id="fname" name="firstname"
                                    :class="detail.first_name ? 'filled' : ''" v-model="detail.first_name"
                                    rules="required:first name" :validateOnInput="true" />
                                  <label for="fname">First Name</label><span class="text-danger">*</span>
                                </div>
                              </div>
                              <ErrorMessage name="firstname" class="validation-msg mt-1" />
                            </div>
                            <div class="col-md-6 mb-3">
                              <div class="floating-form-group">
                                <Field type="text" :class="detail.last_name ? 'filled' : ''" id="lname" name="lastname"
                                  v-model="detail.last_name" rules="required:last name" :validateOnInput="true" />
                                <label for="lname">Last Name</label><span class="text-danger">*</span>
                              </div>
                              <ErrorMessage name="lastname" class="validation-msg mt-1" />
                            </div>
                           
                            <div class="col-md-6 mb-3">
                              <div class="floating-form floating-form-error">
                                <div class="floating-form-group">
                                  <Field type="text" id="gst_number_id" autocomplete="off" :maxlength="15"
                                    v-model="detail.gst_number" name="gst_number_id" rules="required:GST number|gst"
                                    :validateOnInput="true" v-on:keyup="checkGst()" />
                                  <label for="gst_number_id">GST</label>
                                </div>

                              </div>
                              <div class="d-flex flex-row align-items-center" v-if="has_gst_verifying == true">
                                <span class="spinner-grow spinner-grow-sm text-danger mr-2" role="status"
                                  aria-hidden="true"></span>
                                <span class="validation-msg mt-1">Verifying...</span>
                              </div>

                              <ErrorMessage name="gst_number" class="validation-msg mt-1" />
                            </div>
                            <div class="col-md-6 mb-3">
                              <div class="floating-form-group">
                                <Field type="text" id="compny_name" :class="detail.company_name ? 'filled' : ''" v-model="detail.company_name"
                                  rules="required:company" name="company" :validateOnInput="true" />
                                <label for="compny_name">Company Name</label><span class="text-danger">*</span>
                              </div>
                              <ErrorMessage name="company" class="validation-msg mt-1" />
                            </div>  

                            <div class="col-md-4 mb-3">
                              <div class="form-group">
                                <Field as="select" class="custom-select" v-model="detail.user_type" name="type"
                                  rules="required:user type,true">
                                  <option value="">Select UserType</option>
                                  <option value="BUYER">Buyer</option>
                                  <option value="SELLER">Seller</option>
                                  <option value="BROKER">Dealer</option>
                                  <option value="IMPOTER">Impoter</option>
                                </Field>
                              </div>
                              <ErrorMessage name="type" class="validation-msg mt-1" />
                            </div>

                            <div class="col-md-4 mb-3">
                              <div class="form-group">
                                <Field as="select" class="custom-select" v-model="detail.state_id" name="state"
                                  rules="required:state,true" @update:model-value="getDistrictList()">
                                  <option value="">Select State</option>
                                  <option v-for="(data, index) in stateList" :key="index" :value="data.id">
                                    {{ data.name }}
                                  </option>
                                </Field>
                              </div>
                              <ErrorMessage name="state" class="validation-msg mt-1" />
                            </div>

                            <div class="col-md-4 mb-3">
                              <div class="form-group">
                                <Field as="select" class="custom-select" v-model="detail.district_id" name="district"
                                  rules="required:district,true" :disabled="!detail.state_id">
                                  <option value="">Select District</option>
                                  <option v-for="(data, index) in districtList" :key="index" :value="data.id">
                                    {{ data.name }}
                                  </option>
                                </Field>
                              </div>
                              <ErrorMessage name="district" class="validation-msg mt-1" />
                            </div>

                            <div class="col-md-6 mb-3">
                              <div class="floating-form-group">
                                <Field type="email" id="email" name="email" v-model="detail.email"
                                  rules="required:email" :validateOnInput="true" />
                                <label for="email">Email</label><span class="text-danger">*</span>
                              </div>
                              <ErrorMessage name="email" class="validation-msg mt-1" />
                            </div>
                            <div class="col-md-6 mb-3">
                              <div class="floating-form-group">
                                <Field type="text" id="phone" name="phone" v-model="detail.mobile_number"
                                  rules="required:contact number|phone" :validateOnInput="true" maxlength="10" />
                                <label for="phone">Phone</label><span class="text-danger">*</span>
                              </div>
                              <ErrorMessage name="phone" class="validation-msg mt-1" />
                            </div>

                            <div class="col-md-6 mb-3">
                              <div class="floating-form-group mb-4">
                                <Field :type="newPasswordFieldType" id="password" name="password"
                                  v-model="detail.password" v-on:keydown.space="$event.preventDefault()"
                                  rules="required:password|minLength:6|password" :validateOnInput="true" />

                                <label for="password">Password</label><span class="text-danger">*</span>
                                <a class="psw-icon" v-on:click="switchVisibilityNew"><img :src="imageNew"
                                    width="20" /></a>
                              </div>
                              <ErrorMessage name="password" class="validation-msg mt-1" />
                            </div>
                            <div class="col-md-6 mb-3">
                              <div class="floating-form-group mb-4">
                                <Field :type="passwordFieldType" id="conf_password" name="confirm_password"
                                  v-on:keydown.space="$event.preventDefault()"
                                  rules="required:confirm password|confirmed:@password" :validateOnInput="true" />
                                <label for="conf_password">Confirm Password</label>
                                <span class="text-danger">*</span>
                                <a class="psw-icon" v-on:click="switchVisibility"><img :src="image" width="20" /></a>
                              </div>
                              <ErrorMessage name="confirm_password" class="validation-msg mt-1" />
                            </div>
                          </div>
                          <div class="mb-3">
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" true-value="Y" false-value="N"
                                v-model="is_checked" id="defaultCheck1" />
                              <label class="form-check-label" for="defaultCheck1">
                                By Clicking on Register button, you are agreeing
                                to our
                                <a href="https://api.yarnmart.co/terms-condition">Terms of Use</a>
                                ,
                                <a href="https://api.yarnmart.co/trading-rules">Trading Rules & Settlement
                                  Procedure</a>
                                and
                                <a href="https://api.yarnmart.co/data-policy">Data & Privacy.</a>
                              </label>
                            </div>
                          </div>
                          <div class="text-center">
                            <button class="btn btn-brand-1 mb-3" id="signup-button">
                              Register
                            </button>
                          </div>
                        </Form>
                      </form>
                    </div>
                    <div class="card-footer text-center bg-white border-0">
                      <div class="links">
                        Already A User?
                        <a href="javascript:void(0)" v-on:click="$router.push('/login')">Login</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import OuterHeader from "@/components/OuterHeader";
import { Form, Field, ErrorMessage } from "vee-validate";
import $ from "jquery";
export default {
  name: "SignUp",
  components: {
    Form,
    Field,
    ErrorMessage,
    OuterHeader,
    
  },
  data() {
    return {
      id: this.$route.params.id,
      newPasswordFieldType: "password",
      passwordFieldType: "password",
      stateList: [],
      districtList: [],
      detail: {
        user_type: localStorage.getItem("user_type"),
        designation: localStorage.getItem("designation"),
      },
      is_checked: "N",
      image: "/images/show.png",
      imageNew: "/images/show.png",
      is_importer: "N",
      has_gst_verifying: false
    };
  },
  mounted() {
    this.getStateList();
    if (localStorage.getItem('is_importer') && localStorage.getItem('is_importer') == 'Y') {
      this.detail.user_type = 'IMPOTER';
    }
    $(
      ".floating-form-group input,.floating-form-group textarea,.floating-form select"
    ).change(function () {
      if ($(this).val() != "") {
        $(this).addClass("filled");
      } else {
        $(this).removeClass("filled");
      }
    });
  },
  methods: {
    signup() {
      if (this.is_checked == "N") {
        this.$notify({
          type: "error",
          text: "Please select terms and conditions",
        });
        return false;
      }
      if (this.detail.user_type == "IMPOTER") {
        this.detail.is_importer = 'Y';
        this.detail.user_type = 'SELLER';
      } else {
        this.detail.is_importer = 'N';
      }
      this.$api
        .postAPI({
          _action: "signup",
          _body: this.detail,
          _buttonId: "signup-button",
        })
        .then((res) => {
          if (res && res.access_token) {
            localStorage.setItem("access_token", res.access_token);
            this.getMe();
          }
        })
        .catch(() => { });
    },
    getStateList() {
      this.$api
        .getAPI({
          _action: "states",
        })
        .then((res) => {
          this.stateList = res.list;
        })
        .catch(() => { });
    },
    getDistrictList() {
      this.$api
        .getAPI({
          _action: "districts/" + this.detail.state_id,
        })
        .then((res) => {
          this.districtList = res.list;
        })
        .catch(() => { });
    },


    checkGst() {
      if ( this.detail.gst_number && this.detail.gst_number.length > 1) {
        var state = this.detail.gst_number.slice(0, 2);
        for (var i = 0; i < this.stateList.length; i++) {
          if (state.toString() === this.stateList[i].gst_code.toString()) {
            this.detail.state_id = this.stateList[i].id;
            this.getDistrictList();
          }
        }
      }

      if (this.detail.gst_number.length === 15) {
        this.has_gst_verifying = true;
        this.$api
          .postAPI({
            _action: "check-verification",
            _body: {
              gstin: this.detail.gst_number,
              type: "GST",
            },
          })
          .then((res) => {
            console.log("check gst response", res);
            if (res) {
              this.has_gst_verifying = false;
              if (res.status === true) {
                this.detail.company_name = res?.info?.data?.data?.lgnm;
                this.$notify({
                  type: "success",
                  text: "GST number is valid",
                });
              } else {
                this.detail.company_name = "";
                this.$notify({
                  type: "error",
                  text: "GST number is not valid",
                });
              }
            }
          })
          .catch(() => {
            this.has_gst_verifying = false;
          });
      }
    },
    getMe() {
      this.$api
        .getAPI({
          _action: "me",
          _buttonId: "signup-button",
        })
        .then((res) => {
          if (res && res.info && res.info.user_type) {
            localStorage.setItem("user_type", res.info.user_type);
          } else {
            localStorage.removeItem("access_token");
            return;
          }
          
          localStorage.setItem("userId", res.info.id);
          localStorage.setItem("otp-from", "SIGNUP");
          localStorage.setItem("forgot_mobile", res.info.mobile_number);
          this.$router.push("/verification/"+ res.info.uuid);
          // this.$router.push("/verify-otp");
          // this.$router.push("/verification/"+ res.info.uuid); 
        })
        .catch(() => {
          localStorage.removeItem("access_token");
        });
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.image =
        this.image === "/images/show.png"
          ? "/images/hide.png"
          : "/images/show.png";
    },
    switchVisibilityNew() {
      this.newPasswordFieldType =
        this.newPasswordFieldType === "password" ? "text" : "password";
      this.imageNew =
        this.imageNew === "/images/show.png"
          ? "/images/hide.png"
          : "/images/show.png";
    },
  },
};
</script>
