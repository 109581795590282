<template>
  <div>
    <div class="page-content">
      <div class="row mb-4 justify-content-center">
        <div class="col-lg-12">
          <div class="row mb-md-4 mb-2 align-items-center">
            <div class="col-md-6 text-md-left text-center">
              <h2 class="page-title mb-md-0">
                <a class="cursor-pointer" v-on:click="this.$router.go(-1)">
                  <img src="/images/back-arrow.svg" width="17" class="mr-2 align-top" alt="" /></a>Payments
              </h2>
            </div>
            <div class="col-md-6 text-right d-flex justify-content-end">
              <input type="text" class="search-click" name="" v-on:keyup.enter="getList(0)" placeholder="search here..."
                v-model="keyword" />
            </div>
          </div>
          <ul class="nav nav-pills filter-tabs mb-3" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <a class="nav-link" id="pills-pending-tab" data-toggle="pill" href="#pills-pending" role="tab"
                aria-controls="pills-pending" aria-selected="true" :class="currentTab == 'PENDING' ? 'active' : ''"
                v-on:click="changeTab('PENDING')" style="cursor: pointer">Pending
              </a>
            </li>

            <li class="nav-item" role="presentation">
              <a class="nav-link" id="pills-completed-tab" data-toggle="pill" href="#pills-completed" role="tab"
                aria-controls="pills-completed" aria-selected="false" :class="currentTab == 'COMPLETED' ? 'active' : ''"
                v-on:click="changeTab('COMPLETED')" style="cursor: pointer">Completed</a>
            </li>
          </ul>

          <div>
            <div class="card rounded-l border-0 shadow" v-if="list.length > 0">
              <div class="card-body border-bottom expert-list" v-for="(data, index) in list" :key="index">
                <div class="row" @click="redirectTo(data)">
                  <div v-if="user_type == 'BUYER'" class="col-md-9">
                    <p class="mb-0 font-medium">
                      {{ data.quantity }}
                      (Kg)
                    </p>
                    <p class="mb-0 text-secondary" v-if="user_type == 'BUYER' || user_type == 'BROKER'">
                      {{ data.booking.seller.company_name }}
                    </p>
                    <p class="mb-0 text-secondary" v-if="user_type == 'SELLER'">
                      {{ data.booking.buyer.company_name }}
                    </p>
                    <p class="mb-0 text-secondary mb-1" v-if="user_type == 'BUYER' || user_type == 'BROKER'">
                      {{ data.booking.seller.district.name }},
                      {{ data.booking.seller.state.name }}
                    </p>
                    <p class="mb-0 text-secondary mb-1" v-if="user_type == 'SELLER'">
                      {{ data.booking.buyer.district.name }},
                      {{ data.booking.buyer.state.name }}
                    </p>
                    <!-- <p class="mb-0 font-14 text-secondary">
                      {{ data.booking.broker.name }} (Broker)
                    </p> -->
                  </div>
                  <div v-if="user_type == 'SELLER'" class="col-md-9"
                    v-on:click="$router.push('/seller-payment-detail/' + data.uuid)">
                    <p class="mb-0 font-medium">
                      {{ data.quantity }}
                      (Kg)
                    </p>
                    <p class="mb-0 text-secondary" v-if="user_type == 'BUYER' || user_type == 'BROKER'">
                      {{ data.booking.seller.company_name }}
                    </p>
                    <p class="mb-0 text-secondary" v-if="user_type == 'SELLER'">
                      {{ data.booking.buyer.company_name }}
                    </p>
                    <p class="mb-0 text-secondary mb-1" v-if="user_type == 'BUYER' || user_type == 'BROKER'">
                      {{ data.booking.seller.district.name }},
                      {{ data.booking.seller.state.name }}
                    </p>
                    <p class="mb-0 text-secondary mb-1" v-if="user_type == 'SELLER'">
                      {{ data.booking.buyer.district.name }},
                      {{ data.booking.buyer.state.name }}
                    </p>
                    <!-- <p class="mb-0 font-14 text-secondary">
                      {{ data.booking.broker.name }} (Broker)
                    </p> -->
                  </div>
                  <div class="col-md-3 text-right">
                    <p class="mb-1 font-14 text-secondary">
                      {{ data.ago_time }}
                    </p>
                    <p class="mb-1">
                      <span class="badge badge-light-yellow text-capitalize" style="background: #d9ffea; color: #1c9c57"
                        v-if="this.currentTab == 'COMPLETED'">{{ data.payment_status }}</span>
                    </p>
                    <p class="mb-0 font-14 text-secondary"></p>
                    <p class="mb-0 text-primary font-medium" v-if="currentTab == 'PENDING' && user_type == 'BUYER'">
                      ₹ {{ data.invoice_amount }}
                    </p>
                    <p class="mb-0 text-primary font-medium" v-if="currentTab == 'COMPLETED' && user_type == 'BUYER'">
                      ₹ {{ data.net_amount }}
                    </p>
                    <p class="mb-0 text-primary font-medium" v-if="user_type == 'SELLER'">
                      ₹ {{ data.paid_amount }}
                    </p>
                    
                  </div>
                </div>

                <div class="card-footer bg-transparent p-0 mt-3 text-center text-center" :class="currentTab == 'COMPLETED' ||
                  user_type == 'BROKER'
                  ? 'border-0'
                  : ''
                  ">
                  <button class="btn btn-brand-1 mt-3" id="login-button" style="cursor: pointer; min-width: 155px" v-if="currentTab == 'PENDING' &&
                    (user_type == 'BUYER' ||
                      (user_type == 'BROKER' && broker_view == 'BUYER')) &&
                    (designation == 'PRIMARY_OWNER' ||
                      designation == 'SECONDERY_OWNER' ||
                      designation == 'MANAGER')
                    " v-on:click="$router.push('/add-payment-detail/' + data.uuid)">
                    Upload Payment Details
                  </button>
                  <button class="btn btn-outline-danger mt-3 mr-3" id="login-button"
                    style="cursor: pointer; min-width: 155px" v-if="currentTab == 'PENDING' &&
                      user_type == 'SELLER' &&
                      (designation == 'PRIMARY_OWNER' || designation == 'SECONDERY_OWNER')
                      " v-on:click="showStatusPopup('REJECTED', data)">
                    Reject
                  </button>
                  <button class="btn btn-brand-1 mt-3" id="login-button" style="cursor: pointer; min-width: 155px" v-if="currentTab == 'PENDING' &&
                    user_type == 'SELLER' &&
                    (designation == 'PRIMARY_OWNER' ||
                      designation == 'SECONDERY_OWNER' ||
                      designation == 'MANAGER')
                    " v-on:click="showStatusPopup('APPROVED', data)">
                    Approve Payment
                  </button>


                </div>
              </div>
            </div>
          </div>
          <div class="row mx-0 mb-4">
            <div class="col-lg-12 mt-5 text-center">
              <div class="page-bottom-pagination text-right">
                <Pagination @page-change="pageChange" @items-per-page-change="itemsPerPageChange" ref="listPagination" />
              </div>
            </div>
          </div>
          <ErrorComponent @retry="getList(0)" ref="errorComponent" />
        </div>
      </div>
    </div>
  </div>
  <TextModal ref="statusModal" class="modal-small">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" v-on:click="$refs.statusModal.closeModal">
          <img src="/images/close.svg" width="15" />
        </button>
      </div>
      <div class="modal-body px-md-5">
        <h5 class="modal-title text-center font-22 mb-4">
          <span v-if="status == 'APPROVED'">Order Payment Approve</span>
          <span v-if="status == 'REJECTED'">Order Payment Reject</span>
        </h5>

        <p class="font-18 text-center ">
          Are you sure <br />
          you want to
          <span v-if="status == 'APPROVED'">approve order payment? </span>
          <span v-if="status == 'REJECTED'">reject order payment? </span>
        </p>
      </div>
      <div class="modal-footer justify-content-center border-0 pb-4 pt-0">
        <div class="row">
          <div class="col-md-6">
            <button type="button" class="btn btn-brand-1 btn-block mb-3" id="status-button" style="min-width: 115px"
              v-on:click="updateStatus()">
              Yes
            </button>
          </div>
          <div class="col-md-6">
            <button type="button" class="btn btn-outline-brand-1 btn-block mb-3" style="min-width: 115px"
              v-on:click="$refs.statusModal.closeModal">
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  </TextModal>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import TextModal from "@/components/TextModal.vue";
import ShareService from "../../services/ShareService";
import router from "../../router";
import Pagination from "@/components/Pagination.vue";
export default {
  name: "MyPayment",
  components: {
    ErrorComponent,
    TextModal, Pagination
  },
  data() {
    return {
      loader: true,
      offset: -1,
      list: [],
      currentTab: "PENDING",
      keyword: "",

      user_type: localStorage.getItem("user_type"),
      designation: localStorage.getItem("designation"),
      broker_view: localStorage.getItem("broker_view"),
      searchObj: {},
      status: "",
      order: {},
      payment_status: "",
    };
  },
  mounted() {
    this.user_type = localStorage.getItem("user_type");
    this.$store.commit("showSidebar", true);

    var user = this.$store.getters.getUser;
    this.$helperService.checkAddress(user);
    this.payment_status = ShareService.getMapValue("payment_status");
    if (!this.payment_status) {
      this.currentTab = "PENDING";
    } else {
      this.currentTab = this.payment_status;
    }
    this.getList(0);
  },
  methods: {
    redirectTo(data) {
      if (this.currentTab == "COMPLETED") {
        router.push('/payment-detail/' + data.uuid + '/' + data.booking.uuid);
      } else {
        router.push('/add-payment-detail/' + data.uuid)
      }
    },
    openInvoice(invoice) {
      if (invoice) {
        window.open(invoice);
      }
    },
    changeTab(tab) {
      this.currentTab = tab;
      this.getList(0);
    },
    showStatusPopup(type, order) {
      this.status = type;
      this.order = order;
      this.$refs.statusModal.showModal();
    },
    updateStatus() {
      if (this.status == "APPROVED") {
        this.$api
          .postAPI({
            _action:
              "seller/booking/" +
              this.order.booking.uuid +
              "/order/" +
              this.order.uuid +
              "/payment-approved",
            _buttonId: "status-button",
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.$refs.statusModal.closeModal();
            this.list = [];
            this.getList(0);
          })
          .catch(() => { });
      } else if (this.status == "REJECTED") {
        this.$api
          .postAPI({
            _action:
              "seller/booking/" +
              this.order.booking.uuid +
              "/order/" +
              this.order.uuid +
              "/payment-rejected",
            _buttonId: "status-button",
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.$refs.statusModal.closeModal();
            this.list = [];
            this.getList(0);
          })
          .catch(() => { });
      }
    },

    retry() {
      this.getList(0);
    },
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    getList(page) {
      this.loader = true;
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }


      this.list = [];
      this.searchObj.offset = page;
      this.searchObj.status = this.currentTab;

      if (this.keyword) {
        this.searchObj.keyword = this.keyword;
      }

      this.list = [];
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }
      var url = "";
      if (this.user_type == "BUYER" || this.user_type == "BROKER") {
        url = "buyer/payments";
      } else {
        url = "seller/payments";
      }
      this.$api
        .getAPI({
          _action: url,
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.offset = res.next_offset;
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No Payment Found");
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
  },
};
</script>

