<template>
  <div v-show="isShowModal">
    <div class="spx-modal h-100 justify-content-center">
      <div class="spx-modal-body mx-auto">
        <div class="modal-header pt-0">
          <button type="button" class="close" v-on:click="closeModal">
            <span aria-hidden="true">&times;</span>
          </button>
          <h5 class="modal-title font-medium">{{ title }}</h5>
        </div>
        <div class="modal-body">
          <h2 class="text-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="#f33" class="bi bi-exclamation-octagon" viewBox="0 0 16 16">
  <path d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353L4.54.146zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1H5.1z"/>
  <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/>
</svg>
          </h2>
          <p class="text-center">{{ body }} ?</p>
        </div>
        <div class="modal-footer pb-0 justify-content-center">
          <button
            type="button"
            class="btn btn-brand-1"
            id="delete-button"
            :disabled="showLoader"
            v-on:click="onClick"
          >
            <i v-if="showLoader" class="fa fa-spinner fa-spin"></i>Yes
          </button>
          <button
            type="button"
            class="btn cancel-btn"
            id="cancel-button"
           
            v-on:click="closeModal"
          >
            No
          </button>
        </div>
      </div>
    </div>
    <div class="spx-modal-background"></div>
  </div>
</template>
<script>
export default {
  name: "DeleteModal",
  data() {
    return {
      isShowModal: false,
      showLoader: false,
      title: "Alert",
      body: "Are you sure you want to delete",
      obj: {},
    };
  },
  mounted() {},
  methods: {
    showModal(title, body, obj) {
      this.title = title;
      this.body = body;
      this.obj = obj;
      this.isShowModal = true;
    },
    closeModal() {
      this.isShowModal = false;
      this.removeLoader();
    },
    onClick() {
      this.$emit("remove", this.obj);
      this.showLoader = true;
    },
    removeLoader() {
      this.showLoader = false;
    },
  },
};
</script>