<template>
  <div>
    <div class="page-content">
      <div class="row mb-4 justify-content-center">
        <div class="col-lg-6">
          <div class="row mb-md-4 mb-2 align-items-center">
            <div class="col-md-6 text-md-left text-center">
              <h2 class="page-title mb-md-0">
                <a class="cursor-pointer" v-on:click="this.$router.go(-1)">
                  <img
                    src="/images/back-arrow.svg"
                    width="17"
                    class="mr-2 align-top"
                    alt="" /></a
                ><span v-if="uuid">Edit Company</span><span v-else>Add Company</span>
              </h2>
            </div>
            <div class="col-md-6 text-right d-flex justify-content-end"></div>
          </div>
          <div class="card mb-4 rounded-l border-0 shadow-sm py-4">
            <div class="">
              <div class="row justify-content-center">
                <div class="col-md-11">
                  <Form @submit="signup" class="form-style floating-form">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="floating-form-error mb-3">
                          <div class="border-box">
                            <label for="company_name">Company Name</label>
                            <Field
                              type="company_name"
                              id="company_name"
                              name="company_name"
                              class="filled form-control"
                              v-model="detail.company_name"
                              rules="required:company name"
                              :validateOnInput="true"
                            />
                          </div>
                          <ErrorMessage name="company_name" class="validation-msg mt-1" />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="floating-form-error mb-3">
                          <div class="border-box">
                            <!--floating-form-group-->
                            <label for="gst_number">GST Number</label>
                            <Field
                              type="gst_number"
                              id="gst_number"
                              name="gst_number"
                              class="filled form-control"
                              v-model="detail.gst_number"
                              rules="required:GST number|gst"
                              :validateOnInput="true"
                            />
                          </div>
                          <ErrorMessage name="gst_number" class="validation-msg mt-1" />
                        </div>
                      </div>
                      <div class="col-md-12 mb-3">
                        <h3>Billing Address</h3>
                      </div>
                      <div class="col-md-12">
                        <div class="floating-form-error mb-3">
                          <div class="border-box">
                            <label for="address">Address</label>
                            <Field
                              type="address"
                              id="billing_address"
                              name="billing_address"
                              class="filled form-control"
                              v-model="billing.address"
                              rules="required:address"
                              :validateOnInput="true"
                            />
                          </div>
                          <ErrorMessage
                            name="billing_address"
                            class="validation-msg mt-1"
                          />
                        </div>
                      </div>

                      <div class="col-lg-12">
                        <label>Select State <span class="text-danger">*</span></label>
                        <div class="form-group">
                          <Field
                            as="select"
                            class="custom-select"
                            v-model="billing.state_id"
                            name="billing_state"
                            rules="required:state,true"
                            @change="getDistrictList('BILLING')"
                          >
                            <option value="">Select State</option>
                            <option
                              v-for="(data, index) in stateBillingList"
                              :key="index"
                              :value="data.id"
                            >
                              {{ data.name }}
                            </option>
                          </Field>
                        </div>
                        <ErrorMessage name="billing_state" class="validation-msg mt-1" />
                      </div>

                      <div class="col-lg-12">
                        <label>Select District <span class="text-danger">*</span></label>
                        <div class="form-group">
                          <Field
                            as="select"
                            class="custom-select"
                            v-model="billing.district_id"
                            name="billing_district"
                            rules="required:district,true"
                            :disabled="!billing.state_id"
                          >
                            <option value="">Select District</option>
                            <option
                              v-for="(data, index) in districtBillingList"
                              :key="index"
                              :value="data.id"
                            >
                              {{ data.name }}
                            </option>
                          </Field>
                        </div>
                        <ErrorMessage
                          name="billing_district"
                          class="validation-msg mt-1"
                        />
                      </div>

                      <template v-if="user_type == 'BUYER'">
                        <div class="col-md-12">
                          <h3>Shipping Address</h3>
                        </div>

                        <div class="col-md-12">
                          <div class="floating-form-error mb-3">
                            <div class="floating-form-group">
                              <Field
                                type="address"
                                id="address"
                                name="shipping_address"
                                class="filled"
                                v-model="shipping.address"
                                rules="required:address"
                                :validateOnInput="true"
                              />
                              <label for="address">Address</label>
                            </div>
                            <ErrorMessage
                              name="shipping_address"
                              class="validation-msg mt-1"
                            />
                          </div>
                        </div>

                        <div class="col-lg-12">
                          <label>Select State <span class="text-danger">*</span></label>
                          <div class="form-group">
                            <Field
                              as="select"
                              class="custom-select"
                              v-model="shipping.state_id"
                              name="shipping_state"
                              rules="required:state,true"
                              @change="getDistrictList('SHIPPING')"
                            >
                              <option value="">Select State</option>
                              <option
                                v-for="(data, index) in stateShippingList"
                                :key="index"
                                :value="data.id"
                              >
                                {{ data.name }}
                              </option>
                            </Field>
                          </div>
                          <ErrorMessage
                            name="shipping_state"
                            class="validation-msg mt-1"
                          />
                        </div>

                        <div class="col-lg-12">
                          <label
                            >Select District <span class="text-danger">*</span></label
                          >
                          <div class="form-group">
                            <Field
                              as="select"
                              class="custom-select"
                              v-model="shipping.district_id"
                              name="shipping_district"
                              rules="required:district,true"
                              :disabled="!shipping.state_id"
                            >
                              <option value="">Select District</option>
                              <option
                                v-for="(data, index) in districtShippingList"
                                :key="index"
                                :value="data.id"
                              >
                                {{ data.name }}
                              </option>
                            </Field>
                          </div>
                          <ErrorMessage
                            name="shipping_district"
                            class="validation-msg mt-1"
                          />
                        </div>
                      </template>

                      <div class="col-md-12 mt-3">
                        <button class="btn btn-brand-1 btn-block mb-3" id="signup-button">
                          Save
                        </button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import $ from "jquery";
import router from "../../router";
export default {
  name: "AddCompany",
  components: { Form, Field, ErrorMessage },
  data() {
    return {
      uuid: this.$route.params.uuid,
      user_type: localStorage.getItem("user_type"),
      detail: {
        company_name: "",
        gst_number: "",
      },
      billing: {
        address_type: "BILLING",
      },
      shipping: {
        address_type: "SHIPPING",
      },
      stateShippingList: [],
      districtShippingList: [],
      stateBillingList: [],
      districtBillingList: [],
    };
  },
  mounted() {
    this.getStateList();
    this.user_type = localStorage.getItem("user_type");
    this.is_importer = localStorage.getItem("is_importer");

    $(
      ".floating-form-group input,.floating-form-group textarea,.floating-form select"
    ).change(function () {
      if ($(this).val() != "") {
        $(this).addClass("filled");
      } else {
        $(this).removeClass("filled");
      }
    });
  },
  methods: {
    getStateList(type) {
      this.$api
        .getAPI({
          _action: "states",
        })
        .then((res) => {
          if (type && type == "BILLING") {
            this.stateBillingList = res.list;
          }
          if (type && type == "SHIPPING") {
            this.stateShippingList = res.list;
          }
          if (!type) {
            this.stateBillingList = res.list;
            this.stateShippingList = res.list;
          }
          if (this.uuid) {
            this.getDetail();
          }
        })
        .catch(() => {});
    },
    getDistrictList(type) {
      var url = "";
      if (type == "SHIPPING") {
        url = "districts/" + this.shipping.state_id;
      } else {
        url = "districts/" + this.billing.state_id;
      }
      this.$api
        .getAPI({
          _action: url,
        })
        .then((res) => {
          if (type && type == "BILLING") {
            this.districtBillingList = res.list;
          }
          if (type && type == "SHIPPING") {
            this.districtShippingList = res.list;
          }
        })
        .catch(() => {});
    },
    getDetail() {
      this.$api
        .getAPI({
          _action: "company/" + this.$route.params.uuid,
        })
        .then((res) => {
          this.detail = res.info;
          if (this.detail.billing_address && this.detail.billing_address.state_id) {
            this.billing = this.detail.billing_address;
            this.getDistrictList("BILLING");
          }
          if (this.detail.delivery_address && this.detail.delivery_address.state_id) {
            this.shipping = this.detail.delivery_address;
            this.getDistrictList("SHIPPING");
          }
        })
        .catch(() => {});
    },

    signup() {
      this.detail.state_id = this.billing.state_id;
      this.detail.district_id = this.billing.district_id;
      this.detail.is_importer = this.is_importer;
      if (this.uuid) {
        this.$api
          .putAPI({
            _action: "company/" + this.uuid,
            _body: this.detail,
            _buttonId: "signup-button",
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.saveBillingAddress(res.company_id);
          })
          .catch(() => {});
      } else {
        this.$api
          .postAPI({
            _action: "company",
            _body: this.detail,
            _buttonId: "signup-button",
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.saveBillingAddress(res.company_id);
          })
          .catch(() => {});
      }
    },
    saveBillingAddress(company_id) {
      this.billing.company_id = company_id;
      if (this.billing.id) {
        this.$api
          .putAPI({
            _action: "company/" + company_id + "/address",
            _body: this.billing,
            _buttonId: "signup-button",
          })
          .then(() => {
            if (this.user_type == "BUYER") {
              this.saveShippingAddress(company_id);
            } else {
              router.push("/manage-company");
            }
          });
      } else {
        this.$api
          .postAPI({
            _action: "company/address",
            _body: this.billing,
            _buttonId: "signup-button",
          })
          .then(() => {
            if (this.user_type == "BUYER") {
              this.saveShippingAddress(company_id);
            } else {
              router.push("/manage-company");
            }
          });
      }
    },
    saveShippingAddress(company_id) {
      this.shipping.company_id = company_id;

      if (this.shipping.id) {
        this.$api
          .putAPI({
            _action: "company/" + company_id + "/address",
            _body: this.shipping,
            _buttonId: "signup-button",
          })
          .then(() => {
            router.push("/manage-company");
          });
      } else {
        this.$api
          .postAPI({
            _action: "company/address",
            _body: this.shipping,
            _buttonId: "signup-button",
          })
          .then(() => {
            router.push("/manage-company");
          });
      }
    },
  },
};
</script>

