<template>
  <div>
    <div v-if="showMessage" class="text-center">
      <div class="w-50 d-inline-block pt-5 pb-5 mt-5 mb-5">
        <div v-if="new_title">
          <div v-if="user_type == 'BUYER'">
            <!-- <h3 class="mb-4">
              <strong>Five easy steps to buy goods.</strong>
            </h3>
            <ul style="line-height: 21px; padding-left: 18px">
              <li style="">Home - View & Bid the Trade.</li>
              <li style="">Bookings - Pending - Edit/Cancel the Biding.</li>
              <li style="">Orders - Delivered - Start Unloading</li>
              <li style="">Order - Approve the Delivery or Raise the Query.</li>
              <li style="">Payment - Upload the Payment Details.</li>
            </ul> -->
            <h3><strong>No Record Found</strong></h3>
          </div>
          <div v-if="user_type == 'BROKER'">
            <!-- <h3 class="mb-4">
              <strong>Five easy steps to buy goods.</strong>
            </h3>
            <ul style="line-height: 21px; padding-left: 18px">
              <li style="">Home - View & Bid the Trade.</li>
              <li style="">Bookings - Pending - Edit/Cancel the Biding.</li>
              <li style="">Orders - Delivered - Start Unloading</li>
              <li style="">Order - Approve the Delivery or Raise the Query.</li>
              <li style="">Payment - Upload the Payment Details.</li>
            </ul> -->
            <h3><strong>No Record Found</strong></h3>
          </div>
          <div v-if="user_type == 'SELLER'">
            <h3 class="mb-4">
              <strong>Five easy steps to sell your goods.</strong>
            </h3>
            <ul style="line-height: 21px; padding-left: 18px">
              <li style="">Home - (+) Upload the Trade</li>
              <li style="">Bookings - Accept/Reject the Bid</li>
              <li style="">Orders - Pending - Add Vehicle</li>
              <li style="">Order - Open- fill Dispatch Details & Dispatch</li>
              <li style="">Payment - Receive & Approve the Payment</li>
            </ul>
          </div>
          <!-- <div v-if="user_type == 'BROKER'">
            <h3 class="mb-4">
              <strong>{{ title }}</strong>
            </h3>
            <p v-if="subtitle">{{ subtitle }}</p>
            <a v-if="subtitle" @click="onClick()" href="javascript: void(0);"
              >Please Retry</a
            >
          </div> -->
        </div>
        <div v-else>
          <h3 class="mb-4">
            <strong>{{ title }}</strong>
          </h3>
          <p v-if="subtitle">{{ subtitle }}</p>
          <a v-if="subtitle" @click="onClick()" href="javascript: void(0);"
            >Please Retry</a
          >
        </div>
      </div>
    </div>
    <div
      v-show="loader"
      class="text-center"
      style="background: white; border-radius: 0 0 16px 16px"
    >
      <img src="/img/loader.gif" />
    </div>
  </div>
</template>
<script>
export default {
  name: "ErrorComponent",
  data() {
    return {
      showMessage: false,
      loader: true,
      title: "",
      subTitle: "",
      new_title: false,

      user_type: localStorage.getItem("user_type"),
      designation: localStorage.getItem("designation"),
    };
  },
  methods: {
    onClick() {
      this.updateShowMessage();
      this.$emit("retry");
    },
    updateShowMessage() {
      this.showMessage = !this.showMessage;
    },
    setTitle(title, new_title, user_type) {
      if (title) {
        this.showMessage = true;
        this.setSubtitle();
        this.title = title;
        this.new_title = new_title;
        this.user_type = user_type;
      } else {
        this.setSubtitle(true);
        this.title = "Error";
        this.updateShowMessage();
      }
    },
    isShowMessage() {
      return this.showMessage;
    },
    setSubtitle(isShowSubtitle) {
      this.subtitle = isShowSubtitle ? "Looks like something went wrong!" : undefined;
    },
    updateLoader(isShowLoader) {
      if (isShowLoader) {
        this.showMessage = false;
      }
      this.loader = isShowLoader;
    },
    setData(res, title, new_title, user_type) {
      if (res) {
        if (!res.list || res.list.length <= 0) {
          this.setTitle(title, new_title, user_type);
        }
      } else {
        this.setTitle();
      }
      this.updateLoader(false);
    },
  },
};
</script>
