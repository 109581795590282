import {
  createStore
} from "vuex";
const store = createStore({
  state() {
    return {
      userInfo: {},
      isShowSidebar: false,
      summary: {
        company: [],
        businessGroup: [],
        webinars: [],
        activity: []
      },
      keyword: "",
      bid: 0
    };
  },
  mutations: {
    setUser(state, payload) {
      state.userInfo = payload;
    },
    setBid(state, payload) {
      state.bid = payload;
    },
    setSummary(state, payload) {
      console.log(payload)
      state.summary = payload;
    },
    showSidebar(state, payload) {
      state.isShowSidebar = payload;
    },
    keyword(state, payload) {
      console.log(payload)
      state.keyword = payload;
    }
  },
  getters: {
    getUser(state) {
      return state.userInfo;
    },
    getBid(state) {
      return state.bid;
    },
    getShowSidebar(state) {
      return state.isShowSidebar;
    },
    getSummary(state) {
      console.log("gete")
      console.log(state.summary)
      return state.summary;
    },
  },
  actions: {},
});

export default store;