<template>
  <div class="page-content">
    <div class="row mb-4 justify-content-center">
      <div class="col-lg-12">
        <div class="row mb-md-4 mb-2 align-items-center">
          <div class="col-md-6 text-md-left text-center">
            <h2 class="page-title mb-md-0">
              <a class="cursor-pointer" v-on:click="this.$router.go(-1)">
                <img src="/images/back-arrow.svg" width="17" class="mr-2 align-top" alt="" /></a>Payment Detail
            </h2>
          </div>
          <div class="col-md-6 text-right d-flex justify-content-end"></div>
        </div>
        <div class="card mb-4 rounded-l border-0 shadow-sm">
          <form class="form-style floating-form">
            <div class="row justify-content-center">
              <div class="col-md-12">
                <div class="row mt-4">
                  <div class="col-6 text-center">
                    <h5 class="font-medium text-secondary">
                      Booking No. &nbsp; {{ detail.booking.booking_no }}
                    </h5>
                  </div>
                  <div class="col-6 text-center">
                    <h5 class="font-medium text-secondary">
                      Order No. &nbsp; {{ detail.order_no }}
                    </h5>
                  </div>
                </div>

                <hr />
                <div class="mt-2">
                  <div class="mb-4">
                    <div class="row justify-content-center">
                      <div class="col-md-7">
                        <Form @submit="signup" class="form-style floating-form">
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row mb-2">
                                <div class="col-md-6">
                                  <p class="mb-0 text-secondary">Invoice Amount</p>
                                </div>
                                <div class="col-md-6">
                                  <p class="mb-0 font-medium">
                                    ₹ {{ detail.fractions.invoice_amount }}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <!-- <div class="card-footer bg-white">
                              <div class="row mb-2">
                                <div class="col-md-6">
                                  <p class="mb-0 text-secondary">Quality Deductions</p>
                                </div>
                                <div class="col-md-6">
                                  <p class="mb-0 font-medium">
                                    ₹
                                    {{ detail.fractions.quatlity_qty_deduction }}
                                  </p>
                                </div>
                              </div>
                            </div> -->
                          </div>
                          <div class="row ml-2">
                            <h4>Other Deductions</h4>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <div class="floating-form-error mb-3">
                                <div class="floating-form-group">
                                  <Field type="title" id="title" name="title" :class="detail.fractions.other_deduction_title
                                    ? 'filled'
                                    : ''
                                    " v-on:keyup="this.getDetail()" v-model="detail.fractions.other_deduction_title"
                                    disabled />
                                  <label for="title">Ex. TDS</label>
                                </div>
                                <ErrorMessage name="title" class="validation-msg mt-1" />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="floating-form-error mb-3">
                                <div class="floating-form-group">
                                  <Field type="value" id="value" name="value" :class="detail.fractions.other_deduction_value == 0 ||
                                    detail.fractions.other_deduction_value
                                    ? 'filled'
                                    : ''
                                    " v-on:keyup="this.getDetail()" v-model="detail.fractions.other_deduction_value"
                                    rules="numeric:other deduction value" :validateOnInput="true" disabled />
                                  <label for="value">Other Deduction Value</label>
                                </div>
                                <ErrorMessage name="value" class="validation-msg mt-1" />
                              </div>
                            </div>
                          </div>
                          <div class="card mb-3">
                            <div class="card-body">
                              <div class="row mb-2">
                                <div class="col-md-6">
                                  <p class="mb-0 text-secondary">Balance</p>
                                </div>
                                <div class="col-md-6">
                                  <p class="mb-0 font-medium">
                                    ₹ {{ detail.fractions.balance }}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div class="card-footer bg-white">
                              <div class="row mb-2">
                                <div class="col-md-6">
                                  <p class="mb-0 text-secondary">
                                    {{ detail.fractions.deduction_title }}
                                  </p>
                                </div>
                                <div class="col-md-6" v-if="detail.fractions.has_cd_editable">
                                  <div class="floating-form-error mb-3">
                                    <div class="floating-form-group">
                                      <Field type="cash_discount_value" id="cash_discount_value"
                                        name="cash_discount_value" :class="detail.fractions.cash_discount_value
                                          ? 'filled'
                                          : ''
                                          " v-model="detail.fractions.cash_discount_value"
                                        v-on:keyup="this.getDetail()" rules="required:cash discount value|numeric"
                                        :validateOnInput="true" />
                                      <label for="cash_discount_value">Cash Discount Value</label>
                                    </div>
                                    <ErrorMessage name="cash_discount_value" class="validation-msg mt-1" />
                                  </div>
                                </div>
                                <div class="col-md-6" v-else>
                                  <p class="mb-0 font-medium">-</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="card mb-3">
                            <div class="card-header">
                              To Be Paid
                              <span class="font-medium text-secondary float-right">₹ {{ detail.fractions.net_amount
                              }}</span>
                            </div>
                          </div>
                          <div class="card mb-3">
                            <div class="card-header">
                              Payment Date
                              <span class="font-medium text-secondary float-right">
                                {{ $helperService.getFormatedDate(detail.fractions.payment_date) }}</span>
                            </div>
                          </div>
                          <div class="card mb-3">
                            <div class="card-header">
                              Paid Amount
                              <span class="font-medium text-secondary float-right">₹ {{ detail.fractions.paid_amount
                              }}</span>
                            </div>
                          </div>
                          <div class="card mb-3">
                            <div class="card-header">
                              UTR Number
                              <span class="font-medium text-secondary float-right">
                                {{ detail.fractions.utr_number }}</span>
                            </div>
                          </div>


                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
// import Datepicker from "@vuepic/vue-datepicker";
import moment from "moment";
import router from "../../router";
export default {
  name: "SellerPaymentDetail",
  components: { Form, Field, ErrorMessage },
  data() {
    return {
      user_type: localStorage.getItem("user_type"),
      designation: localStorage.getItem("designation"),
      detail: {
        order_no: "",
        booking: {
          trade: {},
        },
        fractions: {
          other_deduction_title: "",
          other_deduction_value: "",
          deduction_title: "",
        },
      },
      uuid: this.$route.params.uuid,
    };
  },
  mounted() {
    this.user_type = localStorage.getItem("user_type");
    this.getDetail();
  },
  methods: {
    notBeforeDate(date) {
      var current_date = new Date();
      return date < current_date.setDate(current_date.getDate() - 1);
    },
    getDetail() {
      var obj = {
        other_deduction_title: this.detail.fractions.other_deduction_title
          ? this.detail.fractions.other_deduction_title
          : "",
        other_deduction_value: this.detail.fractions.other_deduction_value
          ? this.detail.fractions.other_deduction_value
          : 0,
        cash_discount_value: this.detail.fractions.cash_discount_value
          ? this.detail.fractions.cash_discount_value
          : 0,
      };
      this.$api
        .getAPI({
          _action: "seller/order/" + this.uuid + "/payment",
          _body: obj,
        })
        .then((res) => {
          this.detail = res;
          this.detail.fractions.paid_amount = this.detail.fractions.net_amount;
          if (this.detail.payment_date) {
            this.detail.fractions.payment_date = new Date(this.detail.payment_date);
          } else {
            this.detail.fractions.payment_date = null;
          }
          this.detail.fractions.utr_number = this.detail.utr_number;
        })
        .catch(() => { });
    },
    signup() {
      if (!this.detail.fractions.payment_date) {
        this.$notify({
          type: "error",
          text: "Please select payment date",
        });
        return false;
      }
      this.detail.fractions.payment_date = moment(
        this.detail.fractions.payment_date
      ).format("yyyy-MM-DD");
      this.$api
        .postAPI({
          _action: "/buyer/order/" + this.uuid + "/payment",
          _body: this.detail.fractions,
          _buttonId: "signup-button",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          router.push("/payment");
        })
        .catch(() => { });
    },
  },
};
</script>

