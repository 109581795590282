<template>
  <div>
    <div class="page-content">
      <div class="row mb-4 justify-content-center">
        <div class="col-lg-8">
          <div class="row mb-md-4 mb-2 align-items-center">
            <div class="col-md-6 text-md-left text-center">
              <h2 class="page-title mb-md-0">
                <a class="cursor-pointer" v-on:click="this.$router.go(-1)">
                  <img src="/images/back-arrow.svg" width="17" class="mr-2 align-top" alt="" /></a>Users
              </h2>
            </div>
            <div class="col-md-6 text-right d-flex justify-content-end">
              <input type="text" class="search-click" name="" v-on:keyup.enter="getList(0)" placeholder="search here..."
                v-model="keyword" />
              <button type="button " class="btn btn-light ml-3 text-primary px-3 text-nowrap" style="
                  background: #fff;
                  box-shadow: 1px 1px 4px #0000001c;
                  height: 38px;
                " v-on:click="$router.push('add-employee')" v-if="designation == 'PRIMARY_OWNER' ||
                  designation == 'SECONDERY_OWNER'
                  ">
                Add User
              </button>
            </div>
          </div>

          <div>
            <div class="card rounded-l border-0 shadow" v-if="list.length > 0">
              <div class="card-body border-bottom expert-list" v-for="(data, index) in list" :key="index">
                <div class="row align-items-center">
                  <div class="col-md-9">
                    <div class="d-flex">
                      <div class="">
                        <img :src="data.profile_img
                          ? data.profile_img
                          : '/images/profile.svg'
                          " class="
                            img-fluid
                            rounded-circle
                            border border-light
                            shadow-sm
                            mr-2
                          " style="
                            border-width: 1px !important;
                            object-fit: cover;
                          " alt="image thumb" id="img_hoder_1" width="45" height="45" />
                        <div></div>
                      </div>
                      <div>
                        <p class="mb-0 font-medium">{{ data.user.name }}</p>
                        <p class="text-secondary font-14" v-if="data.designation == 'SECONDERY_OWNER'">
                          Secondary Owner
                        </p>
                        <p class="text-secondary font-14" v-else-if="data.designation == 'MANAGER'">
                          Manager
                        </p>
                        <p class="text-secondary font-14" v-else-if="data.designation == 'VIEW'">
                          View
                        </p>
                        <p class="text-secondary font-14" v-else>
                          {{ data.designation }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 text-right">
                    <a v-if="designation == 'PRIMARY_OWNER' ||
                      designation == 'SECONDERY_OWNER'
                      " href="javascript:void(0)" class="font-20 text-danger"
                      @click="showDeletePopup(data.user.uuid)"><i class="fa fa-trash"></i></a>
                  </div>
                  <hr />
                </div>
              </div>
            </div>
          </div>
          <div class="row mx-0 mb-4">
            <div class="col-lg-12 mt-5 text-center">
              <div class="page-bottom-pagination text-right">
                <Pagination @page-change="pageChange" @items-per-page-change="itemsPerPageChange" ref="listPagination" />
              </div>
            </div>
          </div>
          <ErrorComponent @retry="getList(0)" ref="errorComponent" />
        </div>
      </div>
    </div>
  </div>
  <DeleteModal @remove="deleteRecord" ref="deleteModal" />
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import DeleteModal from "@/components/DeleteModal";
import Pagination from "@/components/Pagination.vue";
// import router from "../../router";
export default {
  name: "MyEmployee",
  components: {
    ErrorComponent,
    DeleteModal, Pagination
  },
  data() {
    return {
      loader: true,
      offset: -1,
      list: [],
      keyword: "",

      user_type: localStorage.getItem("user_type"),
      designation: localStorage.getItem("designation"),
    };
  },
  mounted() {
    this.user_type = localStorage.getItem("user_type");
    this.$store.commit("showSidebar", true);
    var user = this.$store.getters.getUser;
    this.$helperService.checkAddress(user);
    this.getList(0);
  },
  methods: {
    showDeletePopup(id) {
      this.$refs.deleteModal.showModal(
        "Delete User",
        "Are you sure you want to delete",
        id
      );
    },
    deleteRecord(id) {
      this.$api
        .deleteAPI({
          _action: "/employee/" + id,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteModal.closeModal();
            this.getList(0);
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {
          this.$refs.deleteModal.removeLoader();
        });
    },
    retry() {
      this.getList(0);
    },
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    getList(page) {
      this.loader = true;
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }


      this.list = [];
      var searchObj = { searchObj: "" };

      if (this.keyword) {
        searchObj.keyword = this.keyword;
      }

      searchObj.offset = page;
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }

      this.$api
        .getAPI({
          _action: "employees",
          _body: searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.offset = res.next_offset;
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No Employee Found");
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
  },
};
</script>

