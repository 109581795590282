<template>
  <div>
    <div class="page-content">
      <div class="row mb-4 justify-content-center">
        <div class="col-lg-12">
          <div class="row mb-md-4 mb-2 align-items-center">
            <div class="col-md-6 text-md-left text-center">
              <h2 class="page-title mb-md-0">
                <a class="cursor-pointer" v-on:click="this.$router.go(-1)">
                  <img
                    src="/images/back-arrow.svg"
                    width="17"
                    class="mr-2 align-top"
                    alt="" /></a
                >Bookings
              </h2>
            </div>
            <div class="col-md-6 text-right d-flex justify-content-end pr-0">
              <input
                type="text"
                class="search-click"
                name=""
                v-on:keyup.enter="getList(0)"
                placeholder="search here..."
                v-model="keyword"
              />
            </div>
          </div>
          <ul class="nav nav-pills filter-tabs mb-3" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <a
                class="nav-link"
                id="pills-pending-tab"
                data-toggle="pill"
                href="#pills-pending"
                role="tab"
                aria-controls="pills-pending"
                aria-selected="true"
                :class="currentTab == 'PENDING' ? 'active' : ''"
                v-on:click="changeTab('PENDING')"
                style="cursor: pointer"
                >Pending
              </a>
            </li>
            <li class="nav-item" role="presentation">
              <a
                class="nav-link"
                id="pills-accepted-tab"
                data-toggle="pill"
                href="#pills-accepted"
                role="tab"
                aria-controls="pills-accepted"
                aria-selected="false"
                :class="currentTab == 'ACCEPTED' ? 'active' : ''"
                v-on:click="changeTab('ACCEPTED')"
                style="cursor: pointer"
                >Approved</a
              >
            </li>
            <li class="nav-item" role="presentation">
              <a
                class="nav-link"
                id="pills-rejected-tab"
                data-toggle="pill"
                href="#pills-rejected"
                role="tab"
                aria-controls="pills-rejected"
                aria-selected="false"
                :class="currentTab == 'REJECTED' ? 'active' : ''"
                v-on:click="changeTab('REJECTED')"
                style="cursor: pointer"
                >Rejected</a
              >
            </li>
          </ul>
          <div>
            <div class="card rounded-l border-0 shadow" v-if="list.length > 0">
              <div
                class="card-body border-bottom expert-list"
                v-for="(data, index) in list"
                :key="index"
              >
                <div class="row">
                  <div class="col-md-6">
                    <p class="mb-1" v-if="user_type == 'BUYER'">
                      <b>{{ data.seller.company_name }}</b>
                    </p>
                    <p class="mb-1" v-if="user_type == 'BUYER'">
                      <span class="text-muted">
                        {{ data.seller.district.name }},
                        {{ data.seller.state.name }}</span
                      >
                    </p>
                    <p class="mb-1" v-if="user_type == 'SELLER'">
                      {{ data.buyer.company_name }}
                    </p>
                    <p class="mb-1" v-if="user_type == 'SELLER'">
                      <span class="text-muted"
                        >{{ data.buyer.district.name }}, {{ data.buyer.state.name }}</span
                      >
                    </p>

                    <p class="mb-1 font-medium">
                      <b>{{ data.quantity }} Kg</b>
                    </p>
                    <!-- <p class="mb-1">
                      <b>{{ data.product.name }}</b>
                    </p> -->
                    <p class="mb-1">
                      <b>{{ data.product.yarn.name }}</b>
                    </p>
                    <p class="mt-2 mb-0">
                      <span class="badge-btn" v-if="data.product.sub_yarn">{{
                        data.product.sub_yarn.name
                      }}</span>
                      <span class="m-2 badge-btn" v-if="data.product.yarn_value">{{
                        data.product.yarn_value.value
                      }}</span>
                    </p>

                    <p class="mt-2 mb-0">
                      <template v-for="item in data.product.list" :key="item">
                        <span class="badge-btn mr-1">
                          {{ item.composition.name }} {{ item.value }}%
                        </span>
                      </template>
                    </p>
                    <p>
                      <span class="text-gery">
                        Payment Terms - <b>{{ data.payment_term.title }}</b>
                      </span>
                      <span class="text-gery">
                        Delivery Terms - <b>{{ data.delivery_term.title }}</b>
                      </span>
                    </p>
                    <p v-if="data.delivery_address">
                      <span class="text-gery">
                        Delivery Address -
                        <b
                          >{{ data.delivery_address.address }},
                          {{ data.delivery_address.district.name }},
                          {{ data.delivery_address.state.name }}</b
                        >
                      </span>
                    </p>
                    <p v-if="data.broker">
                      <span class="text-gery">
                        Refer to Dealer - <b>{{ data.broker.company_name }}</b>
                      </span>
                      <span v-if="data.dealer_status == 'PENDING'" style="color: #e7a704">
                        <b>Pending</b>
                      </span>
                      <span
                        v-if="data.dealer_status == 'ACCEPTED'"
                        style="color: #95c72d"
                      >
                        <b>Accepted</b>
                      </span>
                      <span
                        v-if="data.dealer_status == 'REJECTED'"
                        style="color: #ed3e07"
                      >
                        <b>Rejected</b>
                      </span>
                    </p>
                  </div>
                  <div class="col-md-6 text-right" v-if="user_type == 'BUYER'">
                    <p class="mb-2 font-14">
                      {{ data.ago_time }}
                    </p>
                    <p class="mb-2 text-primary font-medium">₹ {{ data.bid_rate }}</p>

                    <p class="mb-1 text-primary font-medium" v-if="data.freight_amount">
                      ₹{{ data.freight_amount }} (Freight)
                    </p>
                    <p class="my-3">
                      <span
                        class="badge badge-light-yellow text-capitalize"
                        v-if="
                          booking_status == 'PENDING' &&
                          (data.status == 'PENDING' || data.freight_status == 'PENDING')
                        "
                        style="border-radius: 20px; background: #e7a704; color: #fff"
                        ><span v-if="data.status == 'PENDING'">Pending</span
                        ><span v-else-if="data.freight_status == 'PENDING'"
                          >Pending for confirmation</span
                        ></span
                      >
                      <span
                        class="badge badge-light-yellow text-capitalize"
                        style="border-radius: 20px; background: #95c72d; color: #fff"
                        v-else-if="data.status == 'ACCEPTED'"
                        >Accepted</span
                      >
                      <span
                        class="badge badge-light-yellow text-capitalize"
                        style="border-radius: 20px; background: #ed3e07; color: #fff"
                        v-if="data.status == 'REJECTED'"
                        >Rejected</span
                      >
                    </p>
                    <p class="mb-1">
                      <button
                        class="btn accepted-btn"
                        id="login-button"
                        style="cursor: pointer; min-width: 55px"
                        v-on:click="showStatusPopup('ACCEPT', data)"
                        v-if="
                          user_type == 'BUYER' &&
                          booking_status == 'PENDING' &&
                          ((data.user_type == 'BROKER' &&
                            data.freight_status == 'PENDING' &&
                            data.status == 'ACCEPTED') ||
                            data.counter_by != user_type) &&
                          (designation == 'PRIMARY_OWNER' ||
                            designation == 'SECONDERY_OWNER')
                        "
                      >
                        Accept
                      </button>

                      <button
                        class="btn rejected-btn"
                        id="login-button"
                        style="cursor: pointer; min-width: 55px"
                        v-if="
                          user_type == 'BUYER' &&
                          booking_status == 'PENDING' &&
                          ((data.user_type == 'BROKER' &&
                            data.freight_status == 'PENDING' &&
                            data.status == 'ACCEPTED') ||
                            data.counter_by != user_type) &&
                          (designation == 'PRIMARY_OWNER' ||
                            designation == 'SECONDERY_OWNER')
                        "
                        v-on:click="showStatusPopup('REJECT', data)"
                      >
                        Reject
                      </button>
                      <button
                        class="btn accepted-btn counter-btn"
                        id="login-button"
                        style="cursor: pointer; min-width: 120px"
                        v-on:click="bid(data)"
                        v-if="
                          user_type == 'BUYER' &&
                          booking_status == 'PENDING' &&
                          data.status == 'PENDING' &&
                          ((data.user_type == 'BROKER' &&
                            data.freight_status == 'PENDING' &&
                            data.status == 'ACCEPTED') ||
                            data.counter_by != user_type) &&
                          (designation == 'PRIMARY_OWNER' ||
                            designation == 'SECONDERY_OWNER')
                        "
                      >
                        Counter Offer
                      </button>
                    </p>
                  </div>
                  <div class="col-md-6 text-right" v-if="user_type == 'SELLER'">
                    <p class="mb-1 font-14 text-secondary">
                      {{ data.ago_time }}
                    </p>
                    <p class="mb-1 text-primary font-medium">₹{{ data.bid_rate }}</p>
                    <p class="mb-1 text-primary font-medium" v-if="data.freight_amount">
                      ₹{{ data.freight_amount }} (Freight)
                    </p>
                    <p class="my-3">
                      <span
                        class="badge badge-light-yellow text-capitalize"
                        v-if="
                          booking_status == 'PENDING' &&
                          (data.status == 'PENDING' || data.freight_status == 'PENDING')
                        "
                        style="background: #e7a704; border-radius: 20px; color: #fff"
                        ><span v-if="data.status == 'PENDING'">Pending</span
                        ><span v-else-if="data.freight_status == 'PENDING'"
                          >Pending for confirmation</span
                        ></span
                      >
                      <span
                        class="badge badge-light-yellow text-capitalize"
                        style="background: #95c72d; border-radius: 20px; color: #fff"
                        v-else-if="data.status == 'ACCEPTED'"
                        >Accepted</span
                      >
                      <span
                        class="badge badge-light-yellow text-capitalize"
                        style="background: #ed3e07; border-radius: 20px; color: #fff"
                        v-if="data.status == 'REJECTED'"
                        >Rejected</span
                      >
                    </p>

                    <p
                      class="mb-1"
                      v-if="data.status == 'PENDING' && data.counter_by != user_type"
                    >
                      <button
                        class="btn accepted-btn"
                        id="login-button"
                        style="cursor: pointer; min-width: 55px"
                        v-on:click="showStatusPopup('ACCEPT', data)"
                        v-if="
                          user_type == 'SELLER' &&
                          booking_status == 'PENDING' &&
                          data.counter_by != 'BROKER' &&
                          (designation == 'PRIMARY_OWNER' ||
                            designation == 'SECONDERY_OWNER')
                        "
                      >
                        Accept
                      </button>

                      <button
                        class="btn rejected-btn"
                        id="login-button"
                        style="cursor: pointer; min-width: 55px"
                        v-if="
                          user_type == 'SELLER' &&
                          booking_status == 'PENDING' &&
                          data.counter_by != 'BROKER' &&
                          (designation == 'PRIMARY_OWNER' ||
                            designation == 'SECONDERY_OWNER')
                        "
                        v-on:click="showStatusPopup('REJECT', data)"
                      >
                        Reject
                      </button>

                      <button
                        class="btn accepted-btn counter-btn"
                        id="login-button"
                        style="cursor: pointer; min-width: 120px"
                        v-on:click="bid(data)"
                        v-if="
                          user_type == 'SELLER' &&
                          booking_status == 'PENDING' &&
                          data.counter_by != 'BROKER' &&
                          (designation == 'PRIMARY_OWNER' ||
                            designation == 'SECONDERY_OWNER')
                        "
                      >
                        Counter Offer
                      </button>
                    </p>

                    <p
                      class="my-3"
                      v-if="
                        data.status == 'PENDING' &&
                        data.counter_by != user_type &&
                        booking_status == 'PENDING' &&
                        user_type == 'SELLER' &&
                        !data.broker &&
                        data.user_type != 'BROKER'
                      "
                    >
                      <button
                        type="button "
                        class="btn btn-brand-1"
                        style="min-width: 200px !important"
                        v-on:click="dealer(data)"
                      >
                        Refer to Dealer
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mx-0 mb-4">
            <div class="col-lg-12 mt-5 text-center">
              <div class="page-bottom-pagination text-right">
                <Pagination
                  @page-change="pageChange"
                  @items-per-page-change="itemsPerPageChange"
                  ref="listPagination"
                />
              </div>
            </div>
          </div>
          <ErrorComponent @retry="getList(0)" ref="errorComponent" />
        </div>
      </div>
    </div>

    <TextModal ref="statusModal" class="modal-small">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" v-on:click="$refs.statusModal.closeModal">
            <img src="/images/close.svg" width="15" />
          </button>
        </div>
        <div class="modal-body px-md-5">
          <h5 class="modal-title font-22 mb-4 text-center">
            {{ $helperService.getTitleCase(status) }} Bid
          </h5>

          <p class="font-18 text-center">
            Are you sure <br />
            you want to {{ $helperService.getTitleCase(status) }} bid ?
          </p>
          <div class="row" v-if="status == 'REJECT'">
            <div class="col-md-12">
              <label for="" class="float-left">Reasons </label>
              <div class="form-group">
                <select
                  name=""
                  class="form-control custom-select"
                  id=""
                  v-model="Narration.narration"
                >
                  <option value="Required higher Rate">Required higher Rate</option>
                  <option value="Goods are sold">Goods are sold</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>
            <div class="col-md-12" v-show="Narration.narration == 'Other'">
              <label for="" class="float-left">Other</label>
              <div class="form-group">
                <input type="text" v-model="OtherNarration" class="form-control" />
              </div>
            </div>
          </div>
          <div class="" v-if="status == 'ACCEPT' && user_type == 'SELLER'">
            <Form class="form-style floating-form">
              <div class="row">
                <div class="col-md-12">
                  <div class="card mb-3">
                    <div class="row mb-2">
                      <div class="col-md-6">
                        <p class="mb-0 text-secondary">Quantity (in Kg)</p>
                      </div>
                      <div class="col-md-6">
                        <p class="mb-0 font-medium">
                          {{ detail.quantity }}
                        </p>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-md-6">
                        <p class="mb-0 text-secondary">Price (Per Kg)</p>
                      </div>
                      <div class="col-md-6">
                        <p class="mb-0 font-medium">₹ {{ detail.bid_rate }}</p>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-md-6">
                        <p class="mb-0 text-secondary">Delivery Address</p>
                      </div>
                      <div class="col-md-6">
                        <p class="mb-0 font-medium">
                          {{ detail.delivery_address.address }},
                          {{ detail.delivery_address.district.name }},
                          {{ detail.delivery_address.state.name }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="floating-form-error mb-3">
                    <div class="floating-form-group">
                      <Field
                        type="text"
                        id="freight"
                        name="freight"
                        :class="freight_amount ? 'filled' : ''"
                        rules="required:freight|decimal"
                        v-model="freight_amount"
                      />
                      <label for="freight">Freight Amount</label
                      ><span class="text-danger">*</span>
                    </div>
                    <ErrorMessage name="freight" class="validation-msg mt-1" />
                  </div>
                </div>
              </div>
            </Form>
          </div>

          <div class="modal-footer justify-content-center border-0 pb-4 pt-0">
            <div class="row">
              <div class="col-md-6">
                <button
                  type="button"
                  class="btn btn-brand-1 btn-block mb-3"
                  id="status-button"
                  style="min-width: 115px"
                  v-on:click="updateStatus()"
                >
                  Yes
                </button>
              </div>
              <div class="col-md-6">
                <button
                  type="button"
                  class="btn cancel-btn mb-3"
                  style="min-width: 115px"
                  v-on:click="$refs.statusModal.closeModal"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </TextModal>
    <TextModal ref="addModal">
      <Form ref="addForm" @submit="checkOut()">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              v-on:click="this.$refs.addModal.closeModal()"
            >
              <img src="/images/close.svg" width="15" />
            </button>
          </div>
          <div class="modal-body floating-form" style="max-height: 500px; overflow: auto">
            <h4 class="font-22 modal-tital text-center mb-4">Search Dealer</h4>
            <div class="row">
              <div class="col-md-12 align-self-end">
                <div class="floating-form-error mb-3">
                  <label>Search Dealer Name <span class="text-danger">*</span></label>
                  <div class="floating-form-group mb-0">
                    <Field
                      as="select"
                      class="custom-select"
                      name="dealer"
                      id="dealer"
                      rules="required:dealer,true"
                      v-model="dealerId"
                      :validateOnInput="true"
                    >
                      <option value="">Select Dealer</option>
                      <option v-for="(obj, i) in dealerList" :key="i" :value="obj.id">
                        {{ obj.company_name }}
                      </option>
                    </Field>
                  </div>
                  <ErrorMessage name="dealer" class="validation-msg" />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer border-0 justify-content-center">
            <button class="btn btn-brand-1 px-4" id="bid-button">Send</button>
          </div>
        </div>
      </Form>
    </TextModal>
    <TextModal ref="counterModal">
      <Form ref="addForm" @submit="counter()">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              v-on:click="this.$refs.counterModal.closeModal()"
            >
              <img src="/images/close.svg" width="15" />
            </button>
          </div>
          <div class="modal-body floating-form" style="max-height: 500px; overflow: auto">
            <h4 class="font-22 modal-tital text-center mb-4">Book Trade</h4>
            <div class="row">
              <div class="col-md-12 align-self-end">
                <div class="floating-form-error mb-3">
                  <div class="floating-form-group">
                    <Field
                      type="text"
                      id="quantity"
                      name="quantity"
                      :class="book.quantity ? 'filled' : ''"
                      v-model="book.quantity"
                      :rules="'required:quantity|numeric|minValue:' + detail.min_quantity"
                      :validateOnInput="true"
                    />
                    <label for="quantity">Quantity</label>
                    <span class="text-danger">*</span>
                  </div>
                  <ErrorMessage name="quantity" class="validation-msg mt-1" />
                </div>
              </div>

              <div class="col-md-6 align-self-end">
                <div class="floating-form-error mb-3">
                  <label>Payment Term <span class="text-danger">*</span></label>
                  <div class="floating-form-group mb-0">
                    <Field
                      as="select"
                      class="custom-select"
                      name="paymentTerm"
                      id="paymentTerm"
                      rules="required:Payment Term"
                      v-model="book.payment_term_id"
                      :validateOnInput="true"
                    >
                      <option value="">Select Payment Term</option>
                      <option
                        v-for="(obj, i) in paymentTermList"
                        :key="i"
                        :value="obj.id"
                      >
                        {{ obj.title }}
                      </option>
                    </Field>
                  </div>
                  <ErrorMessage name="paymentTerm" class="validation-msg mt-1" />
                </div>
              </div>

              <div class="col-md-6 align-self-end">
                <div class="floating-form-error mb-3">
                  <label>Delivery Term <span class="text-danger">*</span></label>
                  <div class="floating-form-group mb-0">
                    <Field
                      as="select"
                      class="custom-select"
                      name="deliveryTerm"
                      id="deliveryTerm"
                      rules="required:Delivery Term"
                      v-model="book.delivery_term_id"
                      :validateOnInput="true"
                    >
                      <option value="">Select Delivery Term</option>
                      <option
                        v-for="(obj, i) in deliveryTermList"
                        :key="i"
                        :value="obj.id"
                      >
                        {{ obj.title }}
                      </option>
                    </Field>
                  </div>
                  <ErrorMessage name="deliveryTerm" class="validation-msg mt-1" />
                </div>
              </div>
              <div class="col-md-12 align-self-end">
                <div class="floating-form-error mb-3">
                  <label>Delivery Address <span class="text-danger">*</span></label>
                  <div class="floating-form-group mb-0">
                    <Field
                      as="select"
                      class="custom-select"
                      name="deliveryAddress"
                      id="deliveryAddress"
                      rules="required:Delivery Address"
                      v-model="book.delivery_address_id"
                      :validateOnInput="true"
                      :disabled="user_type == 'SELLER'"
                    >
                      <option value="">Select Delivery Address</option>
                      <option
                        v-for="(obj, i) in deliveryAddressList"
                        :key="i"
                        :value="obj.id"
                      >
                        {{ obj.address }}, {{ obj.district.name }},{{ obj.state.name }}
                      </option>
                    </Field>
                  </div>
                  <ErrorMessage name="deliveryAddress" class="validation-msg mt-1" />
                </div>
              </div>
              <div class="col-lg-12 align-self-end">
                <label>Bid Rate Per (Kg) <span class="text-danger">*</span></label>
                <div class="floating-form-error mb-3">
                  <div class="floating-form-group">
                    <Field
                      type="text"
                      name="bid_rate"
                      id="bid_rate"
                      :class="book.bid_rate ? 'filled' : ''"
                      v-model="book.bid_rate"
                      rules="required:bid rate|numeric"
                      :validateOnInput="true"
                    />
                    <label for="bid_rate">Enter Bid Rate</label>
                  </div>
                  <ErrorMessage name="bid_rate" class="validation-msg mt-1" />
                </div>
              </div>

              <div class="col-md-12">
                <div class="floating-form-error mb-3">
                  <div class="floating-form-group">
                    <Field
                      type="text"
                      id="freight"
                      name="freight"
                      :class="book.freight_amount > -1 ? 'filled' : ''"
                      rules="required:freight amount|decimal|minValue:0"
                      v-model="book.freight_amount"
                      :disabled="user_type == 'BUYER'"
                    />
                    <label for="freight">Freight Amount</label
                    ><span class="text-danger">*</span>
                  </div>
                  <ErrorMessage name="freight" class="validation-msg mt-1" />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer border-0 justify-content-center">
            <button class="btn btn-brand-1 px-4" id="counter-button">Save</button>
          </div>
        </div>
      </Form>
    </TextModal>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
import router from "../../router";
import TextModal from "@/components/TextModal.vue";
import ShareService from "../../services/ShareService";
import Pagination from "@/components/Pagination.vue";
export default {
  name: "MyBooking",
  components: {
    ErrorComponent,
    TextModal,
    Pagination,
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      loader: true,
      offset: -1,
      list: [],
      currentTab: "PENDING",
      keyword: "",

      user_type: localStorage.getItem("user_type"),
      designation: localStorage.getItem("designation"),
      count: 0,
      booking_status: "PENDING",
      status: "",
      OtherNarration: "",
      Narration: { narration: "Required higher Rate" },
      dealerId: "",
      dealerList: [],
      bookingDetail: {},
      actual_status: "",
      freight_amount: "",
      paymentTermList: [],
      deliveryTermList: [],
      deliveryAddressList: [],
      book: {
        seller_id: "",
        product_id: this.$route.params.id,
        quantity: "",
        bid_rate: "",
        payment_term_id: "",
        delivery_term_id: "",
        delivery_address_id: "",
        freight_amount: "",
      },
      detail: {},
    };
  },
  mounted() {
    this.user_type = localStorage.getItem("user_type");
    this.$store.commit("showSidebar", true);

    var user = this.$store.getters.getUser;
    this.actual_status = user.user_type;
    this.$helperService.checkAddress(user);
    this.booking_status = ShareService.getMapValue("booking_status");
    if (!this.booking_status) {
      this.currentTab = "PENDING";
      this.booking_status = this.currentTab;
    } else {
      this.currentTab = this.booking_status;
    }
    this.getList(0);
    this.getPaymentTermList();
    this.getDeliveryTermList();
  },
  methods: {
    dealer(data) {
      this.getDealerList(data.buyer_id);
      this.bookingDetail = data;
      this.dealerId = "";
      this.$refs.addModal.showModal();
    },
    checkOut() {
      this.$api
        .postAPI({
          _action: "seller/booking/refer-dealer/" + this.bookingDetail.uuid,
          _body: { dealer_company_id: this.dealerId },
          _buttonId: "bid-button",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$refs.addModal.closeModal();
          this.getList(0);
        })
        .catch(() => {});
    },
    getDealerList(buyer_id) {
      this.$api
        .getAPI({
          _action: "broker/search",
        })
        .then((res) => {
          this.dealerList = [];
          for (var i = 0; i < res.list.length; i++) {
            if (buyer_id != res.list[i].id) {
              this.dealerList.push(res.list[i]);
            }
          }
        })
        .catch(() => {});
    },
    retry() {
      this.getList(0);
    },
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    redirectTo(data) {
      ShareService.setMapValue("booking_status", "PENDING");
      router.push("/booking-detail/" + data.uuid);
    },
    changeTab(tab) {
      this.currentTab = tab;
      this.booking_status = tab;
      this.getList(0);
    },
    getList(page) {
      this.loader = true;
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }

      this.list = [];
      var searchObj = {
        status: this.currentTab,
      };

      if (this.keyword) {
        searchObj.keyword = this.keyword;
      }

      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }
      var url = "";
      if (this.user_type == "BUYER" || this.user_type == "BROKER") {
        url = "buyer/bids";
      } else {
        url = "seller/bids";
      }
      this.$api
        .getAPI({
          _action: url,
          _body: searchObj,
        })
        .then((res) => {
          if (res.count && this.currentTab == "PENDING") {
            this.count = res.count;
          } else {
            this.count = 0;
          }

          this.loader = false;
          this.list = res.list;
          this.offset = res.next_offset;
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No Booking Found");
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    showStatusPopup(type, data) {
      this.status = type;
      this.detail = data;
      if (this.detail.freight_amount) {
        this.freight_amount = this.detail.freight_amount;
      }
      this.$refs.statusModal.showModal();
    },
    updateStatus() {
      if (this.status == "ACCEPT" && this.user_type == "SELLER" && !this.freight_amount) {
        this.$notify({
          type: "error",
          text: "Please enter freight amount",
        });
        return false;
      }
      if (this.status == "ACCEPT") {
        var url1 = "";
        if (this.user_type == "SELLER") {
          url1 = "seller/bid/accept/" + this.detail.uuid;
        } else {
          url1 = "buyer/bid/accept/" + this.detail.uuid;
        }
        this.$api
          .postAPI({
            _action: url1,
            _buttonId: "status-button",
            _body: { freight_amount: this.freight_amount },
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.$refs.statusModal.closeModal();
            ShareService.setMapValue("booking_status", "ACCEPTED");
            this.currentTab = "ACCEPTED";
            this.booking_status = "ACCEPTED";
            this.getList(0);
          })
          .catch(() => {});
      } else if (this.status == "REJECT") {
        if (this.OtherNarration.length > 0) {
          this.Narration.narration = "Other - " + this.OtherNarration;
        }

        var url = "";
        if (this.user_type == "SELLER") {
          url = "seller/bid/reject/" + this.detail.uuid;
        } else {
          url = "buyer/bid/reject/" + this.detail.uuid;
        }
        this.$api
          .postAPI({
            _action: url,
            _body: this.Narration,
            _buttonId: "status-button",
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.$refs.statusModal.closeModal();
            ShareService.setMapValue("booking_status", "REJECTED");
            this.currentTab = "REJECTED";
            this.booking_status = "REJECTED";
            this.getList(0);
          })
          .catch(() => {});
      } else {
        this.$api
          .deleteAPI({
            _action: "buyer/bid/" + this.detail.uuid,
            _buttonId: "status-button",
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.$refs.statusModal.closeModal();
            ShareService.setMapValue("booking_status", "PENDING");
            this.booking_status = "PENDING";
            this.getList(0);
          })
          .catch(() => {});
      }
    },

    getPaymentTermList() {
      this.$api
        .getAPI({
          _action: "payment-terms",
        })
        .then((res) => {
          for (var j in res.list) {
            this.paymentTermList.push(res.list[j]);
          }
        })
        .catch(() => {});
    },
    getDeliveryTermList() {
      this.$api
        .getAPI({
          _action: "delivery-terms",
        })
        .then((res) => {
          for (var j in res.list) {
            this.deliveryTermList.push(res.list[j]);
          }
        })
        .catch(() => {});
    },
    getDeliveryAddressList(data) {
      this.$api
        .getAPI({
          _action: "delivery-address/" + data.buyer_id,
        })
        .then((res) => {
          for (var j in res.list) {
            this.deliveryAddressList.push(res.list[j]);
          }

          this.book = {
            id: data.uuid,
            quantity: data.quantity,
            bid_rate: data.bid_rate,
            payment_term_id: data.payment_term_id,
            delivery_term_id: data.delivery_term_id,
            delivery_address_id: data.delivery_address_id,
            freight_amount: data.freight_amount,
          };
          this.$refs.counterModal.showModal();
        })
        .catch(() => {});
    },
    bid(data) {
      this.detail = data;
      this.getDeliveryAddressList(data);
    },
    counter() {
      var url = "";
      if (this.user_type == "SELLER") {
        url = "seller/bid/counter/" + this.book.id;
      } else {
        url = "buyer/bid/counter/" + this.book.id;
      }

      this.$api
        .postAPI({
          _action: url,
          _body: this.book,
          _buttonId: "counter-button",
        })
        .then((res) => {
          
          this.$notify({
            type: "success",
            text: res.message,
          });
          if (url == "buyer/bid/counter/" + this.book.id) {
            this.getMe();
          }
          this.$refs.counterModal.closeModal();
          this.getList(0);
        })
        .catch(() => {});
    },
    getMe() {
      this.$api
        .getAPI({
          _action: "me",
        })
        .then((res) => {
          this.$store.commit("setBid", res.info.avaliable_bid);
        })
        .catch(() => {});
    },
  },
};
</script>

