<template>
  <div class="page-content">
    <div class="row mb-4 justify-content-center">
      <div class="col-lg-12">
        <div class="row mb-md-4 mb-2 align-items-center">
          <div class="col-md-6 text-md-left text-center">
            <h2 class="page-title mb-md-0">
              <a class="cursor-pointer" v-on:click="this.$router.go(-1)">
                <img src="/images/back-arrow.svg" width="17" class="mr-2 align-top" alt="" /></a>
              Notifications
            </h2>
          </div>
        </div>
        <div class="card rounded-l border-0 shadow">
          <div v-for="(data, index) in list" :key="index" class="card-body border-bottom expert-list"
            v-on:click="redirectTo(data)">
            <div class="row">
              <div class="col-md-8 d-flex align-items-center mb-md-0 mb-3">
                <div class="pl-3">
                  <p class="font-medium mb-2 font-18 font-xs-16">
                    {{ data.title }}
                  </p>
                  <p class="
                      font-weight-light font-14
                      text-secondary
                      mb-1
                      font-xs-12
                    ">
                    {{ data.message }}
                  </p>
                  <p class="
                      font-weight-light font-14
                      text-secondary
                      mb-0
                      font-xs-12
                    ">
                    {{ data.created_at }}
                  </p>
                </div>
              </div>
            </div>

            <div class="row mt-3 justify-content-center" v-if="data.view == 'BID_APPROVAL_REQUIRED'">
              <div class="col-md-6">
                <button class="btn btn-brand-1 mb-3" id="login-button" style="cursor: pointer; min-width: 155px"
                  v-on:click="showStatusPopup('ACCEPT', data.view_id)">
                  Accept
                </button>
              </div>
              <div class="col-md-6 text-right">
                <button class="btn btn-outline-brand-1 mb-3" id="login-button" style="cursor: pointer; min-width: 155px"
                  v-on:click="showStatusPopup('REJECT', data.view_id)">
                  Reject
                </button>
              </div>
            </div>
          </div>
          <ErrorComponent @retry="getNotification(0)" ref="errorComponent" />
        </div>
      </div>
    </div>
  </div>
  <div class="row mx-0 mb-4">
    <div class="col-lg-12 mt-5 text-center">
      <div class="page-bottom-pagination text-right">
        <Pagination @page-change="pageChange" @items-per-page-change="itemsPerPageChange" ref="listPagination" />
      </div>
    </div>
  </div>
  <TextModal ref="statusModal" class="modal-small">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" v-on:click="$refs.statusModal.closeModal">
          <img src="/images/close.svg" width="15" />
        </button>
      </div>
      <div class="modal-body text-center px-md-5">
        <h5 class="modal-title font-22 mb-4">
          {{ $helperService.getTitleCase(status) }} Bid
        </h5>

        <p class="font-18">
          Are you sure <br />
          you want to {{ $helperService.getTitleCase(status) }} bid ?
        </p>
      </div>
      <div class="modal-footer justify-content-center border-0 pb-4 pt-0">
        <div class="row">
          <div class="col-md-6">
            <button type="button" class="btn btn-brand-1 btn-block mb-3" id="status-button" style="min-width: 115px"
              v-on:click="updateStatus()">
              Yes
            </button>
          </div>
          <div class="col-md-6">
            <button type="button" class="btn cancel-btn" style="min-width: 115px"
              v-on:click="$refs.statusModal.closeModal">
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  </TextModal>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import moment from "moment";
import TextModal from "@/components/TextModal.vue";
import Pagination from "@/components/Pagination.vue";
export default {
  name: "MyNotification",
  components: {
    ErrorComponent,
    TextModal, Pagination
  },
  data() {
    return {
      loader: true,
      offset: -1,
      list: [],
      searchObj: {
        offset: 0,
      },

      user_type: localStorage.getItem("user_type"),
      designation: localStorage.getItem("designation"),
      status: "",
      view_id: "",
    };
  },
  mounted() {
    this.user_type = localStorage.getItem("user_type");
    this.$store.commit("showSidebar", true);
    this.getList(0);
  },
  methods: {
    showStatusPopup(type, view_id) {
      this.status = type;
      this.view_id = view_id;
      this.$refs.statusModal.showModal();
    },
    updateStatus() {
      if (this.status == "ACCEPT") {
        this.$api
          .postAPI({
            _action: "buyer/bid/" + this.view_id + "/accept",
            _buttonId: "status-button",
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.$refs.statusModal.closeModal();
          })
          .catch(() => { });
      } else if (this.status == "REJECT") {
        this.$api
          .postAPI({
            _action: "buyer/bid/" + this.view_id + "/reject",
            _buttonId: "status-button",
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.$refs.statusModal.closeModal();
          })
          .catch(() => { });
      }

      this.getList(0);
    },
    redirectTo(detail) {
      this.unreadNotification(detail.id);
    },
    unreadNotification(id) {
      this.$api
        .postAPI({
          _action: "notification/read/" + id,
        })
        .then(() => { })
        .catch(() => { });
    },
    retry() {
      this.getList(0);
    },
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    getList(page) {
      this.loader = true;
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }


      this.list = [];
      this.searchObj.offset = page;

      this.$api
        .getAPI({
          _action: "notifications",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          for (var j in res.list) {
            res.list[j].created_at = moment(res.list[j].created_at).format(
              "DD MMM YYYY | HH:mm A"
            );
            this.list.push(res.list[j]);
          }
          this.offset = res.next_offset;
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No NOtification Found");
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
  },
};
</script>

