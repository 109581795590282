<template>
  <div>
    <div class="page-content">
      <div class="row mb-4 justify-content-center">
        <div class="col-lg-8">
          <div class="row mb-md-4 mb-2 align-items-center">
            <div class="col-md-6 text-md-left text-center">
              <h2 class="page-title mb-md-0">
                <a class="cursor-pointer" v-on:click="this.$router.go(-1)">
                  <img
                    src="/images/back-arrow.svg"
                    width="17"
                    class="mr-2 align-top"
                    alt="" /></a
                >Help & Support
              </h2>
            </div>
            <div class="col-md-6 text-right d-flex justify-content-end"></div>
          </div>
          <div class="card mb-4 rounded-l border-0 shadow-sm">
            <div class="card-body">
              <div class="row justify-content-center">
                <div class="col-md-12">
                  <Form @submit="signup" class="form-style floating-form">
                    <div class="row">
                      <div class="col-md-12 mb-4">
                        <div class="floating-form floating-form-error">
                          <div class="floating-form-group">
                            <Field
                              as="textarea"
                              type="message"
                              id="message"
                              name="message"
                              v-model="detail.message"
                              :class="detail.message ? 'filled' : ''"
                              rules="required:message"
                              :validateOnInput="true"
                              style="height: 80px;"
                            />

                            <label for="message">Message</label>
                          </div>
                        </div><br>
                        <ErrorMessage name="message" class="validation-msg mt-1" />
                      </div>
                      <div class="col-md-12">
                        <button
                          class="btn btn-brand-1 btn-block"
                          id="signup-button"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
          <div class="card mb-4 rounded-l border-0 shadow-sm">
            <div class="card-body px-md-5">
              <div class="row justify-content-center">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-6 cursor-pointer text-center">
                      <a :href="'mailto: ' + support.email">
                        <button
                          type="button"
                          class="btn btn-brand-1 w-100"
                        >
                          <i class="fa fa-envelope"></i>&nbsp; Mail
                          <span class="float-right"
                            ><i class="fa fa-arrow-right" aria-hidden="true"></i
                          ></span></button
                      ></a>
                    </div>
                    <div class="col-md-6 cursor-pointer text-center">
                      <a :href="'tel: ' + support.phone_number">
                        <button
                          type="button"
                          class="btn btn-brand-1 w-100"
                        >
                          <i class="fa fa-phone"></i>&nbsp; Call
                          <span class="float-right"
                            ><i class="fa fa-arrow-right" aria-hidden="true"></i
                          ></span></button
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import router from "../../router";
import $ from "jquery";
export default {
  name: "HelpAndSupport",
  components: { Form, Field, ErrorMessage },
  data() {
    return {
      
      user_type: localStorage.getItem("user_type"),
      designation: localStorage.getItem("designation"),
      detail: { message: "" },
      support: {},
    };
  },
  mounted() {
    this.user_type = localStorage.getItem("user_type");
    this.$store.commit("showSidebar", true);
    this.getDetail();

    $(
      ".floating-form-group mb-0 input,.floating-form-group mb-0 textarea,.floating-form select"
    ).change(function () {
      if ($(this).val() != "") {
        $(this).addClass("filled");
      } else {
        $(this).removeClass("filled");
      }
    });
  },
  methods: {
    getDetail() {
      this.$api
        .getAPI({
          _action: "contact-us",
        })
        .then((res) => {
          this.support = res.info;
        })
        .catch(() => {});
    },
    signup() {
      this.$api
        .postAPI({
          _action: "query",
          _body: this.detail,
          _buttonId: "signup-button",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          router.push("/home");
        })
        .catch(() => {});
    },
  },
};
</script>

