<template>
  <div>
    <div class="page-content">
      <div class="row mb-4 justify-content-center">
        <div class="col-lg-12">
          <div class="row mb-md-4 mb-2 align-items-center">
            <div class="col-md-6 text-md-left text-center">
              <h2 class="page-title mb-md-0">
                <a class="cursor-pointer" v-on:click="this.$router.go(-1)">
                  <img
                    src="/images/back-arrow.svg"
                    width="17"
                    class="mr-2 align-top"
                    alt="" /></a
                >Orders
              </h2>
            </div>
            <div class="col-md-6 text-right d-flex justify-content-end pr-0">
              <input
                type="text"
                class="search-click"
                name=""
                v-on:keyup.enter="getList(0)"
                placeholder="search here..."
                v-model="keyword"
              />
            </div>
          </div>
          <ul class="nav nav-pills filter-tabs mb-3" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <a
                class="nav-link"
                id="pills-pending-tab"
                data-toggle="pill"
                href="#pills-pending"
                role="tab"
                aria-controls="pills-pending"
                aria-selected="false"
                :class="currentTab == 'PENDING' ? 'active' : ''"
                v-on:click="changeTab('PENDING')"
                style="cursor: pointer"
                >Pending</a
              >
            </li>

            <!-- <li class="nav-item" role="presentation">
              <a
                class="nav-link"
                id="pills-rejected-tab"
                data-toggle="pill"
                href="#pills-rejected"
                role="tab"
                aria-controls="pills-rejected"
                aria-selected="false"
                :class="currentTab == 'REJECTED' ? 'active' : ''"
                v-on:click="changeTab('REJECTED')"
                style="cursor: pointer"
                >Rejected</a
              >
            </li> -->

            <li
              class="nav-item"
              role="presentation"
              v-if="
                user_type == 'BUYER' || (user_type == 'BROKER' && broker_view == 'BUYER')
              "
            >
              <a
                class="nav-link"
                id="pills-open-tab"
                data-toggle="pill"
                href="#pills-open"
                role="tab"
                aria-controls="pills-open"
                aria-selected="true"
                :class="currentTab == 'OPEN' ? 'active' : ''"
                v-on:click="changeTab('OPEN')"
                style="cursor: pointer"
                >Open
              </a>
            </li>
            <li
              class="nav-item"
              role="presentation"
              v-if="
                user_type == 'BUYER' || (user_type == 'BROKER' && broker_view == 'BUYER')
              "
            >
              <a
                class="nav-link"
                id="pills-delivered-tab"
                data-toggle="pill"
                href="#pills-delivered"
                role="tab"
                aria-controls="pills-delivered"
                aria-selected="false"
                :class="currentTab == 'DELIVERED' ? 'active' : ''"
                v-on:click="changeTab('DELIVERED')"
                style="cursor: pointer"
                >Delivered</a
              >
            </li>

            <li class="nav-item" role="presentation" v-if="user_type == 'SELLER'">
              <a
                class="nav-link"
                id="pills-open-tab"
                data-toggle="pill"
                href="#pills-open"
                role="tab"
                aria-controls="pills-open"
                aria-selected="true"
                :class="currentTab == 'OPEN' ? 'active' : ''"
                v-on:click="changeTab('OPEN')"
                style="cursor: pointer"
                >Open
              </a>
            </li>

            <li class="nav-item" role="presentation" v-if="user_type == 'SELLER'">
              <a
                class="nav-link"
                id="pills-dispatch-tab"
                data-toggle="pill"
                href="#pills-dispatch"
                role="tab"
                aria-controls="pills-dispatch"
                aria-selected="true"
                :class="currentTab == 'DISPATCH' ? 'active' : ''"
                v-on:click="changeTab('DISPATCH')"
                style="cursor: pointer"
                >Dispatch
              </a>
            </li>
          </ul>

          <div>
            <div class="card rounded-l border-0 shadow" v-if="list.length > 0">
              <div
                class="card-body border-bottom expert-list"
                v-for="(data, index) in list"
                :key="index"
              >
                <template
                  v-if="
                    user_type == 'BUYER' &&
                    (currentTab == 'OPEN' || currentTab == 'DELIVERED')
                  "
                >
                  <div class="row">
                    <div class="col-md-9">
                      <p class="mb-0 font-medium">
                        {{ data.quantity }}
                        (Kg)
                      </p>

                      <p class="mb-1">
                        <b>{{ data.booking.product.yarn.name }}</b>
                      </p>
                      <p class="mt-2 mb-0">
                        <span class="badge-btn" v-if="data.booking.product.sub_yarn">{{
                          data.booking.product.sub_yarn.name
                        }}</span>
                        <span
                          class="m-2 badge-btn"
                          v-if="data.booking.product.yarn_value"
                          >{{ data.booking.product.yarn_value.value }}</span
                        >
                      </p>

                      <p class="mt-2 mb-0">
                        <template v-for="item in data.booking.product.list" :key="item">
                          <span class="badge-btn mr-1">
                            {{ item.composition.name }} {{ item.value }}%
                          </span>
                        </template>
                      </p>
                      <p class="mb-0 text-secondary">
                        {{ data.booking.seller.company_name }}
                      </p>
                      <p class="mb-0 text-secondary mb-1">
                        {{ data.booking.seller.district.name }},
                        {{ data.booking.seller.state.name }}
                      </p>
                    </div>
                    <div class="col-md-3 text-right">
                      <p class="mb-1 font-14 text-secondary">
                        {{ data.ago_time }}
                      </p>
                      <p class="mb-1">
                        <span
                          class="badge badge-light-yellow text-capitalize"
                          style="background: #d9ffea; color: #1c9c57"
                          v-if="this.searchObj.status == 'DELIVERED'"
                          >{{ $helperService.getTitleCase(data.status) }}</span
                        >
                      </p>
                      <p class="mb-0 text-primary font-medium">
                        ₹ {{ data.booking.buy_rate }}
                      </p>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-md-6">
                      <button
                        class="btn btn-brand-1 mb-3"
                        id="login-button"
                        style="cursor: pointer; min-width: 155px"
                        v-if="
                          data.status == 'DISPATCHED' &&
                          (designation == 'PRIMARY_OWNER' ||
                            designation == 'SECONDERY_OWNER' ||
                            designation == 'MANAGER')
                        "
                        v-on:click="showStatusPopup('UNLOADING', data)"
                      >
                        Unloading
                      </button>
                    </div>
                    <div class="col-md-6 text-right">
                      <button
                        class="btn btn-outline-brand-1 mb-3"
                        id="login-button"
                        style="cursor: pointer; min-width: 155px"
                        v-if="
                          data.status == 'DISPATCHED' &&
                          (designation == 'PRIMARY_OWNER' ||
                            designation == 'SECONDERY_OWNER')
                        "
                        @click="openBrokerModal(data)"
                      >
                        Invoice & Dispatch Details
                      </button>
                    </div>
                    <div class="col-md-6">
                      <button
                        class="btn btn-brand-1 mb-3"
                        id="login-button"
                        style="cursor: pointer; min-width: 215px"
                        v-if="
                          data.status == 'UNLOADING' ||
                          (data.status == 'QUERY_APPROVED' &&
                            (designation == 'PRIMARY_OWNER' ||
                              designation == 'SECONDERY_OWNER' ||
                              designation == 'MANAGER'))
                        "
                        v-on:click="showStatusPopup('APPROVED', data)"
                      >
                        Approve Delivery
                      </button>
                    </div>
                    <div class="col-md-6 text-right">
                      <button
                        class="btn btn-outline-brand-1 mb-3"
                        id="login-button"
                        style="cursor: pointer; min-width: 215px"
                        v-if="
                          data.status == 'UNLOADING' &&
                          (designation == 'PRIMARY_OWNER' ||
                            designation == 'SECONDERY_OWNER')
                        "
                        @click="openInvoice(data.invoice)"
                      >
                        Invoice
                      </button>
                    </div>
                  </div>
                </template>
                <template
                  v-else-if="
                    user_type == 'BUYER' &&
                    (currentTab == 'PENDING' || currentTab == 'REJECTED')
                  "
                >
                  <div class="row">
                    <div class="col-md-9">
                      <p class="mb-0 font-medium">
                        {{ data.booking.avail_open_qty }}
                        (Kg)
                      </p>

                      <p class="mb-1">
                        <b>{{ data.booking.product.yarn.name }}</b>
                      </p>
                      <p class="mt-2 mb-0">
                        <span class="badge-btn" v-if="data.booking.product.sub_yarn">{{
                          data.booking.product.sub_yarn.name
                        }}</span>
                        <span
                          class="m-2 badge-btn"
                          v-if="data.booking.product.yarn_value"
                          >{{ data.booking.product.yarn_value.value }}</span
                        >
                      </p>

                      <p class="mt-2 mb-0">
                        <template v-for="item in data.booking.product.list" :key="item">
                          <span class="badge-btn mr-1">
                            {{ item.composition.name }} {{ item.value }}%
                          </span>
                        </template>
                      </p>
                      <p class="mb-0 text-secondary">
                        {{ data.booking.seller.company_name }}
                      </p>
                      <p class="mb-0 text-secondary mb-1">
                        {{ data.booking.seller.district.name }},
                        {{ data.booking.seller.state.name }}
                      </p>
                      <p class="mb-0 font-medium" v-if="data.narration">
                        <span class="text-danger">Reason: </span> {{ data.narration }}
                      </p>
                    </div>
                    <div class="col-md-3 text-right">
                      <p class="mb-1 font-14 text-secondary">
                        {{ data.ago_time }}
                      </p>
                      <p class="mb-0 text-primary font-medium">
                        ₹ {{ data.booking.buy_rate }}
                      </p>
                      <p class="my-1" v-if="data.status == 'PENDING_FROM_ADMIN'">
                        <span
                          class="badge badge-light-yellow text-capitalize"
                          style="background: #e7a704; border-radius: 20px; color: #fff"
                          >Pending from admin
                        </span>
                      </p>
                    </div>
                  </div>
                </template>
                <template
                  v-else-if="
                    user_type == 'SELLER' &&
                    (currentTab == 'PENDING' || currentTab == 'REJECTED')
                  "
                >
                  <div class="row">
                    <div class="col-md-9">
                      <p class="mb-0 font-medium">
                        {{ data.avail_open_qty }}
                        (Kg)
                      </p>
                      <p class="mb-1">
                        <b>{{ data.product.yarn.name }}</b>
                      </p>
                      <p class="mt-2 mb-0">
                        <span class="badge-btn" v-if="data.product.sub_yarn">{{
                          data.product.sub_yarn.name
                        }}</span>
                        <span class="m-2 badge-btn" v-if="data.product.yarn_value">{{
                          data.product.yarn_value.value
                        }}</span>
                      </p>

                      <p class="mt-2 mb-0">
                        <template v-for="item in data.product.list" :key="item">
                          <span class="badge-btn mr-1">
                            {{ item.composition.name }} {{ item.value }}%
                          </span>
                        </template>
                      </p>
                      <p class="mb-0 text-secondary">
                        {{ data.buyer.company_name }}
                      </p>
                      <p class="mb-0 text-secondary mb-1">
                        {{ data.buyer.billing_address.district.name }},
                        {{ data.buyer.billing_address.state.name }}
                      </p>
                      <p class="mb-0 font-medium" v-if="data.narration">
                        <span class="text-danger">Reason: </span> {{ data.narration }}
                      </p>
                    </div>
                    <div class="col-md-3 text-right">
                      <p class="mb-1 font-14 text-secondary">
                        {{ data.ago_time }}
                      </p>
                      <p class="mb-0 text-primary font-medium">₹ {{ data.buy_rate }}</p>
                      <p
                        class="my-1"
                        v-if="data.status == 'PENDING' || data.status == 'INPROCESS'"
                      >
                        <button
                          type="button "
                          class="btn-brand-1"
                          style="min-width: 75px !important"
                          v-on:click="$router.push('/add-vehicle/' + data.uuid)"
                        >
                          Add Vehicle
                        </button>
                      </p>
                      <p class="my-1" v-if="data.status == 'PENDING_FROM_ADMIN'">
                        <span
                          class="badge badge-light-yellow text-capitalize"
                          style="background: #e7a704; border-radius: 20px; color: #fff"
                          >Pending from admin
                        </span>
                      </p>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="row">
                    <div class="col-md-9">
                      <p class="mb-0 font-medium">
                        {{ data.quantity }}
                        (Kg)
                      </p>

                      <p class="mb-1">
                        <b>{{ data.booking.product.yarn.name }}</b>
                      </p>
                      <p class="mt-2 mb-0">
                        <span class="badge-btn" v-if="data.booking.product.sub_yarn">{{
                          data.booking.product.sub_yarn.name
                        }}</span>
                        <span class="m-2 badge-btn" v-if="data.booking.product.yarn_value">{{
                          data.booking.product.yarn_value.value
                        }}</span>
                      </p>

                      <p class="mt-2 mb-0">
                        <template v-for="item in data.booking.product.list" :key="item">
                          <span class="badge-btn mr-1">
                            {{ item.composition.name }} {{ item.value }}%
                          </span>
                        </template>
                      </p>
                      <p class="mb-0 text-secondary">
                        {{ data.booking.buyer.company_name }}
                      </p>
                      <p class="mb-0 text-secondary mb-1">
                        {{ data.billing_address.district.name }},
                        {{ data.billing_address.state.name }}
                      </p>
                    </div>
                    <div class="col-md-3 text-right">
                      <p class="mb-1 font-14 text-secondary">
                        {{ data.ago_time }}
                      </p>
                      <p class="mb-0 text-primary font-medium">
                        ₹ {{ data.booking.buy_rate }}
                      </p>
                    </div>
                  </div>

                  <div
                    class="card-footer bg-transparent p-0 mt-3 text-center text-center"
                    :class="currentTab == 'DISPATCH' ? 'border-0' : ''"
                  >
                    <button
                      class="btn btn-brand-1 mt-3 mr-3"
                      id="login-button"
                      style="cursor: pointer; min-width: 155px"
                      v-if="this.currentTab == 'DISPATCH'"
                      v-on:click="redirectTo(data)"
                    >
                      View Dispatch Details
                    </button>
                    <button
                      class="btn btn-outline-brand-1 mt-3"
                      id="login-button"
                      style="cursor: pointer; min-width: 155px"
                      v-if="this.currentTab == 'DISPATCH' && data.receipt"
                      @click="openInvoice(data.receipt)"
                    >
                      Receipt
                    </button>

                    <button
                      class="btn btn-brand-1 mt-3"
                      id="login-button"
                      style="cursor: pointer; min-width: 155px"
                      v-if="
                        data.status == 'CREATED' &&
                        (designation == 'PRIMARY_OWNER' ||
                          designation == 'SECONDERY_OWNER' ||
                          designation == 'MANAGER')
                      "
                      v-on:click="showStatusPopup('LOADING_STARTED', data)"
                    >
                      Loading Started
                    </button>
                    <button
                      class="btn btn-brand-1 mt-3"
                      id="login-button"
                      style="cursor: pointer; min-width: 155px"
                      v-if="
                        data.status == 'LOADING' &&
                        (designation == 'PRIMARY_OWNER' ||
                          designation == 'SECONDERY_OWNER' ||
                          designation == 'MANAGER')
                      "
                      v-on:click="
                        $router.push(
                          '/broker-note/' + data.uuid + '/' + data.booking.uuid
                        )
                      "
                    >
                      Complete Dispatch Details
                    </button>
                    <button
                      class="btn btn-brand-1 mt-3"
                      id="login-button"
                      style="cursor: pointer; min-width: 155px"
                      v-if="
                        data.status == 'VEHICLE_PLACED' &&
                        (designation == 'PRIMARY_OWNER' ||
                          designation == 'SECONDERY_OWNER' ||
                          designation == 'MANAGER')
                      "
                      v-on:click="showStatusPopup('DISPATCH', data)"
                    >
                      Dispatch
                    </button>
                  </div>
                </template>
              </div>
            </div>
          </div>

          <div class="row mx-0 mb-4">
            <div class="col-lg-12 mt-5 text-center">
              <div class="page-bottom-pagination text-right">
                <Pagination
                  @page-change="pageChange"
                  @items-per-page-change="itemsPerPageChange"
                  ref="listPagination"
                />
              </div>
            </div>
          </div>
          <ErrorComponent @retry="getList(0)" ref="errorComponent" />
        </div>
      </div>
    </div>
  </div>
  <TextModal ref="statusModal" class="modal-small">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" v-on:click="$refs.statusModal.closeModal">
          <img src="/images/close.svg" width="15" />
        </button>
      </div>
      <div
        class="modal-body text-center px-md-5 floating-form"
        style="max-height: 500px; overflow: auto"
      >
        <h5 class="modal-title font-22 mb-4">
          <span v-if="status == 'LOADING_STARTED'">Order Start Loading</span>
          <span v-if="status == 'DISPATCH'">Order Dispatch</span>
          <span v-if="status == 'UNLOADING'">Order Unloading</span>
          <span v-if="status == 'APPROVED'">Order Approved</span>
        </h5>

        <p class="font-18">
          Are you sure <br />
          you want to
          <span v-if="status == 'LOADING_STARTED'">Start Loading order?</span>
          <span v-if="status == 'DISPATCH'">dispatch order? </span>
          <span v-if="status == 'UNLOADING'">unloading order? </span>
          <span v-if="status == 'APPROVED'">approve order? </span>
        </p>
      </div>

      <div class="row" v-if="status == 'APPROVED'">
        <div class="col-md-12">
          <div class="input-group mb-3">
            <div class="custom-file">
              <input
                type="file"
                class="custom-file-input"
                id="inputGroupFile02"
                @change="onChange($event)"
              />
              <label
                class="custom-file-label"
                for="inputGroupFile02"
                aria-describedby="inputGroupFileAddon02"
                >Upload Receipt</label
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="status == 'UNLOADING'">
        <div class="col-md-12">
          <Form class="form-style floating-form">
            <div class="floating-form-error mb-3">
              <div class="floating-form-group">
                <Field
                  type="text"
                  id="otp"
                  name="otp"
                  :class="otp ? 'filled' : ''"
                  rules="required:otp|numeric"
                  v-model="otp"
                />
                <label for="otp">OTP</label><span class="text-danger">*</span>
              </div>
              <ErrorMessage name="otp" class="validation-msg mt-1" />
            </div>
          </Form>
        </div>
      </div>
      <div class="modal-footer justify-content-center border-0 pb-4 pt-0">
        <div class="row">
          <div class="col-md-6">
            <button
              type="button"
              class="btn btn-brand-1 btn-block mb-3"
              id="status-button"
              style="min-width: 115px"
              v-on:click="updateStatus()"
            >
              Yes
            </button>
          </div>
          <div class="col-md-6">
            <button
              type="button"
              class="btn cancel-btn"
              style="min-width: 115px"
              v-on:click="$refs.statusModal.closeModal"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  </TextModal>

  <TextModal ref="brokerModal" class="modal-small">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" v-on:click="$refs.brokerModal.closeModal">
          <img src="/images/close.svg" width="15" />
        </button>
      </div>
      <div class="modal-body text-center px-md-5">
        <h5 class="modal-title font-22 mb-4">
          <span>Invoice & Dispatch Details</span>
        </h5>
        <div class="row">
          <div class="col-md-12" v-if="data.invoice">
            <button
              type="button"
              class="btn btn-brand-1 btn-block mb-3"
              id="status-button"
              style="min-width: 115px"
              v-on:click="openInvoice(data.invoice)"
            >
              Invoice
            </button>
          </div>
          <div class="col-md-12" v-if="data.lr_copy">
            <button
              type="button"
              class="btn btn-brand-1 btn-block mb-3"
              style="min-width: 115px"
              v-on:click="openInvoice(data.lr_copy)"
            >
              L.R Copy or Transporter Copy
            </button>
          </div>
          <div class="col-md-12">
            <button
              type="button"
              class="btn btn-brand-1 btn-block mb-3"
              style="min-width: 115px"
              v-on:click="redirectTo(data)"
            >
              Dispatch Details
            </button>
          </div>
        </div>
      </div>
    </div>
  </TextModal>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
import TextModal from "@/components/TextModal.vue";
import ShareService from "../../services/ShareService";
import router from "../../router";
import Pagination from "@/components/Pagination.vue";
export default {
  name: "MyBooking",
  components: {
    ErrorComponent,
    TextModal,
    Pagination,
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      loader: true,
      offset: -1,
      list: [],
      currentTab: "PENDING",
      keyword: "",

      user_type: localStorage.getItem("user_type"),
      designation: localStorage.getItem("designation"),
      broker_view: localStorage.getItem("broker_view"),
      searchObj: {},
      status: "",
      order: {},
      order_status: "",
      data: {},
      otp: "",
    };
  },
  mounted() {
    this.user_type = localStorage.getItem("user_type");

    var user = this.$store.getters.getUser;
    this.$helperService.checkAddress(user);

    if (this.user_type == "SELLER") {
      this.currentTab = "PENDING";
    } else if (this.user_type == "BUYER") {
      this.currentTab = "OPEN";
    } else if (this.user_type == "BROKER" && this.broker_view == "BUYER") {
      this.currentTab = "OPEN";
    }
    this.$store.commit("showSidebar", true);
    this.order_status = ShareService.getMapValue("order_status");
    if (!this.order_status) {
      this.currentTab = "OPEN";
    } else {
      this.currentTab = this.order_status;
    }
    this.getList(0);
  },
  methods: {
    openBrokerModal(data) {
      this.data = data;
      this.$refs.brokerModal.showModal();
    },
    redirectTo(data) {
      if (this.currentTab == "DISPATCH") {
        ShareService.setMapValue("order_status", "DISPATCH");
        router.push("/order-detail/" + data.uuid + "/" + data.booking.uuid);
      }

      if (this.currentTab == "DELIVERED") {
        ShareService.setMapValue("order_status", "DELIVERED");
        router.push("/order-detail/" + data.uuid + "/" + data.booking.uuid);
        this.$refs.brokerModal.closeModal();
      }
    },
    openInvoice(invoice) {
      if (invoice) {
        window.open(invoice);
      }
      this.$refs.brokerModal.closeModal();
    },
    changeTab(tab) {
      this.currentTab = tab;
      this.getList(0);
    },

    showStatusPopup(type, order) {
      this.status = type;
      this.order = order;
      this.otp = "";
      this.$refs.statusModal.showModal();
    },
    updateStatus() {
      if (this.status == "APPROVED" && this.user_type == "BUYER" && !this.file) {
        this.$notify({
          type: "error",
          text: "Please select receipt",
        });
        return false;
      }

      if (this.status == "UNLOADING" && this.user_type == "BUYER" && !this.otp) {
        this.$notify({
          type: "error",
          text: "Please enter otp",
        });
        return false;
      }

      if (this.status == "LOADING_STARTED") {
        this.$api
          .postAPI({
            _action:
              "seller/booking/" +
              this.order.booking.uuid +
              "/order/" +
              this.order.uuid +
              "/loading",
            _buttonId: "status-button",
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.$refs.statusModal.closeModal();
            this.list = [];
            this.getList(0);
          })
          .catch(() => {});
      } else if (this.status == "DISPATCH") {
        this.$api
          .postAPI({
            _action:
              "seller/booking/" +
              this.order.booking.uuid +
              "/order/" +
              this.order.uuid +
              "/dispatched",
            _buttonId: "status-button",
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.$refs.statusModal.closeModal();
            this.list = [];
            this.currentTab = "DISPATCH";
            this.getList(0);
          })
          .catch(() => {});
      } else if (this.status == "UNLOADING") {
        this.$api
          .postAPI({
            _action:
              "buyer/booking/" +
              this.order.booking.uuid +
              "/order/" +
              this.order.uuid +
              "/unloading",
            _buttonId: "status-button",
            _body: { otp: this.otp },
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.$refs.statusModal.closeModal();
            this.list = [];
            this.getList(0);
          })
          .catch(() => {});
      } else if (this.status == "APPROVED") {
        this.$api
          .postAPI({
            _action:
              "buyer/booking/" +
              this.order.booking.uuid +
              "/order/" +
              this.order.uuid +
              "/order-approved",
            _buttonId: "status-button",
          })
          .then((res) => {
            if (this.file) {
              this.uploadImage();
            } else {
              this.$notify({
                type: "success",
                text: res.message,
              });
              this.$refs.statusModal.closeModal();
              this.list = [];
              this.getList(0);
            }
          })
          .catch(() => {});
      }
    },

    onChange(e) {
      let files = e.target.files;
      this.file = files[0];
    },
    uploadImage() {
      this.$api
        .uploadImageAPI({
          _action: "buyer/order/" + this.order.uuid + "/receipt",
          _key: "receipt",
          _file: this.file,
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$refs.statusModal.closeModal();
          this.list = [];
          this.getList(0);
        })
        .catch(() => {});
    },
    retry() {
      this.getList(0);
    },
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    getList(page) {
      this.loader = true;
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }

      this.list = [];
      this.searchObj.offset = page;
      this.searchObj.status = this.currentTab;

      if (this.keyword) {
        this.searchObj.keyword = this.keyword;
      }

      this.list = [];
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }
      var url = "";
      if (this.user_type == "BUYER") {
        if (this.currentTab == "PENDING" || this.currentTab == "REJECTED") {
          // url = "buyer/pending/orders";
          url = "buyer/orders";
        } else {
          url = "buyer/orders";
        }
      } else {
        if (this.currentTab == "PENDING" || this.currentTab == "REJECTED") {
          url = "seller/bookings/pending";
        } else if (this.currentTab == "OPEN") {
          url = "seller/open/orders";
        } else {
          url = "seller/dispatched/orders";
        }
      }
      this.$api
        .getAPI({
          _action: url,
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.offset = res.next_offset;
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No Order Found");
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
  },
};
</script>

