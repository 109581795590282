<template>
  <header
    class="p-md-4 p-3 shadow-sm bg-white index-header position-fixed w-100"
    style="z-index: 1"
  >
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-md-3 col-3">
          <img
            src="/images/yarnmart-Logo.svg"
            width="200"
            height="70"
            class="img-fluid"
          />
        </div>
      </div>
    </div>
  </header>
</template>
<script>
export default {
  name: "OuterHeader",
  data() {},
  mounted() {},
  methods: {},
};
</script>