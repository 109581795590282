<template>
  <div>
    <div class="page-content">
      <div class="row mb-4 justify-content-center">
        <div class="col-lg-12">
          <div class="row mb-md-4 mb-2 align-items-center">
            <div class="col-md-6 text-md-left text-center">
              <h2 class="page-title mb-md-0">
                <a class="cursor-pointer" v-on:click="this.$router.go(-1)">
                  <img
                    src="/images/back-arrow.svg"
                    width="17"
                    class="mr-2 align-top"
                    alt="" /></a
                >Terms & Conditions
              </h2>
            </div>

            <div class="col-md-6 text-right d-flex justify-content-end"></div>
          </div>
          <div class="card rounded-l border-0 shadow" v-if="about.description">
            <div class="font-16 m-3" v-html="about.description"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AboutUs",
  components: {},
  data() {
    return {
      
      user_type: localStorage.getItem("user_type"),
      designation: localStorage.getItem("designation"),
      about: { description: "" },
    };
  },
  mounted() {
    this.user_type = localStorage.getItem("user_type");
    this.$store.commit("showSidebar", true);
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.$api
        .getAPI({
          _action: "cms/TERMS",
        })
        .then((res) => {
          this.about = res;
        })
        .catch(() => {});
    },
  },
};
</script>