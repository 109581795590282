<template>
  <div class="container-fluid h-100">
    <div class="row justify-content-md-center align-items-center min-vh-100 bg-white">
      <div class="col-lg-4">

        <div>
          <div class="card card-body" v-if="list.length > 0">

            <div class="row">
              <div class="col-md-12 text-center">
                <h2 class="page-title mb-md-0 pt-2">Select Company</h2>
              </div>
              <!-- <div class="col-md-6 text-right">
              <input type="text" class="search-click" name="" placeholder="search here..." v-model="searchObj.keyword"
                v-on:keyup.enter="getList(0)" />
            </div> -->
            </div>

            <div class="blue-border mt-4" v-for="(data, index) in list" :key="index" v-on:click="redirectTo(data)">
              <div class="row">
                <div class="col-md-9">

                  <div class="mb-0 font-medium">
                    {{ data.company.company_name }}
                  </div>

                  <div class="mb-0 text-secondary">
                    {{ data.company.district?.name }}
                    <span v-if="data.company.district">,</span>
                    {{ data.company.state?.name }}
                  </div>
                </div>
                <div class="col-md-3 text-end text-right my-auto">
                  <img src="/images/arrow-right-circle.svg" alt="" />
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="row mx-0 mb-4">
          <div class="col-lg-12 mt-5 text-center">
            <div class="page-bottom-pagination text-right">
              <Pagination @page-change="pageChange" @items-per-page-change="itemsPerPageChange" ref="listPagination" />
            </div>
          </div>
        </div>
        <ErrorComponent @retry="getList(0)" ref="errorComponent" />
      </div>
    </div>
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import router from "@/router";
import Pagination from "@/components/Pagination.vue";
export default {
  name: "SearchCompany",
  components: {
    ErrorComponent, Pagination
  },
  data() {
    return {
      loader: true,
      offset: -1,
      list: [],
      searchObj: {
        offset: 0,
      },
      user_type: localStorage.getItem("user_type"),
      designation: localStorage.getItem("designation"),
      products: [],
      company: {},
    };
  },
  mounted() {
    this.user_type = localStorage.getItem("user_type");
    this.$store.commit("showSidebar", true);
    this.getList(0);
  },
  methods: {
    redirectTo(data) {
      localStorage.setItem("company_uuid", data.company.uuid);
      localStorage.setItem("is_importer", data.company.is_importer);
      if (this.user_type != "BROKER") {
        if (this.user_type == "SELLER" && !data.company.billing_address) {
          router.push("/add-address");
        } else if (this.user_type == "BUYER" && !data.company.billing_address) {
          router.push("/add-address");
        } else if (this.user_type == "BUYER" && !data.company.delivery_address) {
          router.push("/add-address");
        } else {
          router.push("/home");
        }
      } else {
        router.push("/home");
      }
    },
    getList(page) {
      this.loader = true;
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }


      this.list = [];
      this.searchObj.offset = page;

      this.$api
        .getAPI({
          _action: "user/companies",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.offset = res.next_offset;
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No Company Found");
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    retry() {
      this.getList(0);
    },
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
  },
};
</script>

