<template>
  <div>
    <header class="p-md-4 p-3 shadow-sm bg-white index-header position-fixed w-100" style="z-index: 1">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-md-3 col-3">
            <img src="/images/yarnmart-Logo.svg" width="200" height="70" class="img-fluid" />
          </div>
        </div>
      </div>
    </header>
    <main role="main">
      <div id="main-wrapper " class="login-wrap pt-0">
        <div class="container-fluid h-100">
          <div class="row justify-content-center min-vh-100 align-items-center">
            <div class="col-md-3">
              <div class="card">
                <div class="card-body">
                  <div class="text-center">
                    <img width="100"
                        height="100"
                        class="img-fluid svg-img" src="/images/forgot-password.svg" />
                    <p class="font-medium font-18">OTP Verification</p>
                    <p class="text-secondary font-14">
                      Please enter verification code.
                    </p>
                  </div>

                  <div class="form-group otp-element mb-0 col-md-10 mx-auto">
                    <form class="d-flex justify-content-between">
                      <input id="codeBox1" type="text" class="otp-control" maxlength="1" @blur="check()"
                        v-on:keyup="onKeyUpEvent(1, $event)" />
                      <input id="codeBox2" type="text" class="otp-control" maxlength="1" @blur="check()"
                        v-on:keyup="onKeyUpEvent(2, $event)" />
                      <input id="codeBox3" type="text" class="otp-control" maxlength="1" @blur="check()"
                        v-on:keyup="onKeyUpEvent(3, $event)" />
                      <input id="codeBox4" type="text" class="otp-control" maxlength="1" @blur="check()"
                        v-on:keyup="onKeyUpEvent(4, $event)" />
                    </form>
                  </div>
                  <div class="text-center mt-2">
                    <p class="text-secondary">
                      We have sent verification code on your registered Mobile
                      no.
                    </p>
                    <p class="text-danger" id="digit">Please enter OTP</p>
                  </div>
                </div>
                <div class="card-footer text-center bg-white border-0 px-md-5">
                  <button type="button" class="btn btn-brand-1 btn-block" id="verify-otp" @click="verifyOtp()">
                    Verify
                  </button>
                  <div class="mt-3">
                    <p id="otp_timer" class="font-20 mb-2" style="color: #666666">
                      00:0
                    </p>
                    <p id="is_show">
                      Didn't get the code?
                      <a href="#" class="text-color-2 font-16 text-primary" @click="resendOtp()">Resend</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <ThankyouModal ref="ThankyouModal" @redirect="gotoResetPassword()" />
  </div>
</template>
<script>
import api from "../../services/WebService";
import router from "../../router";
import ThankyouModal from "@/components/ThankyouModal.vue";
export default {
  name: "ForgotPassword",
  components: { ThankyouModal },
  data() {
    return {
      detail: {},
      mobile_number: "",
      item: "",
      from: "",
      user_type: localStorage.getItem("user_type"),
      products: [],
    };
  },
  mounted() {
    document.getElementById("digit").style.display = "none";
    this.mobile_number = localStorage.getItem("forgot_mobile");
    this.from = localStorage.getItem("otp-from");
    if (!this.from || (this.from == "FORGOT" && !this.mobile_number)
      || (this.from == "SIGNUP" && !this.mobile_number)) {
      router.push("/login");
    }

    window.history.forward(1);

    //floating form
    this.startCountDown();
  },
  methods: {
    // startCountDown() {
    //   document.getElementById("is_show").style.display = "none";
    //   var timeLeft = 30;
    //   var elem = document.getElementById("otp_timer");
    //   var timerId = setInterval(countdown, 1000);
    //   function countdown() {
    //     if (timeLeft == -1) {
    //       clearTimeout(timerId);
    //       doSomething();
    //     } else {
    //       elem.innerHTML = "00:" + timeLeft;
    //       timeLeft--;
    //       document.getElementById("is_show").style.display = "none";
    //     }
    //   }
    //   function doSomething() {
    //     document.getElementById("is_show").style.display = "block";
    //   }
    // },
    startCountDown() {
      document.getElementById("is_show").style.display = "none";
      var timeLeft = 30;
      var elem = document.getElementById("otp_timer");
      var timerId = setInterval(countdown, 1000);
      function countdown() {
        if (timeLeft == -1) {
          clearTimeout(timerId);
          doSomething();
        } else {
          elem.innerHTML = "00:" + timeLeft;
          timeLeft--;
          document.getElementById("is_show").style.display = "none";
        }
      }
      function doSomething() {
        document.getElementById("is_show").style.display = "block";
      }
    },
    check() {
      if (
        document.getElementById("codeBox1").value &&
        document.getElementById("codeBox2").value &&
        document.getElementById("codeBox3").value &&
        document.getElementById("codeBox4").value
      ) {
        document.getElementById("digit").style.display = "none";
        return true;
      } else {
        document.getElementById("digit").style.display = "block";
        return false;
      }
    },
    getCodeBoxElement(index) {
      return document.getElementById("codeBox" + index);
    },
    onKeyUpEvent(index, event) {
      const eventCode = event.which || event.keyCode;
      if (this.getCodeBoxElement(index).value.length === 1) {
        if (index !== 4) {
          this.getCodeBoxElement(index + 1).focus();
        } else {
          this.getCodeBoxElement(index).blur();
          // Submit code
          console.log("submit code ");
        }
      }
      if (eventCode === 8 && index !== 1) {
        this.getCodeBoxElement(index - 1).focus();
      }
    },
    verifyOtp() {
      var flag = this.check();
      if (flag) {
        var otp =
          document.getElementById("codeBox1").value +
          document.getElementById("codeBox2").value +
          document.getElementById("codeBox3").value +
          document.getElementById("codeBox4").value;
        var url = "";
        if (this.from == "FORGOT") {
          url = "verify-otp";
        } else {
          url = "otp/verify";
        }
        api
          .postAPI({
            _action: url,
            _body: { otp: otp, mobile_number: this.mobile_number },
            _buttonId: "verify-otp",
          })
          .then(() => {
            this.$refs.ThankyouModal.showModal(
              "Congratulations !",
              " Your OTP has successfully verified"
            );
          })
          .catch(() => { });
      }
    },
    resendOtp() {
      var url = "";
      if (this.from == "FORGOT") {
        url = "resend-otp";
      } else {
        url = "otp/resend";
      }
      api
        .postAPI({
          _action: url,
          _body: { mobile_number: this.mobile_number },
          _buttonId: "verify-otp",
        })
        .then(() => {
          document.getElementById("codeBox1").value = "";
          document.getElementById("codeBox2").value = "";
          document.getElementById("codeBox3").value = "";
          document.getElementById("codeBox4").value = "";
          this.startCountDown();
        })
        .catch(() => { });
    },
    gotoResetPassword() {
      if (this.from == "FORGOT") {
        router.push("/reset-password");
      } else {
        localStorage.removeItem("access_token");
        router.push("/login");
      }
    },
  },
};
</script>

