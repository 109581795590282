<template>
  <div>
    <OuterHeader />
    <main role="main">
      <div id="main-wrapper" class="login-wrap pt-0">
        <div class="container-fluid h-100">
          <div
            class="row justify-content-md-center align-items-center min-vh-100 bg-white"
          >
            <div class="col-md-6 bg-white">
              <div class="row justify-content-center">
                <div class="col-lg-6">
                  <div class="px-lg-4 px-0 py-3 card shadow">
                    <div class="card-header bg-white rounded-10 text-center border-0">
                      <img
                        src="/images/yarnmart-Logo.svg"
                        width="300"
                        height="60"
                        class="img-fluid mb-3"
                      />
                    </div>
                    <div class="">
                      <Form @submit="login" class="form-style floating-form">
                        <div class="floating-form-error mb-3">
                          <div class="floating-form-group">
                            <Field
                              type="mobile"
                              id="mobile"
                              name="mobile"
                              v-model="details.mobile_number"
                              rules="required:mobile number|phone"
                              :validateOnInput="true"
                              maxlength="10"
                            />
                            <label for="mobile">Mobile Number</label>
                          </div>
                          <ErrorMessage name="mobile" class="validation-msg mt-1" />
                        </div>
                        <div class="floating-form-error mb-3">
                          <div class="floating-form-group">
                            <Field
                              :type="passwordFieldType"
                              id="password"
                              name="Password"
                              v-model="details.password"
                              rules="required:password"
                              :validateOnInput="true"
                            />
                            <label for="password">Password</label>
                            <span>
                              <a class="psw-icon" v-on:click="switchVisibility"
                                ><img :src="image" width="20"
                              /></a>
                            </span>
                          </div>
                          <ErrorMessage name="Password" class="validation-msg mt-1" />
                        </div>
                        <div class="form-check text-center pl-0 mb-4 overflow-auto pr-3">
                          <a
                            class="text-dark text-primary"
                            style="cursor: pointer"
                            v-on:click="$router.push('/forgot-password')"
                            >Forgot Password?</a
                          >
                        </div>
                        <button
                          class="btn btn-brand-1 btn-block mb-3"
                          id="login-button"
                          style="cursor: pointer"
                        >
                          Login
                        </button>
                      </Form>
                    </div>
                    <div class="card-footer text-center bg-white border-0 p-0">
                      <div class="links">
                        New User?
                        <button
                          class="btn btn-brand-1 btn-block mb-3 mt-3"
                          id="login-button"
                          style="cursor: pointer"
                          v-on:click="$router.push('/signup')"
                        >
                          Register
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import OuterHeader from "@/components/OuterHeader";
import $ from "jquery";
import router from "../../router";
export default {
  name: "WebLogin",
  components: {
    Form,
    Field,
    ErrorMessage,
    OuterHeader,
  },
  data() {
    return {
      passwordFieldType: "password",
      image: "/images/show.png",
      details: {
        mobile_number: "",
        password: "",
      },
      is_importer: localStorage.getItem("is_importer"),
      user_type: localStorage.getItem("user_type"),
      designation: localStorage.getItem("designation"),
      products: [],
      detail: {},
    };
  },
  mounted() {
    this.user_type = localStorage.getItem("user_type");
    this.is_importer = localStorage.getItem("is_importer");
    if (!this.user_type) {
      router.push("/select-type");
    }
    if (localStorage.getItem("access_token")) {
      router.push("/home");
    }

    //floating form
    $(document).ready(function () {
      $(
        ".floating-form-group input,.floating-form-group textarea,.floating-form select"
      ).change(function () {
        if ($(this).val() != "") {
          $(this).addClass("filled");
        } else {
          $(this).removeClass("filled");
        }
      });
    });
  },
  methods: {
    login() {
      this.$api
        .postAPI({
          _action: "login",
          _body: this.details,
          _buttonId: "login-button",
        })
        .then((res) => {
          if (res && res.access_token) {
            localStorage.setItem("access_token", res.access_token);
            this.getMe();
            // window.initializeUI();
            setTimeout(() => {
              // this.getMe();
              // this.register();
            }, 1000);
          }
        })
        .catch(() => {});
    },
    register() {
      let subscription = window.localStorage.getItem("subscription");
      console.log("subscription " + subscription);
      if (subscription) {
        this.$api
          .postAPI({
            _action: "register-device",
            _body: {
              token: subscription,
              token_type: "WEB",
            },
          })
          .then(() => {
            this.getMe();
          })
          .catch((e) => {
            this.$notify.error("" + e.message, {
              timeout: 2000,
              layout: "topCenter",
              theme: "semanticui",
            });
          });
      } else {
        this.getMe();
      }
    },
    getMe() {
      this.$api
        .getAPI({
          _action: "me",
          _buttonId: "login-button",
        })
        .then((res) => {
          
          this.detail = res.info;
          if (this.detail.user_type != this.user_type) {
            this.$notify({
              type: "error",
              text: "Please select appropriate user type",
            });
            localStorage.removeItem("access_token");
            return;
          } else {
            if (res.info.user_companies[0].company.is_importer != this.is_importer) {
              this.$notify({
                type: "error",
                text: "Please select appropriate user type",
              });
              localStorage.removeItem("access_token");
              return;
            }
          }
          if (res && res.info && res.info.user_type) {
            localStorage.setItem("user_type", res.info.user_type);
            localStorage.setItem("user_uuid", res.info.uuid);
            localStorage.setItem("user_id", res.info.id);
            localStorage.setItem("user_name", res.info.name);
            localStorage.setItem("company_id", res.info.user_companies[0].company.id);
            localStorage.setItem("company_uuid", res.info.user_companies[0].company.uuid);
            localStorage.setItem("designation", res.info.user_companies[0].designation);
            localStorage.setItem(
              "is_importer",
              res.info.user_companies[0].company.is_importer
            );
          } else {
            localStorage.removeItem("access_token");
            return;
          }

          localStorage.setItem("userId", res.info.id);

          if(res?.info?.user_info == null && (res?.info?.user_info?.has_pan_verified == 0 || res?.info?.user_info?.has_gst_verified == 0)) {
            router.push("/verification/"+ res.info.uuid);
          }
          this.getCompany();
        })
        .catch(() => {
          localStorage.removeItem("access_token");
        });
    },
    getCompany() {
      this.$api
        .getAPI({
          _action: "user/companies",
        })
        .then((res) => {
          if (res.list.length > 1) {
            router.push("/search-company");
          } else {
            if (
              this.detail.user_type == "SELLER" &&
              !this.detail.user_companies[0].company.billing_address
            ) {
              router.push("/add-address");
            } else if (
              this.detail.user_type == "BUYER" &&
              !this.detail.user_companies[0].company.billing_address
            ) {
              router.push("/add-address");
            } else if (
              this.detail.user_type == "BUYER" &&
              !this.detail.user_companies[0].company.delivery_address
            ) {
              router.push("/add-address");
            } else {
              if (this.detail.user_type == "BROKER") {
                localStorage.setItem("user_type", "BUYER");
                if (!this.detail.user_companies[0].company.delivery_address) {
                  router.push("/add-address");
                } else if (!this.detail.user_companies[0].company.billing_address) {
                  router.push("/add-address");
                } else {
                  router.push("/home");
                }
              } else {
                router.push("/home");
              }
            }
          }
        })
        .catch(() => {});
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.image =
        this.image === "/images/show.png" ? "/images/hide.png" : "/images/show.png";
    },
  },
};
</script>

