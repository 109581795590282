<template>
  <div>
    <div class="page-content">
      <div class="row mb-4 justify-content-center">
        <div class="col-lg-12">
          <div class="row mb-md-4 mb-2 align-items-center">
            <div class="col-md-6 text-md-left text-center">
              <h2 class="page-title mb-md-0">
                <a class="cursor-pointer" v-on:click="this.$router.go(-1)">
                  <img
                    src="/images/back-arrow.svg"
                    width="17"
                    class="mr-2 align-top"
                    alt="" /></a
                >Leads
              </h2>
            </div>
            <div class="col-md-6 text-right d-flex justify-content-end pr-0">
              <input
                type="text"
                class="search-click"
                name=""
                v-on:keyup.enter="getList(0)"
                placeholder="search here..."
                v-model="keyword"
              />
            </div>
          </div>
          <ul class="nav nav-pills filter-tabs mb-3" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <a
                class="nav-link"
                id="pills-pending-tab"
                data-toggle="pill"
                href="#pills-pending"
                role="tab"
                aria-controls="pills-pending"
                aria-selected="true"
                :class="currentTab == 'PENDING' ? 'active' : ''"
                v-on:click="changeTab('PENDING')"
                style="cursor: pointer"
                >Pending
              </a>
            </li>
            <li class="nav-item" role="presentation">
              <a
                class="nav-link"
                id="pills-accepted-tab"
                data-toggle="pill"
                href="#pills-accepted"
                role="tab"
                aria-controls="pills-accepted"
                aria-selected="false"
                :class="currentTab == 'ACCEPTED' ? 'active' : ''"
                v-on:click="changeTab('ACCEPTED')"
                style="cursor: pointer"
                >offered</a
              >
            </li>
          </ul>
          <div>
            <div class="card rounded-l border-0 shadow" v-if="list.length > 0">
              <table class="table table-style-1 text-center form-style floating-form">
                <tr>
                  <th>Product Information</th>
                  <th>Refer By (Seller)</th>
                  <th>Buyer</th>
                  <th>Quantity (in Kg)</th>
                  <th>Price</th>
                  <th>Action</th>
                </tr>
                <tr v-for="data in list" :key="data.id">
                  <td>
                    {{ data.product.name }}<br />
                    <span class="m-2 badge-btn" v-if="data.product.sub_yarn">{{
                      data.product.sub_yarn.name
                    }}</span>
                    <span class="m-2 badge-btn" v-if="data.product.yarn_value">{{
                      data.product.yarn_value.name
                    }}</span>
                  </td>
                  <td>
                    {{ data.seller.company_name }}<br />
                    <b>{{ data.seller.district.name }},{{ data.seller.state.name }}</b>
                  </td>
                  <td>
                    {{ data.buyer.company_name }}<br />
                    <b>{{ data.buyer.district.name }},{{ data.buyer.state.name }}</b>
                  </td>
                  <td v-if="currentTab == 'PENDING'">{{ data.quantity }}</td>
                  <td v-if="currentTab != 'PENDING'">{{ data.new_bid.quantity }}</td>

                  <td v-if="currentTab == 'PENDING'">₹ {{ data.bid_rate }}</td>
                  <td v-if="currentTab != 'PENDING'">{{ data.new_bid.bid_rate }}</td>

                  <td v-if="currentTab == 'PENDING'">
                    <button
                      type="button "
                      class="btn-brand-1 mr-3"
                      style="min-width: 75px !important"
                      v-on:click="bid(data)"
                    >
                      Send Offer
                    </button>
                    <button
                      type="button "
                      class="ignore-btn"
                      style="min-width: 75px !important"
                      v-on:click="showStatusPopup('IGNORE', data)"
                    >
                      Ignore
                    </button>
                  </td>
                  <td v-if="currentTab != 'PENDING'">
                    <button
                      type="button "
                      class="ignore-btn"
                      style="min-width: 75px !important"
                      v-on:click="showStatusPopup('REVOKE', data)"
                    >
                      <img src="/images/ignore-btn.svg" /> Revoke
                    </button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="row mx-0 mb-4">
            <div class="col-lg-12 mt-5 text-center">
              <div class="page-bottom-pagination text-right">
                <Pagination
                  @page-change="pageChange"
                  @items-per-page-change="itemsPerPageChange"
                  ref="listPagination"
                />
              </div>
            </div>
          </div>
          <ErrorComponent @retry="getList(0)" ref="errorComponent" />
        </div>
      </div>
    </div>
    <TextModal ref="addModal">
      <Form ref="addForm" @submit="checkOut()">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              v-on:click="this.$refs.addModal.closeModal()"
            >
              <img src="/images/close.svg" width="15" />
            </button>
          </div>
          <div class="modal-body floating-form" style="max-height: 500px; overflow: auto">
            <h4 class="font-22 modal-tital text-center mb-4">Book Trade</h4>
            <div class="row">
              <div class="col-md-12 align-self-end">
                <div class="floating-form-error mb-3">
                  <div class="floating-form-group">
                    <Field
                      type="text"
                      id="quantity"
                      name="quantity"
                      :class="book.quantity ? 'filled' : ''"
                      v-model="book.quantity"
                      :rules="'required:quantity|numeric|minValue:' + detail.min_quantity"
                      :validateOnInput="true"
                    />
                    <label for="quantity">Quantity</label>
                    <span class="text-danger">*</span>
                  </div>
                  <ErrorMessage name="quantity" class="validation-msg mt-1" />
                </div>
              </div>

              <div class="col-md-12 align-self-end">
                <div class="row">
                  <div class="col-md-6">
                    <label class="font-medium">Bid Rate (Per Kg)</label>
                  </div>
                  <div class="col-md-6 text-right">
                    <label class="font-medium">₹ {{ detail.bid_rate }}</label>
                  </div>
                </div>
              </div>

              <div class="col-md-12 align-self-end">
                <div class="row">
                  <div class="col-md-6">
                    <label class="font-medium">Avaliable Quantity</label>
                  </div>
                  <div class="col-md-6 text-right">
                    <label class="font-medium"> {{ detail.quantity }} (Kg)</label>
                  </div>
                </div>
              </div>

              <div class="col-md-6 align-self-end">
                <div class="floating-form-error mb-3">
                  <label>Payment Term <span class="text-danger">*</span></label>
                  <div class="floating-form-group mb-0">
                    <Field
                      as="select"
                      class="custom-select"
                      name="paymentTerm"
                      id="paymentTerm"
                      rules="required:Payment Term"
                      v-model="book.payment_term_id"
                      :validateOnInput="true"
                    >
                      <option value="">Select Payment Term</option>
                      <option
                        v-for="(obj, i) in paymentTermList"
                        :key="i"
                        :value="obj.id"
                      >
                        {{ obj.title }}
                      </option>
                    </Field>
                  </div>
                  <ErrorMessage name="paymentTerm" class="validation-msg mt-1" />
                </div>
              </div>

              <div class="col-md-6 align-self-end">
                <div class="floating-form-error mb-3">
                  <label>Delivery Term <span class="text-danger">*</span></label>
                  <div class="floating-form-group mb-0">
                    <Field
                      as="select"
                      class="custom-select"
                      name="deliveryTerm"
                      id="deliveryTerm"
                      rules="required:Delivery Term"
                      v-model="book.delivery_term_id"
                      :validateOnInput="true"
                    >
                      <option value="">Select Delivery Term</option>
                      <option
                        v-for="(obj, i) in deliveryTermList"
                        :key="i"
                        :value="obj.id"
                      >
                        {{ obj.title }}
                      </option>
                    </Field>
                  </div>
                  <ErrorMessage name="deliveryTerm" class="validation-msg mt-1" />
                </div>
              </div>
              <div class="col-md-12 align-self-end">
                <div class="floating-form-error mb-3">
                  <label>Delivery Address <span class="text-danger">*</span></label>
                  <div class="floating-form-group mb-0">
                    <Field
                      as="select"
                      class="custom-select"
                      name="deliveryAddress"
                      id="deliveryAddress"
                      rules="required:Delivery Address"
                      v-model="book.delivery_address_id"
                      :validateOnInput="true"
                      disabled
                    >
                      <option value="">Select Delivery Address</option>
                      <option
                        v-for="(obj, i) in deliveryAddressList"
                        :key="i"
                        :value="obj.id"
                      >
                        {{ obj.address }}, {{ obj.district.name }},{{ obj.state.name }}
                      </option>
                    </Field>
                  </div>
                  <ErrorMessage name="deliveryAddress" class="validation-msg mt-1" />
                </div>
              </div>

              <div class="col-md-12">
                <div class="floating-form-error mb-3">
                  <div class="floating-form-group">
                    <Field
                      type="text"
                      id="freight"
                      name="freight"
                      :class="book.freight_amount ? 'filled' : ''"
                      rules="required:freight|decimal"
                      v-model="book.freight_amount"
                    />
                    <label for="freight">Freight Amount</label
                    ><span class="text-danger">*</span>
                  </div>
                  <ErrorMessage name="freight" class="validation-msg mt-1" />
                </div>
              </div>

              <div class="col-lg-12 align-self-end">
                <label>Bid Rate Per (Kg) <span class="text-danger">*</span></label>
                <div class="floating-form-error mb-3">
                  <div class="floating-form-group">
                    <Field
                      type="text"
                      name="bid_rate"
                      id="bid_rate"
                      :class="book.bid_rate ? 'filled' : ''"
                      v-model="book.bid_rate"
                      rules="required:bid rate|numeric"
                      :validateOnInput="true"
                    />
                    <label for="bid_rate">Enter Bid Rate</label>
                  </div>
                  <ErrorMessage name="bid_rate" class="validation-msg mt-1" />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer border-0 justify-content-center">
            <button class="btn btn-brand-1 px-4" id="bid-button">Save</button>
          </div>
        </div>
      </Form>
    </TextModal>
    <TextModal ref="statusModal" class="modal-small">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" v-on:click="$refs.statusModal.closeModal">
            <img src="/images/close.svg" width="15" />
          </button>
        </div>
        <div class="modal-body text-center px-md-5">
          <h5 class="modal-title font-22 mb-4">
            {{ $helperService.getTitleCase(status) }} Bid
          </h5>

          <p class="font-18">
            Are you sure <br />
            you want to {{ $helperService.getTitleCase(status) }} bid ?
          </p>
          <div class="row" v-if="status == 'REJECT'">
            <div class="col-md-12">
              <label for="" class="float-left">Reasons </label>
              <div class="form-group">
                <select
                  name=""
                  class="form-control custom-select"
                  id=""
                  v-model="Narration.narration"
                >
                  <option value="Required higher Rate">Required higher Rate</option>
                  <option value="Change Broker">Change Broker</option>
                  <option value="Change Buyer">Change Buyer</option>
                  <option value="Goods are sold">Goods are sold</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>
            <div class="col-md-12" v-show="Narration.narration == 'Other'">
              <label for="" class="float-left">Other</label>
              <div class="form-group">
                <input type="text" v-model="OtherNarration" class="form-control" />
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer justify-content-center border-0 pb-4 pt-0">
          <div class="row">
            <div class="col-md-6">
              <button
                type="button"
                class="btn btn-brand-1 btn-block mb-3"
                id="status-button"
                style="min-width: 115px"
                v-on:click="updateStatus()"
              >
                Yes
              </button>
            </div>
            <div class="col-md-6">
              <button
                type="button"
                class="btn cancel-btn mb-3"
                style="min-width: 115px"
                v-on:click="$refs.statusModal.closeModal"
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </TextModal>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
import TextModal from "@/components/TextModal.vue";
import Pagination from "@/components/Pagination.vue";
export default {
  name: "MyLead",
  components: {
    ErrorComponent,
    TextModal,
    Pagination,
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      loader: true,
      offset: -1,
      list: [],
      currentTab: "PENDING",
      keyword: "",

      user_type: localStorage.getItem("user_type"),
      designation: localStorage.getItem("designation"),
      count: 0,
      status: "",
      paymentTermList: [],
      deliveryTermList: [],
      deliveryAddressList: [],
      book: {
        seller_id: "",
        product_id: this.$route.params.id,
        quantity: "",
        bid_rate: "",
        payment_term_id: "",
        delivery_term_id: "",
        delivery_address_id: "",
      },
      detail: {},
    };
  },
  mounted() {
    this.getPaymentTermList();
    this.getDeliveryTermList();
    this.getDeliveryAddressList();
    this.user_type = localStorage.getItem("user_type");
    this.$store.commit("showSidebar", true);
    var user = this.$store.getters.getUser;
    this.$helperService.checkAddress(user);
    this.getList(0);
  },
  methods: {
    getPaymentTermList() {
      this.$api
        .getAPI({
          _action: "payment-terms",
        })
        .then((res) => {
          for (var j in res.list) {
            this.paymentTermList.push(res.list[j]);
          }
        })
        .catch(() => {});
    },
    getDeliveryTermList() {
      this.$api
        .getAPI({
          _action: "delivery-terms",
        })
        .then((res) => {
          for (var j in res.list) {
            this.deliveryTermList.push(res.list[j]);
          }
        })
        .catch(() => {});
    },
    getDeliveryAddressList() {
      this.$api
        .getAPI({
          _action: "delivery/addresses",
          _body: { address_type: "SHIPPING" },
        })
        .then((res) => {
          for (var j in res.list) {
            this.deliveryAddressList.push(res.list[j]);
          }
        })
        .catch(() => {});
    },
    bid(data) {
      this.detail = data;
      this.deliveryAddressList.push(data.bid.delivery_address);
      this.book = {
        quantity: data.quantity,
        bid_rate: data.bid_rate,
        payment_term_id: data.bid.payment_term_id,
        delivery_term_id: data.bid.delivery_term_id,
        delivery_address_id: data.bid.delivery_address_id,
        freight_amount: "",
      };
      this.$refs.addModal.showModal();
    },
    checkOut() {
      this.$api
        .postAPI({
          _action: "broker/bid/" + this.detail.id,
          _body: this.book,
          _buttonId: "bid-button",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$refs.addModal.closeModal();
          this.getList(0);
        })
        .catch(() => {});
    },
    retry() {
      this.getList(0);
    },
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    changeTab(tab) {
      this.currentTab = tab;
      this.getList(0);
    },
    getList(page) {
      this.loader = true;
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }

      this.list = [];
      var searchObj = {
        status: this.currentTab,
      };

      if (this.keyword) {
        searchObj.keyword = this.keyword;
      }

      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }
      this.$api
        .getAPI({
          _action: "broker/leads",
          _body: searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.offset = res.next_offset;
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No Lead Found");
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    showStatusPopup(type, data) {
      this.status = type;
      this.detail = data;
      this.$refs.statusModal.showModal();
    },
    updateStatus() {
      if (this.status == "IGNORE") {
        this.$api
          .postAPI({
            _action: "broker/bid/ignore/" + this.detail.uuid,
            _buttonId: "status-button",
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.$refs.statusModal.closeModal();
            this.currentTab = "ACCEPTED";
            this.getList(0);
          })
          .catch(() => {});
      } else if (this.status == "REVOKE") {
        this.$api
          .postAPI({
            _action: "broker/bid/revoke/" + this.detail.uuid,
            _body: this.Narration,
            _buttonId: "status-button",
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.$refs.statusModal.closeModal();
            this.currentTab = "REJECTED";
            this.getList(0);
          })
          .catch(() => {});
      } else {
        this.$api
          .deleteAPI({
            _action: "buyer/bid/" + this.detail.uuid,
            _buttonId: "status-button",
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.$refs.statusModal.closeModal();
            this.getList(0);
          })
          .catch(() => {});
      }
    },
  },
};
</script>

