<template>
    <div>
        <div class="page-content">
            <div class="row mb-4 justify-content-center">
                <div class="col-lg-8">
                    <div class="row mb-md-4 mb-2 align-items-center">
                        <div class="col-md-6 text-md-left text-center">
                            <h2 class="page-title mb-md-0">Comission</h2>
                        </div>
                        <div class="col-md-6 text-right d-flex justify-content-end"></div>
                    </div>
                    <ul class="nav nav-pills filter-tabs mb-3" id="pills-tab" role="tablist">
                        <li>
                            <a class="nav-link" id="pills-pending-tab" data-toggle="pill" href="#pills-pending" role="tab"
                                aria-controls="pills-pending" aria-selected="false"
                                :class="currentTab == 'PENDING' ? 'active' : ''" v-on:click="changeTab('PENDING')"
                                style="cursor: pointer">Pending</a>
                        </li>
                        <li>
                            <a class="nav-link" id="pills-completed-tab" data-toggle="pill" href="#pills-completed"
                                role="tab" aria-controls="pills-completed" aria-selected="false"
                                :class="currentTab == 'COMPLETED' ? 'active' : ''" v-on:click="changeTab('COMPLETED')"
                                style="cursor: pointer">Transactions</a>
                        </li>
                    </ul>

                    <div class="card mb-4 rounded-l border-0 shadow-sm" v-if="currentTab == 'PENDING'">
                        <div class="card-body px-md-5">
                            <form class="form-style floating-form">
                                <div class="row justify-content-center">
                                    <div class="col-md-10">
                                        <div class="text-center">
                                            <h2 class="mb-4 font-22">Comission</h2>
                                        </div>
                                        <div class="d-flex justify-content-center">
                                            <div class="card card-body p-3 mb-3">
                                                <div class="
                              d-flex
                              align-items-center
                              justify-content-center
                            ">
                                                    <img src="images/rupees-coin.svg" width="50" alt="" />
                                                    <div class="pl-3">
                                                        <p class="mb-1">Total Comission</p>
                                                        <h3 class="mb-0 text-primary font-medium">
                                                            ₹ {{ total_amount }}
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <table class="table table-style-1 text-center">
                                            <tr>
                                                <th>Order ID</th>
                                                <th>Comission</th>
                                                <th>Action</th>
                                            </tr>
                                            <tr v-for="data in list" :key="data.id">
                                                <td>{{ data.order.order_no }}</td>
                                                <td>₹ {{ data.amount }}</td>
                                                <td>
                                                    <button type="button" class="btn btn-brand-1 mb-3 px-5"
                                                        @click="uploadUtr(data.order.uuid, data.amount)">
                                                        Upload UTR details
                                                    </button>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div class="card rounded-l border-0 shadow" v-if="currentTab == 'COMPLETED'">
                        <div class="card-body border-bottom expert-list" v-for="(data, index) in list" :key="index">
                            <div class="row">
                                <div class="col-md-9">
                                    <p class="mb-0 font-medium">UTR # {{ data.utr_number }}</p>
                                    <p class="mb-0 font-medium">Order No : {{ data.comission?.order.order_no }}</p>
                                    <p class="mb-0 font-medium">
                                        {{ data.payment_date }}
                                    </p>
                                </div>
                                <div class="col-md-3 text-right">
                                    <p class="mb-1 font-14 text-secondary">
                                        ₹ {{ data.paid_amount }}
                                    </p>

                                    <p class="mb-0 font-14 text-secondary">
                                        <span class="badge badge-light-yellow text-capitalize"
                                            v-if="data.status == 'UTR_SUBMITTED'"
                                            style="background: #E7A704; border-radius: 20px; color: #fff">UTR
                                            Submitted</span>
                                        <span class="badge badge-light-yellow text-capitalize"
                                            style="background: #95C72D; border-radius: 20px; color: #fff"
                                            v-else-if="data.status == 'PAYMENT_APPROVED'">Payment Accepted</span>
                                        <span class="badge badge-light-yellow text-capitalize"
                                            style="background: #ED3E07; border-radius: 20px; color: #fff"
                                            v-if="data.status == 'PAYMENT_REJECTED'">Payment Rejected</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0 mb-4">
                        <div class="col-lg-12 mt-5 text-center">
                            <div class="page-bottom-pagination text-right">
                                <Pagination @page-change="pageChange" @items-per-page-change="itemsPerPageChange"
                                    ref="listPagination" />
                            </div>
                        </div>
                    </div>
                    <ErrorComponent @retry="getList(0)" ref="errorComponent" />
                </div>
            </div>
        </div>
    </div>
    <TextModal ref="addModal">
        <Form ref="addForm" @submit="signup()">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" v-on:click="this.$refs.addModal.closeModal()">
                        <img src="/images/close.svg" width="15" />
                    </button>
                </div>
                <div class="modal-body floating-form">
                    <h4 class="font-22 modal-tital text-center mb-4">UTR Details</h4>
                    <div class="row">
                        <div class="col-md-12 align-self-end">
                            <div class="floating-form-error mb-3">
                                <div class="floating-form-group">
                                    <Field type="utr_number" id="utr_number" name="utr_number"
                                        :class="detail.utr_number ? 'filled' : ''" v-model="detail.utr_number"
                                        rules="required:utr number" :validateOnInput="true" />
                                    <label for="utr_number">UTR Number</label>
                                </div>
                                <ErrorMessage name="utr_number" class="validation-msg" />
                            </div>
                        </div>

                        <div class="col-md-12 align-self-end">
                            <div class="floating-form-group with-icon">
                                <Datepicker v-model="detail.payment_date" hideInputIcon :clearable="false"
                                    format="yyyy-MM-dd" placeholder="Select Payment Date" :disabled-date="notBeforeDate">
                                </Datepicker>
                                <img src="/images/calendar.svg" width="20" class="icon" />
                            </div>
                        </div>

                        <div class="col-md-12 align-self-end">
                            <div class="floating-form-error mb-3">
                                <div class="floating-form-group">
                                    <Field type="paid_amount" id="paid_amount" name="paid_amount"
                                        :class="detail.paid_amount ? 'filled' : ''" v-model="detail.paid_amount" disabled />
                                    <label for="paid_amount">Paid Amount</label>
                                </div>
                                <ErrorMessage name="paid_amount" class="validation-msg" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer border-0 justify-content-center mb-3">
                    <button class="btn btn-brand-1 px-4" id="signup-button">Save</button>
                </div>
            </div>
        </Form>
    </TextModal>
</template>
<script>
import TextModal from "@/components/TextModal.vue";
import Datepicker from "@vuepic/vue-datepicker";
import ErrorComponent from "@/components/ErrorComponent";
import { Form, Field, ErrorMessage } from "vee-validate";
import Pagination from "@/components/Pagination.vue";
import moment from "moment";
import router from "../../router";
export default {
    name: "MyFees",
    components: {
        Form,
        Field,
        ErrorMessage,
        Datepicker,
        TextModal,
        ErrorComponent, Pagination
    },
    data() {
        return {
            user_type: localStorage.getItem("user_type"),
            designation: localStorage.getItem("designation"),
            total_amount: 0,
            list: [],
            detail: {
                payment_date: "",
                booking_ids: "",
            },
            product: {},
            currentTab: "PENDING",
            loader: true,
            offset: -1,
            searchObj: {},
            orderId: ""
        };
    },
    mounted() {
        this.user_type = localStorage.getItem("user_type");
        this.$store.commit("showSidebar", true);
        this.getDetail(0);
    },
    methods: {
        redirectTo(data) {
            router.push("/transactions/" + data.id);
        },
        changeTab(tab) {
            this.currentTab = tab;
            if (this.currentTab == "PENDING") {
                this.getDetail(0);
            } else {
                this.getList(0);
            }
        },
        retry() {
            if (this.currentTab == "PENDING") {
                this.getDetail(0);
            } else {
                this.getList(0);
            }
        },
        pageChange(offset) {
            if (this.currentTab == "PENDING") {
                this.getDetail(offset - 1);
            } else {
                this.getList(offset - 1);
            }
        },
        itemsPerPageChange() {
            this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
            if (this.currentTab == "PENDING") {
                this.getDetail(0);
            } else {
                this.getList(0);
            }
        },
        getList(page) {
            this.loader = true;
            if (this.$refs.errorComponent) {
                this.$refs.errorComponent.updateLoader(true);
            }


            this.searchObj.offset = page;
            this.searchObj.status = this.currentTab;

            if (this.keyword) {
                this.searchObj.keyword = this.keyword;
            }

            this.list = [];
            if (this.$refs.errorComponent) {
                this.$refs.errorComponent.updateLoader(true);
            }
            var url = "";
            if (this.user_type == "BUYER") {
                url = "buyer/transactions";
            } else {
                url = "seller/transactions";
            }

            this.$api
                .getAPI({
                    _action: url,
                    _body: this.searchObj,
                })
                .then((res) => {
                    this.loader = false;
                    // for (var j in res.list) {
                    //     res.list[j].payment_date = moment(res.list[j].payment_date).format(
                    //         "DD MMM YYYY"
                    //     );
                    //     this.list.push(res.list[j]);
                    // }
                    this.list = res.list;
                    this.offset = res.next_offset;
                    if (page == 0) {
                        if (this.$refs.listPagination) {
                            if (res.count != null && res.count > 0) {
                                this.$refs.listPagination.setTotalCount(res.count);
                            } else {
                                this.$refs.listPagination.setTotalCount(0);
                            }
                        }
                    }
                    if (this.$refs.errorComponent) {
                        this.$refs.errorComponent.setData(res, "No Transaction Found");
                        this.$refs.errorComponent.updateLoader(false);
                    }
                })
                .catch(() => {
                    this.loader = false;
                    if (this.$refs.errorComponent) {
                        this.$refs.errorComponent.setData(0, undefined, "");
                        this.$refs.errorComponent.updateLoader(false);
                    }
                });
        },
        uploadUtr(id, paid_amount) {
            this.orderId = id;
            this.detail.paid_amount = paid_amount;
            this.$refs.addModal.showModal();
        },
        getDetail(page) {
            this.loader = true;
            if (this.$refs.errorComponent) {
                this.$refs.errorComponent.updateLoader(true);
            }


            this.list = [];
            this.searchObj.offset = page;
            this.searchObj.status = this.currentTab;

            if (this.keyword) {
                this.searchObj.keyword = this.keyword;
            }
            if (this.$refs.errorComponent) {
                this.$refs.errorComponent.updateLoader(true);
            }

            var url = "";
            if (this.user_type == "BUYER") {
                url = "buyer/comission";
            } else {
                url = "seller/comission";
            }
            this.$api
                .getAPI({
                    _action: url,
                    _body: this.searchObj,
                })
                .then((res) => {
                    this.total_amount = res.total_amount;
                    this.loader = false;
                    this.list = res.list;
                    this.offset = res.next_offset;
                    if (page == 0) {
                        if (this.$refs.listPagination) {
                            if (res.count != null && res.count > 0) {
                                this.$refs.listPagination.setTotalCount(res.count);
                            } else {
                                this.$refs.listPagination.setTotalCount(0);
                            }
                        }
                    }
                    if (this.$refs.errorComponent) {
                        this.$refs.errorComponent.setData(res, "No Comission Found");
                        this.$refs.errorComponent.updateLoader(false);
                    }

                })
                .catch(() => { });
        },

        signup() {
            if (!this.detail.payment_date) {
                this.$notify({
                    type: "error",
                    text: "Please select payment date",
                });
                return false;
            }
            this.detail.payment_date = moment(this.detail.payment_date).format(
                "yyyy-MM-DD"
            );

            var url = "";
            if (this.user_type == "BUYER") {
                url = "buyer/transaction/" + this.orderId;
            } else {
                url = "seller/transaction/" + this.orderId;
            }

            this.$api
                .postAPI({
                    _action: url,
                    _body: this.detail,
                    _buttonId: "signup-button",
                })
                .then((res) => {
                    this.$notify({
                        type: "success",
                        text: res.message,
                    });
                    this.$refs.addModal.closeModal();
                    this.getList(0);
                })
                .catch(() => { });
        },
    },
};
</script>

