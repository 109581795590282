<template>
  <div>
    <div class="page-content">
      <div class="row mb-4 justify-content-center">
        <div class="col-lg-12">
          <div class="row mb-md-4 mb-2 align-items-center">
            <div class="col-md-6 text-md-left text-center">
              <h2 class="page-title mb-md-0">
                <!-- <a class="cursor-pointer" v-on:click="this.$router.go(-1)">
                  <img
                    src="/images/back-arrow.svg"
                    width="17"
                    class="mr-2 align-top"
                    alt="" /></a> -->
                Manage Company
              </h2>
            </div>
            <div class="col-md-6 text-right d-flex justify-content-end">
              <!-- <input
                type="text"
                class="search-click"
                name=""
                v-on:keyup.enter="getList(0)"
                placeholder="search here..."
                v-model="keyword"
              /> -->
              <button
                type="button "
                class="btn btn-light ml-3 text-primary px-3 text-nowrap"
                style="background: #fff; box-shadow: 1px 1px 4px #0000001c; height: 45px"
                v-on:click="$router.push('add-company')"
              >
              Add company
              </button>
            </div>
          </div>

          <div>
            <div class="card rounded-l border-0">
              <div class="card-body border-bottom expert-list">
                <div class="row">
                  <div class="col-md-12"></div>
                </div>
                <div class="table-responsive">
                  <div class="dataTables_wrapper dt-bootstrap4">
                    <table
                      class="table table-striped table-bordered dataTable"
                      style="width: 100%"
                      role="grid"
                      aria-describedby="example_info"
                    >
                      <thead>
                        <tr role="row">
                          <th class="text-center">Sr. No.</th>
                          <th class="text-center">Company Name</th>
                          <th class="text-center">GST Number</th>
                          <th class="text-center">District</th>
                          <th class="text-center">State</th>
                          <th class="text-center">Status</th>
                          <th class="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody v-show="!loader">
                        <tr v-for="(data, index) in list" :key="index">
                          <td>
                            {{ ($refs.listPagination.currentPage - 1) * 10 + index + 1 }}
                          </td>

                          <td>{{ data.company_name }}</td>
                          <td>{{ data.gst_number }}</td>
                          <td v-if="data.district">{{ data.district.name }}</td>
                          <td v-else></td>
                          <td v-if="data.state">{{ data.state.name }}</td>
                          <td v-else></td>
                          <td>{{ data.status }}</td>
                          <td>
                            <a
                              class="cursor-pointer"
                              v-on:click="$router.push('/edit-company/' + data.uuid)"
                              ><i class="fa fa-pencil"></i></a
                            > &nbsp;&nbsp;
                            <a
                              class="cursor-pointer"
                              v-on:click="showDeletePopup(data)"
                              ><i class="fa fa-trash" style="color:#ff0000;"></i></a
                            >

                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="row mx-0 mb-4">
                  <div class="col-lg-12 mt-5 text-center">
                    <div class="page-bottom-pagination text-right">
                      <Pagination
                        @page-change="pageChange"
                        @items-per-page-change="itemsPerPageChange"
                        ref="listPagination"
                      />
                    </div>
                  </div>
                </div>
                <ErrorComponent @retry="getList(0)" ref="errorComponent" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <DeleteModal @remove="deleteRecord" ref="deleteModal" />
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import DeleteModal from "@/components/DeleteModal";
import Pagination from "@/components/Pagination.vue";
// import router from "../../router";
export default {
  name: "ManageCompany",
  components: {
    ErrorComponent,
    DeleteModal,
    Pagination,
  },
  data() {
    return {
      loader: true,
      offset: -1,
      list: [],
      keyword: "",
      delete: {
        id: "",
      },
      user_type: localStorage.getItem("user_type"),
      designation: localStorage.getItem("designation"),
    };
  },
  mounted() {
    this.user_type = localStorage.getItem("user_type");
    this.userId = localStorage.getItem("userId");
    this.$store.commit("showSidebar", true);
    var user = this.$store.getters.getUser;
    this.$helperService.checkAddress(user);
    this.getList(0);
  },
  methods: {
    redirectTo() {
      // router.push("/edit-employee/" + data.user.uuid);
    },
    showDeletePopup(data) {
      this.delete.id = data.uuid;
      this.$refs.deleteModal.showModal(
        "Delete User",
        "Are you sure you want to delete",
        data.uuid
      );
    },
    deleteRecord() {
      this.$api
        .deleteAPI({
          _action: "company/" + this.delete.id,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteModal.closeModal();
            this.getList(0);
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {
          this.$refs.deleteModal.removeLoader();
        });
    },
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    getList(page, orderBy, sortBy) {
      var searchObj = { searchObj: "", userId: "" };

      // this.$refs.errorComponent.updateLoader(true);
      this.list = [];
      searchObj.offset = page;
      if (orderBy) {
        searchObj.order_by = orderBy;
      }
      if (sortBy) {
        searchObj.sort_by = sortBy;
      }
      searchObj.userId = this.userId;
      searchObj.searchObj = this.keyword;

      this.$api
        .getAPI({
          _action: "list/company",
          _body: searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.offset = res.next_offset;
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No Company Found");
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
  },
};
</script>

