<template>
  <div>
    <div class="page-content">
      <div class="card rounded-l border-0 shadow mb-5">
        <div class="card-body border-bottom expert-list">
          <div id="chart"></div>
        </div>
      </div>
      <div class="row mb-4 justify-content-center">
        <div class="col-lg-12">
          <div class="row mb-md-4 mb-2 align-items-center">
            <div class="col-md-6 text-md-left text-center">
              <h2 class="page-title mb-md-0">
                <a class="cursor-pointer" v-on:click="this.$router.go(-1)">
                  <img
                    src="/images/back-arrow.svg"
                    width="17"
                    class="mr-2 align-top"
                    alt="" /></a
                >Seller List
              </h2>
            </div>
            <!-- <div class="col-md-6 text-right d-flex justify-content-end">
                            <input type="text" class="search-click" name="" v-on:keyup.enter="getList(0)"
                                placeholder="search here..." v-model="keyword" />
                        </div> -->
          </div>

          <div>
            <table class="table table-style-1">
              <tr style="border-radius: 8px 8px 0px 0px; background: #dfdfdf">
                <th class="text-left">Seller Name</th>
                <th class="text-left">Price (Per Kg)</th>
                <th class="text-left">GST</th>
                <th class="text-left">Ex-Mill Price (Per Kg)</th>
                <th class="text-left">Quantity (Kg)</th>
                <th class="text-left">Action</th>
              </tr>
              <tr v-for="(data, index) in list" :key="index">
                <td class="text-left">
                  <b>{{ data.company_name }}</b
                  >&nbsp;
                  <span class="preferred" v-if="data.is_preferred == 'Y'">Preferred</span
                  >&nbsp;
                  <span class="preferred" v-if="data.is_importer == 'Y'">Importer</span
                  >&nbsp;
                  <span
                    class="preferred"
                    v-if="data.is_importer == 'Y' && data.country"
                    style="color: black"
                    >{{ data.country.name }}</span
                  ><br />
                  <span class="text-muted"
                    >{{ data.district.name }}, {{ data.state.name }}</span
                  >
                </td>
                <td class="text-left">
                  <span v-if="data.view_price == 'Y'"> ₹ {{ data.price }}</span>
                </td>
                <td class="text-left">
                  <span v-if="data.view_price == 'Y'"> {{ data.gst_percentage }} %</span>
                </td>
                <td class="text-left">
                  <span v-if="data.view_price == 'Y'"> ₹{{ data.ex_mill_price }}</span>
                </td>
                <td class="text-left">{{ data.total_quantity }}</td>
                <td class="text-left">
                  <button
                    v-if="data.view_price == 'Y'"
                    type="button "
                    class="btn-brand-1"
                    style="min-width: 75px !important"
                    v-on:click="bid(data)"
                  >
                    Bid
                  </button>
                  <button
                    v-if="data.view_price == 'N'"
                    type="button "
                    class="btn-brand-1"
                    style="min-width: 75px !important"
                    v-on:click="viewPrice(data)"
                    id="view-button"
                  >
                    View Price
                  </button>
                </td>
              </tr>
            </table>
          </div>
          <div class="row mx-0 mb-4">
            <div class="col-lg-12 mt-5 text-center">
              <div class="page-bottom-pagination text-right">
                <Pagination
                  @page-change="pageChange"
                  @items-per-page-change="itemsPerPageChange"
                  ref="listPagination"
                />
              </div>
            </div>
          </div>
          <ErrorComponent @retry="getList(0)" ref="errorComponent" />
        </div>
      </div>
    </div>
  </div>
  <DeleteModal @remove="deleteRecord" ref="deleteModal" />
  <TextModal ref="addModal">
    <Form ref="addForm" @submit="checkOut()">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            v-on:click="this.$refs.addModal.closeModal()"
          >
            <img src="/images/close.svg" width="15" />
          </button>
        </div>
        <div class="modal-body floating-form" style="max-height: 500px; overflow: auto">
          <h4 class="font-22 modal-tital text-center mb-4">Book Trade</h4>
          <div class="row">
            <div class="col-md-12 align-self-end">
              <div class="floating-form-error mb-3">
                <div class="floating-form-group">
                  <Field
                    type="text"
                    id="quantity"
                    name="quantity"
                    :class="book.quantity ? 'filled' : ''"
                    v-model="book.quantity"
                    :rules="
                      'required:quantity|numeric|minValue:' +
                      this.detail.min_order_quantity
                    "
                    :validateOnInput="true"
                  />
                  <label for="quantity">Quantity (kg)</label>
                  <span class="text-danger">*</span>
                </div>
                <ErrorMessage name="quantity" class="validation-msg mt-1" />
              </div>
            </div>

            <div class="col-md-12 align-self-end">
              <div class="row">
                <div class="col-md-6">
                  <label class="font-medium">Ask Rate (Per Kg)</label>
                </div>
                <div class="col-md-6 text-right">
                  <label class="font-medium">₹ {{ detail.price }}</label>
                </div>
              </div>
            </div>

            <div class="col-md-12 align-self-end">
              <div class="row">
                <div class="col-md-6">
                  <label class="font-medium">Avaliable Quantity</label>
                </div>
                <div class="col-md-6 text-right">
                  <label class="font-medium"> {{ detail.total_quantity }} (Kg)</label>
                </div>
              </div>
            </div>

            <div class="col-md-6 align-self-end">
              <div class="floating-form-error mb-3">
                <label>Payment Term <span class="text-danger">*</span></label>
                <div class="floating-form-group mb-0">
                  <Field
                    as="select"
                    class="custom-select"
                    name="paymentTerm"
                    id="paymentTerm"
                    rules="required:Payment Term"
                    v-model="book.payment_term_id"
                    :validateOnInput="true"
                  >
                    <option value="">Select Payment Term</option>
                    <option v-for="(obj, i) in paymentTermList" :key="i" :value="obj.id">
                      {{ obj.title }}
                    </option>
                  </Field>
                </div>
                <ErrorMessage name="paymentTerm" class="validation-msg mt-1" />
              </div>
            </div>

            <div class="col-md-6 align-self-end">
              <div class="floating-form-error mb-3">
                <label>Delivery Term <span class="text-danger">*</span></label>
                <div class="floating-form-group mb-0">
                  <Field
                    as="select"
                    class="custom-select"
                    name="deliveryTerm"
                    id="deliveryTerm"
                    rules="required:Delivery Term"
                    v-model="book.delivery_term_id"
                    :validateOnInput="true"
                  >
                    <option value="">Select Delivery Term</option>
                    <option v-for="(obj, i) in deliveryTermList" :key="i" :value="obj.id">
                      {{ obj.title }}
                    </option>
                  </Field>
                </div>
                <ErrorMessage name="deliveryTerm" class="validation-msg mt-1" />
              </div>
            </div>

            <div class="col-md-12 align-self-end">
              <div class="floating-form-error mb-3">
                <label>Delivery Address <span class="text-danger">*</span></label>
                <div class="floating-form-group mb-0">
                  <Field
                    as="select"
                    class="custom-select"
                    name="deliveryAddress"
                    id="deliveryAddress"
                    rules="required:Delivery Address"
                    v-model="book.delivery_address_id"
                    :validateOnInput="true"
                  >
                    <option value="">Select Delivery Address</option>
                    <option
                      v-for="(obj, i) in deliveryAddressList"
                      :key="i"
                      :value="obj.id"
                    >
                      {{ obj.address }}, {{ obj.district.name }},{{ obj.state.name }}
                    </option>
                  </Field>
                </div>
                <ErrorMessage name="deliveryAddress" class="validation-msg mt-1" />
              </div>
            </div>

            <div class="col-lg-12 align-self-end">
              <label
                >Bid Rate Per (Kg) <span class="text-danger">*</span> <br />
                <strong
                  >Note: If bid rate is less then ask rate then bid is deducted.</strong
                ></label
              >
              <div class="floating-form-error mb-3">
                <div class="floating-form-group">
                  <Field
                    type="text"
                    name="bid_rate"
                    id="bid_rate"
                    :class="book.bid_rate ? 'filled' : ''"
                    v-model="book.bid_rate"
                    rules="required:bid rate|numeric"
                    :validateOnInput="true"
                  />
                  <label for="bid_rate">Enter Bid Rate</label>
                </div>
                <ErrorMessage name="bid_rate" class="validation-msg mt-1" />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 justify-content-center">
          <button class="btn btn-brand-1 px-4" id="bid-button">Send</button>
        </div>
      </div>
    </Form>
  </TextModal>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
import DeleteModal from "@/components/DeleteModal";
import TextModal from "@/components/TextModal.vue";
import router from "../../router";
import Pagination from "@/components/Pagination.vue";
import ApexCharts from "apexcharts";
export default {
  name: "MyEmployee",
  components: {
    ErrorComponent,
    DeleteModal,
    TextModal,
    Form,
    Field,
    ErrorMessage,
    Pagination,
  },
  data() {
    return {
      loader: true,
      offset: -1,
      list: [],
      keyword: "",
      id: this.$route.params.id,
      paymentTermList: [],
      deliveryTermList: [],
      deliveryAddressList: [],
      book: {
        seller_id: "",
        product_id: this.$route.params.id,
        quantity: "",
        bid_rate: "",
        payment_term_id: "",
        delivery_term_id: "",
      },
      detail: {},
      sellerId: "",
      chartData: [],
      searchObj: {},
    };
  },
  mounted() {
    this.getChartData();
    this.getPaymentTermList();
    this.getDeliveryTermList();
    this.getDeliveryAddressList();
  },
  methods: {
    getList(page) {
      this.loader = true;
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }

      this.list = [];
      this.searchObj.offset = page;
      this.searchObj.status = this.currentTab;

      if (this.keyword) {
        this.searchObj.keyword = this.keyword;
      }

      this.list = [];
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }

      this.$api
        .getAPI({
          _action: "buyer/product/" + this.id + "/seller/list",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.offset = res.next_offset;
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No Data Found");
            this.$refs.errorComponent.updateLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateLoader(false);
          }
        });
    },
    getPaymentTermList() {
      this.$api
        .getAPI({
          _action: "payment-terms",
        })
        .then((res) => {
          for (var j in res.list) {
            this.paymentTermList.push(res.list[j]);
          }
        })
        .catch(() => {});
    },
    getDeliveryTermList() {
      this.$api
        .getAPI({
          _action: "delivery-terms",
        })
        .then((res) => {
          for (var j in res.list) {
            this.deliveryTermList.push(res.list[j]);
          }
        })
        .catch(() => {});
    },
    getDeliveryAddressList() {
      this.$api
        .getAPI({
          _action: "delivery/addresses",
          _body: { address_type: "SHIPPING" },
        })
        .then((res) => {
          for (var j in res.list) {
            this.deliveryAddressList.push(res.list[j]);
          }
        })
        .catch(() => {});
    },
    bid(data) {
      this.detail = data;
      this.book = {
        seller_id: data.uuid,
        product_id: this.id,
        quantity: "",
        bid_rate: "",
        ask_rate: data.price,
        payment_term_id: "",
        delivery_term_id: "",
      };
      this.$refs.addModal.showModal();
    },
    checkOut() {
      if (this.book.id) {
        this.$api
          .putAPI({
            _action: "buyer/bid" + this.uuid,
            _body: this.book,
            _buttonId: "bid-button",
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.getMe();
            this.$refs.addModal.closeModal();
            router.go(-1);
          })
          .catch(() => {});
      } else {
        this.$api
          .postAPI({
            _action: "buyer/bid",
            _body: this.book,
            _buttonId: "bid-button",
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.getMe();
            this.$refs.addModal.closeModal();
            router.go(-1);
          })
          .catch(() => {});
      }
    },
    viewPrice(data) {
      this.$api
        .postAPI({
          _action: "buyer/view-price",
          _body: { product_id: this.id, seller_id: data.uuid },
          _buttonId: "view-button",
        })
        .then(() => {
          data.view_price = "Y";
          this.getMe();
        })
        .catch(() => {});
    },
    getChartData() {
      this.$api
        .getAPI({
          _action: "product-chart/" + this.$route.params.id,
        })
        .then((res) => {
          for (var i = 0; i < res.list.length; i++) {
            var obj = {
              x: new Date(res.list[i].date),
              y: [res.list[i].open, res.list[i].high, res.list[i].low, res.list[i].close],
            };
            this.chartData.push(obj);
          }
          var options = {
            series: [
              {
                data: this.chartData,
              },
            ],
            chart: {
              type: "candlestick",
              height: 350,
              stroke: {
                curve: "straight",
              },
            },
            xaxis: {
              type: "datetime",
            },
            stroke: {
              width: 5, // Adjust the candlestick border width (optional)
            },
          };

          var chart = new ApexCharts(document.querySelector("#chart"), options);
          chart.render();

          setTimeout(() => {
            this.getList(0);
          }, 10000);
        })
        .catch(() => {});
    },
    getMe() {
      this.$api
        .getAPI({
          _action: "me",
        })
        .then((res) => {
          this.$store.commit("setBid", res.info.avaliable_bid);
        })
        .catch(() => {});
    },
    retry() {
      this.getList(0);
    },
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
  },
};
</script>

<style scoped>
.apexcharts-candlestick-area {
  width: 5px !important;
  /* Adjust the width as needed */
}
</style>
