<template >
  <div>
    <OuterHeader />
    <main role="main">
      <div id="main-wrapper" class="login-wrap pt-0">
        <div class="container-fluid h-100">
          <div class="row justify-content-center min-vh-100 align-items-center">
            <div class="col-md-6">
              <div class="row justify-content-center">
                <div class="col-lg-6 card-body">
                  <div class="py-3">
                    <h4 class="font-weight-bold text-center">
                      Reset password?
                    </h4>
                    <div
                      class="
                        card-header
                        bg-white
                        rounded-10
                        text-center
                        border-0
                      "
                    >
                      <img
                        src="/images/reset-password.png"
                        width="220"
                        height="70"
                        class="img-fluid mb-lg-5 mb-4"
                      />
                    </div>

                    <div class="pt-1">
                      <div class="mb-3 text-secondary">
                        Please reset your password.
                      </div>
                      <Form class="form-style floating-form">
                        <Form @submit="resetPassword()">
                         <row>
                          <div class="">
                            <div class="floating-form-group mb-4">
                              <Field
                                :type="newPasswordFieldType"
                                id="password"
                                name="password"
                                v-model="password"
                                v-on:keydown.space="$event.preventDefault()"
                                rules="required:password|minLength:6|password"
                                :validateOnInput="true"
                              />

                              <label for="password">Password</label
                              ><span class="text-danger">*</span>
                              <a
                                class="psw-icon"
                                v-on:click="switchVisibilityNew"
                                ><img :src="imageNew" width="20"
                              /></a>
                            </div>
                            <ErrorMessage
                              name="password"
                              class="validation-msg mt-1"
                            />
                          </div>
                          <div class="">
                            <div class="floating-form-group mb-4">
                              <Field
                                :type="passwordFieldType"
                                id="conf_password"
                                name="confirm_password"
                                v-on:keydown.space="$event.preventDefault()"
                                rules="required:confirm password|confirmed:@password"
                                :validateOnInput="true"
                              />
                              <label for="conf_password"
                                >Confirm Password</label
                              >
                              <span class="text-danger">*</span>
                              <a class="psw-icon" v-on:click="switchVisibility"
                                ><img :src="image" width="20"
                              /></a>
                            </div>
                            <ErrorMessage
                              name="confirm_password"
                              class="validation-msg mt-1"
                            />
                          </div>
                          <div class="">
                          <button
                            class="btn btn-brand-1 btn-block"
                            id="submit-button"
                            style="cursor: pointer"
                          >
                            Submit
                          </button></div>
                        </row>
                        </Form>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import OuterHeader from "@/components/OuterHeader";
import { Form, Field, ErrorMessage } from "vee-validate";
import $ from "jquery";
import router from "../../router";
import api from "../../services/WebService";
export default {
  name: "ResetPassword",
  components: {
    Form,
    Field,
    ErrorMessage,
    OuterHeader,
  },
  data() {
    return {
      newPasswordFieldType: "password",
      passwordFieldType: "password",
      image: "/images/show.png",
      imageNew: "/images/show.png",
      password: "",
      mobile_number: "",
    };
  },
  mounted() {
    this.mobile_number = localStorage.getItem("forgot_mobile");
    if (!this.mobile_number) {
      router.push("/login");
    }
    $(
      ".floating-form-group input,.floating-form-group textarea,.floating-form select"
    ).change(function () {
      if ($(this).val() != "") {
        $(this).addClass("filled");
      } else {
        $(this).removeClass("filled");
      }
    });
  },
  methods: {
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.image =
        this.image === "/images/show.png"
          ? "/images/hide.png"
          : "/images/show.png";
    },
    switchVisibilityNew() {
      this.newPasswordFieldType =
        this.newPasswordFieldType === "password" ? "text" : "password";
      this.imageNew =
        this.imageNew === "/images/show.png"
          ? "/images/hide.png"
          : "/images/show.png";
    },
    resetPassword() {
      api
        .postAPI({
          _action: "reset-password",
          _body: { password: this.password, mobile_number: this.mobile_number },
          _buttonId: "submit-button",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          localStorage.setItem("forgot_mobile", "");
          router.push("/login");
        })
        .catch(() => {});
    },
  },
};
</script>
