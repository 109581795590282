<template>
    <div>
        <div class="page-content">
            <div class="row mb-4 justify-content-center">
                <div class="col-lg-8">
                    <div class="row mb-md-4 mb-2 align-items-center">
                        <div class="col-md-6 text-md-left text-center">
                            <h2 class="page-title mb-md-0">
                                <a class="cursor-pointer" v-on:click="this.$router.go(-1)">
                                    <img src="/images/back-arrow.svg" width="17" class="mr-2 align-top"
                                        alt="" /></a>Preferred Supplier
                            </h2>
                        </div>
                        <div class="col-md-6 text-right d-flex justify-content-end">
                            <input type="text" class="search-click" name="" v-on:keyup.enter="getList(0)"
                                placeholder="search here..." v-model="keyword" />
                            <button type="button " class="btn btn-light ml-3 text-primary px-3 text-nowrap" style="
                    background: #fff;
                    box-shadow: 1px 1px 4px #0000001c;
                    height: 38px;
                  " v-on:click="showmodal()" v-if="designation == 'PRIMARY_OWNER' ||
                      designation == 'SECONDERY_OWNER'
                      ">
                                Add
                            </button>
                        </div>
                    </div>

                    <div>
                        <div class="card rounded-l border-0 shadow" v-if="list.length > 0">
                            <div class="card-body border-bottom expert-list" v-for="(data, index) in list" :key="index">
                                <div class="row align-items-center">
                                    <div class="col-md-9">
                                        <div class="d-flex">
                                            <div>
                                                <p class="mb-0 font-medium">{{ data.company_name }}</p>
                                                <p class="text-secondary font-14">
                                                    {{ data.district.name }},{{ data.state.name }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3 text-right">
                                        <a v-if="designation == 'PRIMARY_OWNER' ||
                                            designation == 'SECONDERY_OWNER'
                                            " href="javascript:void(0)" class="font-20 text-danger"
                                            @click="showDeletePopup(data.id)"><i class="fa fa-trash"></i></a>
                                    </div>
                                    <hr />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0 mb-4">
                        <div class="col-lg-12 mt-5 text-center">
                            <div class="page-bottom-pagination text-right">
                                <Pagination @page-change="pageChange" @items-per-page-change="itemsPerPageChange"
                                    ref="listPagination" />
                            </div>
                        </div>
                    </div>
                    <ErrorComponent @retry="getList(0)" ref="errorComponent" />
                </div>
            </div>
        </div>
    </div>
    <DeleteModal @remove="deleteRecord" ref="deleteModal" />
    <TextModal ref="addModal">
        <Form ref="addForm" @submit="checkOut()">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" v-on:click="this.$refs.addModal.closeModal()">
                        <img src="/images/close.svg" width="15" />
                    </button>
                </div>
                <div class="modal-body floating-form" style="max-height: 500px; overflow: auto">
                    <h4 class="font-22 modal-tital text-center mb-4">Search Supplier</h4>
                    <div class="row">
                        <div class="col-md-12 align-self-end">
                            <div class="floating-form-error mb-3">
                                <label>Search Suppler Name <span class="text-danger">*</span></label>
                                <div class="floating-form-group mb-0">
                                    <Field as="select" class="custom-select" name="seller" id="seller"
                                        rules="required:seller,true" v-model="sellerId" :validateOnInput="true">
                                        <option value="">Select Suppler</option>
                                        <option v-for="(obj, i) in sellerList" :key="i" :value="obj.id">
                                            {{ obj.company_name }}
                                        </option>
                                    </Field>
                                </div>
                                <ErrorMessage name="seller" class="validation-msg" />
                            </div>
                        </div>

                    </div>
                </div>
                <div class="modal-footer border-0 justify-content-center">
                    <button class="btn btn-brand-1 px-4" id="bid-button">Save</button>
                </div>
            </div>
        </Form>
    </TextModal>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
import DeleteModal from "@/components/DeleteModal";
import Pagination from "@/components/Pagination.vue";
import TextModal from "@/components/TextModal.vue";
export default {
    name: "PreferredSupplier",
    components: {
        ErrorComponent,
        DeleteModal, Pagination, Form, Field, ErrorMessage, TextModal
    },
    data() {
        return {
            loader: true,
            offset: -1,
            list: [],
            keyword: "",
            user_type: localStorage.getItem("user_type"),
            designation: localStorage.getItem("designation"),
            sellerId: "",
            sellerList: []
        };
    },
    mounted() {
        this.user_type = localStorage.getItem("user_type");
        this.$store.commit("showSidebar", true);
        var user = this.$store.getters.getUser;
        this.$helperService.checkAddress(user);
        this.getList(0);
        this.getSupplierList();
    },
    methods: {
        showmodal() {
            this.sellerId = "";
            this.$refs.addModal.showModal();
        },
        checkOut() {
            this.$api
                .postAPI({
                    _action: "buyer/preferred-supplier",
                    _body: { seller_company_id: this.sellerId },
                    _buttonId: "bid-button",
                })
                .then((res) => {
                    this.$notify({
                        type: "success",
                        text: res.message,
                    });
                    this.$refs.addModal.closeModal();
                    this.getList(0);
                })
                .catch(() => { });
        },
        getSupplierList() {
            this.$api
                .getAPI({
                    _action: "seller/search",
                })
                .then((res) => {
                    this.sellerList = res.list;
                })
                .catch(() => { });
        },
        showDeletePopup(id) {
            this.$refs.deleteModal.showModal(
                "Delete Supplier",
                "Are you sure you want to delete",
                id
            );
        },
        deleteRecord(id) {
            this.$api
                .deleteAPI({
                    _action: "/buyer/preferred-supplier/" + id,
                })
                .then((res) => {
                    if (res) {
                        this.$refs.deleteModal.closeModal();
                        this.getList(0);
                        this.$notify({
                            type: "success",
                            text: res.message,
                        });
                    }
                })
                .catch(() => {
                    this.$refs.deleteModal.removeLoader();
                });
        },
        retry() {
            this.getList(0);
        },
        pageChange(offset) {
            this.getList(offset - 1);
        },
        itemsPerPageChange() {
            this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
            this.getList(0);
        },
        getList(page) {
            this.loader = true;
            if (this.$refs.errorComponent) {
                this.$refs.errorComponent.updateLoader(true);
            }


            this.list = [];
            var searchObj = { searchObj: "" };

            if (this.keyword) {
                searchObj.keyword = this.keyword;
            }

            searchObj.offset = page;
            if (this.$refs.errorComponent) {
                this.$refs.errorComponent.updateLoader(true);
            }

            this.$api
                .getAPI({
                    _action: "buyer/preferred-suppliers",
                    _body: searchObj,
                })
                .then((res) => {
                    this.loader = false;
                    this.list = res.list;
                    this.offset = res.next_offset;
                    if (page == 0) {
                        if (this.$refs.listPagination) {
                            if (res.count != null && res.count > 0) {
                                this.$refs.listPagination.setTotalCount(res.count);
                            } else {
                                this.$refs.listPagination.setTotalCount(0);
                            }
                        }
                    }
                    if (this.$refs.errorComponent) {
                        this.$refs.errorComponent.setData(res, "No Supplier Found");
                        this.$refs.errorComponent.updateLoader(false);
                    }
                })
                .catch(() => {
                    this.loader = false;
                    if (this.$refs.errorComponent) {
                        this.$refs.errorComponent.setData(0, undefined, "");
                        this.$refs.errorComponent.updateLoader(false);
                    }
                });
        },
    },
};
</script>

