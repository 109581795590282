<template>
  <div>
    <div class="page-content">
      <div class="row mb-4 justify-content-start">
        <div class="col-lg-12">
          <div class="row mb-md-4 mb-2 align-items-center">
            <div class="col-md-6 text-md-left text-center">
              <h2 class="page-title mb-md-0">
                <a class="cursor-pointer" v-on:click="this.$router.go(-1)">
                  <img
                    src="/images/back-arrow.svg"
                    width="17"
                    class="mr-2 align-top"
                    alt="" /></a
                >Home
              </h2>
            </div>
          </div>
        </div>

        <ul
          class="nav nav-pills filter-tabs mb-3 ml-3"
          id="pills-tab"
          role="tablist"
          v-if="user_type == 'SELLER'"
        >
          <li class="nav-item" role="presentation">
            <a
              class="nav-link"
              id="pills-product-tab"
              data-toggle="pill"
              href="#pills-product"
              role="tab"
              aria-controls="pills-product"
              aria-selected="true"
              :class="tab == 'MY_PRODUCT' ? 'active' : ''"
              v-on:click="changeTab('MY_PRODUCT')"
              style="cursor: pointer"
              >My Product
            </a>
          </li>
          <li class="nav-item" role="presentation">
            <a
              class="nav-link"
              id="pills-price-tab"
              data-toggle="pill"
              href="#pills-price"
              role="tab"
              aria-controls="pills-price"
              aria-selected="false"
              :class="tab == 'PRODUCT_PRICE' ? 'active' : ''"
              v-on:click="changeTab('PRODUCT_PRICE')"
              style="cursor: pointer"
              >Product Price</a
            >
          </li>

          <li class="nav-item" role="presentation">
            <a
              class="nav-link"
              id="pills-company-tab"
              data-toggle="pill"
              href="#pills-company"
              role="tab"
              aria-controls="pills-company"
              aria-selected="false"
              :class="tab == 'MY_COMPANY' ? 'active' : ''"
              v-on:click="changeTab('MY_COMPANY')"
              style="cursor: pointer"
              >My Company Profile</a
            >
          </li>
        </ul>

        <div class="col-lg-3" v-if="user_type == 'BUYER'">
          <div class="px-4 pt-4 pb-1" style="border-radius: 5px; background: #fff">
            <div class="row mb-md-2 align-items-start">
              <div class="col-md-12 align-self-end">
                <div class="form-group">
                  <Field
                    as="select"
                    class="custom-select"
                    v-model="searchObj.yarn_id"
                    name="yarn"
                    @update:model-value="getYarnList"
                  >
                    <option value="">Category Type</option>
                    <option
                      v-for="(data, index) in yarnList"
                      :key="index"
                      :value="data.id"
                    >
                      {{ data.name }}
                    </option>
                  </Field>
                </div>
              </div>
              <div class="col-md-12 align-self-end">
                <div class="form-group">
                  <Field
                    as="select"
                    class="custom-select"
                    v-model="searchObj.sub_yarn_id"
                    name="yarn"
                    :disabled="!searchObj.yarn_id"
                    @update:model-value="getYarnValueList()"
                  >
                    <option value="">Sub Category Type</option>
                    <option
                      v-for="(data, index) in yarnSubList"
                      :key="index"
                      :value="data.id"
                    >
                      {{ data.name }}
                    </option>
                  </Field>
                </div>
              </div>

              <div class="col-md-12 align-self-end">
                <div class="form-group">
                  <Field
                    as="select"
                    class="custom-select"
                    v-model="searchObj.yarn_value_id"
                    name="yarn"
                    :disabled="!searchObj.sub_yarn_id"
                  >
                    <option value="">Category Value</option>
                    <option
                      v-for="(data, index) in yarnValueList"
                      :key="index"
                      :value="data.id"
                    >
                      {{ data.value }}
                    </option>
                  </Field>
                </div>
              </div>

              <div class="modal-footer border-0 justify-content-start">
                <button
                  class="btn btn-brand-1 px-3"
                  id="bid-button"
                  @click="getList(0)"
                  style="min-width: 100px !important"
                >
                  Search
                </button>
                <button
                  class="btn cancel-btn px-3"
                  @click="clearModal()"
                  style="min-width: 100px !important"
                >
                  Clear
                </button>

                <button
                  class="btn btn-brand-1 px-3"
                  id="bid-button"
                  v-on:click="$router.push('add-buyer-product')"
                  style="min-width: 100px !important"
                >
                  Request Product
                </button>
              </div>
            </div>
          </div>
        </div>
        <div :class="user_type == 'BUYER' ? 'col-lg-9' : 'col-lg-12'">
          <ul
            class="nav nav-pills filter-tabs mb-3 ml-3"
            id="pills-tab"
            role="tablist"
            v-if="user_type == 'BUYER'"
          >
            <li class="nav-item" role="presentation">
              <a
                class="nav-link"
                id="pills-buyerproduct-tab"
                data-toggle="pill"
                href="#pills-buyerproduct"
                role="tab"
                aria-controls="pills-buyerproduct"
                aria-selected="true"
                :class="buyertab == 'SELLER_PRODUCT' ? 'active' : ''"
                v-on:click="changeTabBuyer('SELLER_PRODUCT')"
                style="cursor: pointer"
                >Seller Product
              </a>
            </li>
            <li class="nav-item" role="presentation">
              <a
                class="nav-link"
                id="pills-requestedprice-tab"
                data-toggle="pill"
                href="#pills-requestedprice"
                role="tab"
                aria-controls="pills-requestedprice"
                aria-selected="false"
                :class="buyertab == 'PRODUCT_PRICE' ? 'active' : ''"
                v-on:click="changeTabBuyer('REQUESTED_PRODUCT')"
                style="cursor: pointer"
                >Requested Product</a
              >
            </li>
          </ul>

          <div class="row align-items-start">
            <div
              class="col-md-12 text-right d-flex justify-content-start"
              v-if="tab != 'MY_COMPANY'"
            >
              <div class="form-group" v-if="user_type == 'SELLER' && tab != 'MY_COMPANY'">
                <Field
                  as="select"
                  class="custom-select"
                  v-model="searchObj.yarn_id"
                  name="yarn"
                  @update:model-value="getList(0)"
                >
                  <option value="">Category Type</option>
                  <option v-for="(data, index) in yarnList" :key="index" :value="data.id">
                    {{ data.name }}
                  </option>
                </Field>
              </div>

              <div
                class="form-group ml-3"
                v-if="user_type == 'SELLER' && tab != 'MY_COMPANY'"
              >
                <Field
                  as="select"
                  class="custom-select"
                  v-model="searchObj.status"
                  name="yarn"
                  @update:model-value="getList(0)"
                >
                  <option value="ACTIVE">Active</option>
                  <option value="SELLER_REQUESTED">Requested</option>
                </Field>
              </div>

              <button
                v-if="user_type == 'SELLER' && tab != 'MY_COMPANY'"
                type="button "
                class="btn btn-light ml-3 text-primary px-3 text-nowrap"
                style="background: #fff; box-shadow: 1px 1px 4px #0000001c; height: 38px"
                v-on:click="$router.push('add-product')"
              >
                Add Product
              </button>
            </div>
          </div>
          <div>
            <template v-if="user_type == 'BUYER' || user_type == 'BROKER'">
              <div class="card rounded-l border-0 shadow" v-if="list.length > 0">
                <div
                  class="card-body border-bottom expert-list"
                  style="border-radius: 0"
                  v-for="(data, index) in list"
                  :key="index"
                >
                  <div class="row">
                    <div class="col-md-9">
                      <p class="mb-1">
                        <b>{{ data.yarn.name }}</b>
                      </p>
                      <p class="mt-2 mb-0">
                        <span class="badge-btn" v-if="data.sub_yarn">{{
                          data.sub_yarn.name
                        }}</span>
                        <span class="m-2 badge-btn" v-if="data.yarn_value">{{
                          data.yarn_value.value
                        }}</span>
                      </p>

                      <p class="mt-2 mb-0">
                        <template v-for="item in data.list" :key="item">
                          <span class="badge-btn mr-1">
                            {{ item.composition.name }} {{ item.value }}%
                          </span>
                        </template>
                      </p>
                    </div>
                    <div class="col-md-3 text-right">
                      <button
                        v-if="data.status == 'ACTIVE'"
                        type="button"
                        class="btn btn-light ml-3 text-nowrap btn-brand-1"
                        v-on:click="$router.push('/view-price/' + data.uuid)"
                      >
                        View Price
                      </button>
                      <span
                        v-else
                        class="badge badge-light-yellow text-capitalize"
                        style="background: #e7a704; border-radius: 20px; color: #fff"
                        >Pending from admin
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mx-0 mb-4">
                <div class="col-lg-12 mt-5 text-center">
                  <div class="page-bottom-pagination text-right">
                    <Pagination
                      @page-change="pageChange"
                      @items-per-page-change="itemsPerPageChange"
                      ref="listPagination"
                    />
                  </div>
                </div>
              </div>
              <ErrorComponent @retry="retry()" ref="errorComponent" />
            </template>
            <template v-if="user_type == 'SELLER' && tab != 'MY_COMPANY'">
              <div class="card rounded-l border-0 shadow" v-if="list.length > 0">
                <table class="table table-style-1 text-center form-style floating-form">
                  <tr>
                    <th>Type of Category</th>
                    <th>Product Information</th>
                    <th>Quantity (in Kg)</th>
                    <th>Price (Per Kg)</th>
                    <th>GST</th>
                    <th>Ex-Mill Price (Per Kg)</th>
                    <th>Action</th>
                  </tr>
                  <tr v-for="(data, index) in list" :key="data.id">
                    <td>{{ data.name }}</td>
                    <td>
                      <p class="mt-2 mb-0">
                        <span class="badge-btn" v-if="data.sub_yarn">{{
                          data.sub_yarn.name
                        }}</span>
                        <span class="m-2 badge-btn" v-if="data.yarn_value">{{
                          data.yarn_value.value
                        }}</span>
                      </p>

                      <p class="mt-2 mb-0">
                        <template v-for="item in data.list" :key="item">
                          <span class="badge-btn mr-1">
                            {{ item.composition.name }} {{ item.value }}%
                          </span>
                        </template>
                      </p>
                    </td>
                    <td v-if="tab == 'MY_PRODUCT'">{{ data.total_quantity }}</td>
                    <td v-if="tab == 'PRODUCT_PRICE'">
                      <div class="border-box">
                        <Field
                          type="text"
                          id="total_quantity"
                          name="total_quantity"
                          :class="data.total_quantity ? 'filled' : ''"
                          rules="required:total_quantity|decimal"
                          v-model="data.total_quantity"
                        />
                      </div>
                      <ErrorMessage name="total_quantity" class="validation-msg mt-1" />
                    </td>
                    <td v-if="tab == 'MY_PRODUCT'">₹ {{ data.price }}</td>
                    <td v-if="tab == 'PRODUCT_PRICE'">
                      <div class="border-box">
                        <Field
                          type="text"
                          id="price"
                          name="price"
                          :class="data.price ? 'filled' : ''"
                          rules="required:price|decimal"
                          v-model="data.price"
                          @blur="setMillPrice(data, index)"
                        />
                      </div>
                      <ErrorMessage name="price" class="validation-msg mt-1" />
                    </td>

                    <td v-if="tab == 'MY_PRODUCT'">{{ data.gst_percentage }} %</td>
                    <td v-if="tab == 'PRODUCT_PRICE'">
                      <div class="form-group">
                        <Field
                          as="select"
                          class="custom-select"
                          v-model="data.gst_percentage"
                          :name="'gst_percentage_' + index"
                          rules="required:gst percentage,true"
                          @update:model-value="setMillPrice(data, index)"
                        >
                          <option value="">Select GST Percentage</option>
                          <option value="5">5 %</option>
                          <option value="12">12 %</option>
                          <option value="18">18 %</option>
                        </Field>
                      </div>
                      <ErrorMessage
                        :name="'gst_percentage_' + index"
                        class="validation-msg mt-1"
                      />
                    </td>
                    <td>₹ {{ data.ex_mill_price }}</td>
                    <td v-if="tab == 'MY_PRODUCT'">
                      <a @click="edit(data.id)" class="mr-3"
                        ><i class="fa fa-pencil"></i
                      ></a>
                      <a @click="showDeletePopup(data.id)"
                        ><i class="fa fa-trash" style="color: #ff0000"></i
                      ></a>
                    </td>
                    <td v-if="tab == 'PRODUCT_PRICE'">
                      <button
                        type="button "
                        class="update-btn ml-3"
                        id="update-button"
                        style="
                          background: #fff;
                          box-shadow: 1px 1px 4px #0000001c;
                          height: 38px;
                        "
                        v-on:click="update(data)"
                      >
                        Update
                      </button>
                    </td>
                  </tr>
                </table>
              </div>

              <div class="row mx-0 mb-4">
                <div class="col-lg-12 mt-5 text-center">
                  <div class="page-bottom-pagination text-right">
                    <Pagination
                      @page-change="pageChange"
                      @items-per-page-change="itemsPerPageChange"
                      ref="listPagination"
                    />
                  </div>
                </div>
              </div>
              <ErrorComponent @retry="retry()" ref="errorComponent" />
            </template>
            <template v-if="user_type == 'SELLER' && tab == 'MY_COMPANY'">
              <div class="card-body pb-0 pt-lg-3">
                <form class="form-style floating-form">
                  <Form @submit="signup">
                    <div class="row mt-4">
                      <div class="col-md-3 mb-3">
                        <div class="border-box">
                          <!--floating-form-group-->
                          <label for="compny_name">Company Name</label
                          ><span class="text-danger">*</span><br />
                          <Field
                            type="text"
                            id="compny_name"
                            v-model="detail.company_name"
                            rules="required:company"
                            name="company"
                            :class="detail.company_name ? 'filled' : ''"
                            :validateOnInput="true"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="company" class="validation-msg mt-1" />
                      </div>
                      <div class="col-md-3 mb-3">
                        <div class="border-box floating-form-error">
                          <div class="border-boxborder-box">
                            <label for="gst_number">GST</label><br />
                            <Field
                              type="text"
                              id="gst_number_id"
                              v-model="detail.gst_number"
                              name="gst_number"
                              :class="detail.gst_number ? 'filled' : ''"
                              rules="required:GST number|gst"
                              :validateOnInput="true"
                              v-on:keyup="checkGst()"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <ErrorMessage name="gst_number" class="validation-msg mt-1" />
                      </div>
                      <div class="col-md-3 mb-3">
                        <div class="border-box">
                          <label for="email">Email</label
                          ><span class="text-danger">*</span><br />
                          <Field
                            type="email"
                            id="email"
                            name="email"
                            v-model="detail.owner.email"
                            rules="required:email"
                            :validateOnInput="true"
                            :class="detail.owner.email ? 'filled' : ''"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="email" class="validation-msg mt-1" />
                      </div>
                      <div class="col-md-3 mb-3">
                        <div class="border-box">
                          <!-- floating-form-group-->
                          <label for="phone">Phone</label
                          ><span class="text-danger">*</span>
                          <Field
                            type="text"
                            id="phone"
                            name="phone"
                            v-model="detail.owner.mobile_number"
                            rules="required:contact number|phone"
                            :class="detail.owner.mobile_number ? 'filled' : ''"
                            :validateOnInput="true"
                            maxlength="10"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="phone" class="validation-msg mt-1" />
                      </div>
                      <div class="col-md-12 mb-2 mt-2">
                        <h5><b>Address</b></h5>
                        <hr />
                      </div>
                      <div class="col-md-12 mb-3">
                        <div class="border-box">
                          <!-- floating-form-group-->
                          <label for="address">Address</label
                          ><span class="text-danger">*</span><br />
                          <Field
                            type="text"
                            id="address"
                            name="address"
                            v-model="detail.billing_address.address"
                            rules="required:address"
                            :class="detail.billing_address.address ? 'filled' : ''"
                            :validateOnInput="true"
                            maxlength="10"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="address" class="validation-msg mt-1" />
                      </div>
                      <div class="col-md-6 mb-3">
                        <!-- <div class="form-group"> -->
                        <label for="state">State</label>
                        <Field
                          as="select"
                          class="custom-select"
                          v-model="detail.billing_address.state_id"
                          name="state"
                          rules="required:state,true"
                          @update:model-value="getDistrictList()"
                        >
                          <option value="">Select State</option>
                          <option
                            v-for="(data, index) in stateList"
                            :key="index"
                            :value="data.id"
                          >
                            {{ data.name }}
                          </option>
                        </Field>
                        <!-- </div> -->
                        <ErrorMessage name="state" class="validation-msg mt-1" />
                      </div>

                      <div class="col-md-6 mb-3">
                        <!-- <div class="form-group"> -->
                        <label for="district">District</label>
                        <Field
                          as="select"
                          class="custom-select"
                          v-model="detail.billing_address.district_id"
                          name="district"
                          rules="required:district,true"
                          :disabled="!detail.billing_address.state_id"
                        >
                          <option value="">Select District</option>
                          <option
                            v-for="(data, index) in districtList"
                            :key="index"
                            :value="data.id"
                          >
                            {{ data.name }}
                          </option>
                        </Field>
                        <!-- </div> -->
                        <ErrorMessage name="district" class="validation-msg mt-1" />
                      </div>
                    </div>
                    <div class="text-center">
                      <button class="btn btn-brand-1 mb-3" id="signup-button">
                        Update
                      </button>
                    </div>
                  </Form>
                </form>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <DeleteModal @remove="deleteRecord" ref="deleteModal" />
  </div>
</template>
<script>
import DeleteModal from "@/components/DeleteModal";
import { Form, Field, ErrorMessage } from "vee-validate";
import $ from "jquery";
import ErrorComponent from "@/components/ErrorComponent";
import router from "../../router";
import Pagination from "@/components/Pagination.vue";
export default {
  name: "MyHome",
  components: {
    ErrorComponent,
    ErrorMessage,
    Field,
    Form,
    DeleteModal,
    Pagination,
  },
  data() {
    return {
      loader: false,
      offset: -1,
      list: [],
      searchObj: {
        keyword: "",
        offset: 0,
        company_name: "",
        yarn_id: "",
        yarn_sub_id: "",
        yarn_value_id: "",
        status: "ACTIVE",
      },
      user_type: localStorage.getItem("user_type"),
      yarnList: [],
      yarnSubList: [],
      yarnValueList: [],
      tab: "MY_PRODUCT",
      detail: { owner: {}, billing_address: {} },
      stateList: [],
      districtList: [],
      buyertab: "SELLER_PRODUCT",
    };
  },
  mounted() {
    this.user_type = localStorage.getItem("user_type");
    this.$store.commit("showSidebar", true);
    var user = this.$store.getters.getUser;
    this.$helperService.checkAddress(user);

    this.getStateList();
    this.getList(0);
    this.getYarnList();
    $(document).ready(function () {
      $(
        ".floating-form-group input,.floating-form-group textarea,.floating-form select"
      ).change(function () {
        if ($(this).val() != "") {
          $(this).addClass("filled");
        } else {
          $(this).removeClass("filled");
        }
      });
    });
  },
  methods: {
    getYarnValueList() {
      this.$api
        .getAPI({
          _action: "yarn-values/" + this.searchObj.sub_yarn_id,
        })
        .then((res) => {
          this.yarnValueList = res.list;
        })
        .catch(() => {});
    },
    setMillPrice(data, index) {
      var ExMillPrice,
        price,
        gst = 0;
      price = parseFloat(this.list[index].price);
      gst = parseFloat(this.list[index].gst_percentage);
      if (gst) {
        ExMillPrice = price + (price * gst) / 100;
        this.list[index].ex_mill_price = parseFloat(ExMillPrice);
      } else {
        this.list[index].ex_mill_price = 0;
      }
    },
    onChange(e) {
      let files = e.target.files;
      this.file = files[0];
      if (e.target.files && e.target.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
          $("#img_hoder_1").attr("src", e.target.result);
        };
      }
      reader.readAsDataURL(e.target.files[0]);
      this.uploadFile();
    },
    uploadFile() {
      this.$api
        .uploadImageAPI({
          _action: "company/image",
          _key: "image",
          _file: this.file,
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
        })
        .catch((e) => {
          this.$notify({
            type: "error",
            text: e.message,
          });
        });
    },
    edit(id) {
      router.push("/edit-product/" + id);
    },
    showDeletePopup(id) {
      this.$refs.deleteModal.showModal(
        "Delete Product",
        "Are you sure you want to delete ?",
        id
      );
    },
    deleteRecord(id) {
      this.$api
        .deleteAPI({
          _action: "/seller/product/" + id,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteModal.closeModal();
            this.getList(0);
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {
          this.$refs.deleteModal.removeLoader();
        });
    },
    retry() {
      this.getList(0);
    },
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    changeTabBuyer(tab) {
      this.buyertab = tab;
      this.list = [];
      if (this.buyertab == "REQUESTED_PRODUCT") {
        this.searchObj.status = "BUYER_REQUESTED";
      } else {
        this.searchObj.status = "ACTIVE";
      }
      this.getList(0);
    },
    changeTab(tab) {
      this.tab = tab;
      this.list = [];
      if (tab == "MY_PRODUCT" || tab == "PRODUCT_PRICE") {
        this.getList(0);
      } else {
        this.getCompanyDetail();
      }
    },
    selectFilter() {
      this.$refs.addModal.showModal();
    },
    update(data) {
      if (data.total_quantity < data.min_order_quantity) {
        this.$notify({
          type: "error",
          text: "Qunatity must be at least " + data.min_order_quantity,
        });
        return false;
      }

      if (!data.gst_percentage) {
        this.$notify({
          type: "error",
          text: "Please select gst percentage",
        });
        return false;
      }

      this.$api
        .putAPI({
          _action: "seller/product/" + data.id,
          _body: {
            price: data.price,
            total_quantity: data.total_quantity,
            gst_percentage: data.gst_percentage,
            ex_mill_price: data.ex_mill_price,
          },
          _buttonId: "update-button",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.getList(this.searchObj.offset);
        })
        .catch(() => {});
    },
    getCompanyDetail() {
      this.$api
        .getAPI({
          _action: "company/" + localStorage.getItem("company_uuid"),
        })
        .then((res) => {
          this.detail = res.info;
          this.getDistrictList();
        })
        .catch(() => {});
    },
    clearModal() {
      this.searchObj = {
        company_name: "",
        state_id: "",
        district_id: "",
        yarn_id: "",
        yarn_sub_id: "",
        yarn_value_id: "",
      };

      this.$refs.addModal.closeModal();
      this.getList(0);
    },
    getList(page) {
      setTimeout(() => {
        this.loader = true;
        if (this.$refs.errorComponent) {
          this.$refs.errorComponent.updateLoader(true);
        }

        this.list = [];
        this.searchObj.offset = page;
        var url = "";
        if (this.user_type == "BUYER") {
          url = "buyer/product/list";
        } else if (this.user_type == "SELLER") {
          url = "seller/product/list";
        }

        this.$api
          .getAPI({
            _action: url,
            _body: this.searchObj,
          })
          .then((res) => {
            this.loader = false;
            for (var j in res.list) {
              if (!res.list[j].gst_percentage) {
                res.list[j].gst_percentage = "";
              }
              this.list.push(res.list[j]);
            }
            this.offset = res.next_offset;
            if (page == 0) {
              if (this.$refs.listPagination) {
                if (res.count != null && res.count > 0) {
                  this.$refs.listPagination.setTotalCount(res.count);
                } else {
                  this.$refs.listPagination.setTotalCount(0);
                }
              }
            }
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setData(res, "No Product Found");
              this.$refs.errorComponent.updateLoader(false);
            }
          })
          .catch(() => {
            this.loader = false;
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setData(0, undefined, "");
              this.$refs.errorComponent.updateLoader(false);
            }
          });
      }, 500);
    },
    getYarnList() {
      setTimeout(() => {
        if (this.searchObj.yarn_id) {
          this.searchObj.sub_yarn_id = "";
          this.searchObj.yarn_value_id = "";
        } else {
          this.searchObj.sub_yarn_id = "";
          this.searchObj.yarn_value_id = "";
        }

        if (!this.searchObj.sub_yarn_id) {
          this.searchObj.yarn_value_id = "";
        }
        this.$api
          .getAPI({
            _action: "yarns",
            _body: { parent_id: this.searchObj.yarn_id },
          })
          .then((res) => {
            if (this.searchObj.yarn_id) {
              this.yarnSubList = res.list;
            } else {
              this.yarnList = res.list;
            }
          })
          .catch(() => {});
      }, 500);
    },
    getStateList() {
      this.$api
        .getAPI({
          _action: "states",
        })
        .then((res) => {
          this.stateList = res.list;
        })
        .catch(() => {});
    },
    getDistrictList() {
      this.$api
        .getAPI({
          _action: "districts/" + this.detail.billing_address.state_id,
        })
        .then((res) => {
          this.districtList = res.list;
        })
        .catch(() => {});
    },
    checkGst() {
      if (this.detail.gst_number.length > 1) {
        var state = this.detail.gst_number.slice(0, 2);
        for (var i = 0; i < this.stateList.length; i++) {
          if (state.toString() === this.stateList[i].gst_code.toString()) {
            this.detail.billing_address.state_id = this.stateList[i].id;
            this.getDistrictList();
          }
        }
      }
    },
    signup() {
      this.$api
        .putAPI({
          _action: "company/" + localStorage.getItem("company_uuid"),
          _body: this.detail,
          _buttonId: "signup-button",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.getCompanyDetail();
        })
        .catch(() => {});
    },
  },
};
</script>

