<template>
  <div class="page-content">
    <div class="row mb-4 justify-content-center">
      <div class="col-lg-6">
        <div class="row mb-md-4 mb-2 align-items-center">
          <div class="col-md-6 text-md-left text-center">
            <h2 class="page-title mb-md-0"><a class="cursor-pointer" v-on:click="this.$router.go(-1)">
                <img src="/images/back-arrow.svg" width="17" class="mr-2 align-top" alt="" /></a> Profile</h2>
          </div>
          <div class="col-md-6 text-right d-flex justify-content-end"></div>
        </div>
        <div class="card mb-4 rounded-l border-0 shadow-sm">
          <div class="card-body">
            <form class="form-style floating-form">
              <!-- <div class="row justify-content-center">
                <div class="position-relative mb-2 text-center">
                  <div class="">
                    <img :src="detail.profile_img
                      ? detail.profile_img
                      : '/images/profile.svg'
                      " class="
                        img-fluid
                        rounded-circle
                        border border-light
                        shadow-sm
                      " style="border-width: 3px !important; object-fit: cover" alt="image thumb" id="img_hoder_1"
                      width="130" height="130" />
                    <div></div>
                  </div>
                  <button type="button" class="
                      btn
                      px-3
                      upload-img-merge-btn
                      btn-secondary
                      w-75
                      text-capitalize
                    " style="
                      position: absolute;
                      top: 60px;
                      right: -10px;
                      padding: 4px 7px !important;
                    ">
                    <i class="fa fa-pencil" aria-hidden="true"></i>
                    <input type="file" class="btn upload-img" id="AddPhoto" @change="onChange($event)" />
                  </button>
                </div>
              </div> -->
              <div class="text-center">
                <h5 class="mb-1">
                  {{ detail.first_name }} {{ detail.last_name }}
                </h5>
                <p class="mb-0" v-if="user_type == 'BUYER' ||
                  user_type == 'SELLER' ||
                  (user_type == 'BUYER' && broker_view == 'BUYER')
                  ">
                  {{ company_name }}
                </p>
              </div>
              <div class="row justify-content-center">
                <div class="col-md-12 mt-3">
                  <div class="card mb-3">
                    <div class="card-body border-bottom mb-3">
                      <label for="" class="mb-0">Email</label>
                      <p class="text-secondary font-14 mb-0">
                        {{ detail.email }}
                      </p>
                    </div>
                    <div class="card-body border-bottom mb-3">
                      <label for="" class="mb-0">Mobile Number </label>
                      <p class="text-secondary font-14 mb-0">
                        {{ detail.mobile_number }}
                      </p>
                    </div>
                    <div class="card-body" v-if="this.user_type == 'BUYER' || this.user_type == 'SELLER'
                      ">
                      <label for="" class="mb-0">GST Number </label>
                      <p class="text-secondary font-14 mb-0">
                        {{ gst_number }}
                      </p>
                    </div>
                  </div>

                  <div class="card mb-3" v-if="this.user_type == 'BUYER' || this.user_type == 'SELLER'
                    ">
                    <div class="card-body border-bottom mb-3">
                      <label for="" class="mb-0">Billing Address </label><a v-if="billing_address.id &&
                        (designation == 'PRIMARY_OWNER' ||
                          designation == 'SECONDERY_OWNER')
                        " href="#" class="float-right" v-on:click="
    $router.push('/edit-address/' + billing_address.id)
    "><img src="images/edit.svg" width="20" alt="" /></a><a v-if="!billing_address.id &&
    (designation == 'PRIMARY_OWNER' ||
      designation == 'SECONDERY_OWNER')
    " href="#" class="float-right" v-on:click="$router.push('/add-address')"><img src="images/edit.svg" width="20"
                          alt="" /></a>
                      <p class="text-secondary font-14 mb-0">
                        {{ billing_address.address }}
                      </p>
                    </div>
                    <div class="card-body border-bottom mb-3">
                      <label for="" class="mb-0">City </label>
                      <p class="text-secondary font-14 mb-0">
                        {{ billing_address.district.name }}
                      </p>
                    </div>
                    <!-- <div class="card-body border-bottom">
                      <label for="" class="mb-0">State </label>
                      <p class="text-secondary font-14 mb-0">
                        {{ billing_address.state.name }}
                      </p>
                    </div> -->
                  </div>

                  <div class="card mb-3" v-if="this.user_type == 'BUYER'">
                    <div class="card-body border-bottom mb-3">
                      <label for="" class="mb-0">Delivery Address </label><a v-if="delivery_address.id &&
                        (designation == 'PRIMARY_OWNER' ||
                          designation == 'SECONDERY_OWNER')
                        " href="#" class="float-right" v-on:click="
    $router.push('/edit-address/' + delivery_address.id)
    "><img src="images/edit.svg" width="20" alt="" /></a><a v-if="!delivery_address.id &&
    (designation == 'PRIMARY_OWNER' ||
      designation == 'SECONDERY_OWNER')
    " href="#" class="float-right" v-on:click="$router.push('/add-address')"><img src="images/edit.svg" width="20"
                          alt="" /></a>
                      <p class="text-secondary font-14 mb-0">
                        {{ delivery_address.address }}
                      </p>
                    </div>
                    <div class="card-body border-bottom mb-3">
                      <label for="" class="mb-0">City </label>
                      <p class="text-secondary font-14 mb-0">
                        {{ delivery_address.district.name }}
                      </p>
                    </div>
                    <!-- <div class="card-body border-bottom">
                      <label for="" class="mb-0">State </label>
                      <p class="text-secondary font-14 mb-0">
                        {{ delivery_address.state.name }}
                      </p>
                    </div> -->
                  </div>

                  <!-- <div
                    class="card mb-3"
                    v-if="
                      this.user_type == 'BUYER' || this.user_type == 'SELLER'
                    "
                  >
                    <div class="card-body border-bottom">
                      <label for="" class="mb-0">Bank Name </label
                      ><a
                        href=""
                        class="float-right"
                        v-on:click="$router.push('/save-bank')"
                        v-if="
                          designation == 'PRIMARY_OWNER' ||
                          designation == 'SECONDERY_OWNER'
                        "
                        ><img src="images/edit.svg" width="15" alt=""
                      /></a>
                      <p class="text-secondary font-14 mb-0">
                        {{ detail.user_bank.bank_name }}
                      </p>
                    </div>
                    <div class="card-body border-bottom">
                      <label for="" class="mb-0">Account Number </label>
                      <p class="text-secondary font-14 mb-0">
                        {{ detail.user_bank.account_number }}
                      </p>
                    </div>
                    <div class="card-body">
                      <label for="" class="mb-0">IFSC</label>
                      <p class="text-secondary font-14 mb-0">
                        {{ detail.user_bank.ifsc_code }}
                      </p>
                    </div>
                  </div> -->

                  <div class="col-lg-12 col-sm-12 mt-2 d-flex justify-content-end">
                    <button type="button" id="forgot-button" class="btn btn-brand-1 mb-3"
                      v-on:click="showChangePasswordPopup()">
                      Change Password
                    </button>
                  </div>

                  <div class="text-center mt-4" v-if="designation == 'PRIMARY_OWNER'">
                    <button type="button" class="btn btn-danger px-4" @click="delAcc()">
                      <i class="fa fa-trash"></i> Delete Account
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <DeleteModal @remove="deleteAccount()" ref="deleteModal" />
  <TextModal ref="textModal">
    <div class="modal-header pt-3">
      <h5 class="modal-title">Change Password</h5>
      <button type="button" class="close" v-on:click="$refs.textModal.closeModal">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <form class="form-style floating-form">
      <Form @submit="changePassword">
        <div class="col-lg-12 col-sm-12">
          <div class="floating-form-group mb-4">
            <Field :type="oldPasswordFieldType" id="current_password" name="current_password" v-model="current_password"
              v-on:keydown.space="$event.preventDefault()" rules="required:old password" :validateOnInput="true" />
            <label for="current_password">Old Password</label><span class="text-danger">*</span>
            <a class="psw-icon" v-on:click="switchVisibilityOld"><img :src="imageNew" width="20" /></a>
          </div>
          <ErrorMessage name="current_password" class="validation-msg mt-1" />
        </div>
        <div class="col-lg-12 col-sm-12">
          <div class="floating-form-group mb-4">
            <Field :type="newPasswordFieldType" id="password" name="password" v-model="password"
              v-on:keydown.space="$event.preventDefault()" rules="required:new password|minLength:6|password"
              :validateOnInput="true" />

            <label for="password">New Password</label><span class="text-danger">*</span>
            <a class="psw-icon" v-on:click="switchVisibilityNew"><img :src="imageNew" width="20" /></a>
          </div>
          <ErrorMessage name="password" class="validation-msg mt-1" />
        </div>
        <div class="col-lg-12 col-sm-12">
          <div class="floating-form-group mb-4">
            <Field :type="passwordFieldType" id="conf_password" name="confirm_password"
              v-on:keydown.space="$event.preventDefault()" rules="required:confirm password|confirmed:@password"
              :validateOnInput="true" />
            <label for="conf_password">Confirm Password</label>
            <span class="text-danger">*</span>
            <a class="psw-icon" v-on:click="switchVisibility"><img :src="image" width="20" /></a>
          </div>
          <ErrorMessage name="confirm_password" class="validation-msg mt-1" />
        </div>
        <div class="modal-footer pb-0">
          <button class="btn btn-brand-1 btn-block mb-3" id="change-password-button"
            style="width: 20%; border-radius: 5px">
            Save
          </button>
          <button type="button" class="cancel-btn btn mb-3" id="cancel-button" v-on:click="$refs.textModal.closeModal"
            style="width: 20%; border-radius: 5px">
            Cancel
          </button>
        </div>
      </Form>
    </form>
  </TextModal>
</template>

<script>
import DeleteModal from "@/components/DeleteModal";
import TextModal from "@/components/TextModal";
import { Form, Field, ErrorMessage } from "vee-validate";
import $ from "jquery";
export default {
  name: "MyProfile",
  components: { Form, Field, ErrorMessage, DeleteModal, TextModal },
  data() {
    return {
      user_type: localStorage.getItem("user_type"),
      designation: localStorage.getItem("designation"),
      broker_view: localStorage.getItem("broker_view"),
      detail: { user_bank: {} },
      company_name: "",
      gst_number: "",
      billing_address: { district: {}, state: {} },
      delivery_address: { district: {}, state: {} },
      password: "",
      confirm_password: "",
      oldPasswordFieldType: "password",
      newPasswordFieldType: "password",
      passwordFieldType: "password",
      imageOld: "/images/show.png",
      image: "/images/show.png",
      imageNew: "/images/show.png",
    };
  },
  mounted() {
    this.user_type = localStorage.getItem("user_type");
    this.$store.commit("showSidebar", true);
    this.getDetail();
    $(
      ".floating-form-group input,.floating-form-group textarea,.floating-form select"
    ).change(function () {
      if ($(this).val() != "") {
        $(this).addClass("filled");
      } else {
        $(this).removeClass("filled");
      }
    });
  },
  methods: {
    showChangePasswordPopup() {
      this.$refs.textModal.showModal();
    },
    changePassword() {
      var obj = {};
      obj.current_password = this.current_password;
      obj.new_password = this.password;
      this.$api
        .postAPI({
          _action: "/change/password",
          _body: obj,
          _buttonId: "change-password-button",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$refs.textModal.closeModal();
        })
        .catch(() => { });
    },
    delAcc() {
      this.$refs.deleteModal.showModal(
        "Delete Account",
        "Are you sure you want to delete account",
        0
      );
    },
    deleteAccount() {
      this.$api
        .deleteAPI({
          _action: "delete-account",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$sharedService.clearMap();
          localStorage.clear();
          this.$router.push("/select-type");
        })
        .catch(() => { });
    },
    getDetail() {
      this.$api
        .getAPI({
          _action: "me",
        })
        .then((res) => {
          this.detail = res.info;
          this.$store.commit("setUser", res.info);
          this.$helperService.checkAddress(res.info);
          if (this.user_type == "BUYER" || this.user_type == "SELLER") {
            this.company_name =
              this.detail.user_companies[0].company.company_name;
            this.gst_number = this.detail.user_companies[0].company.gst_number;
            this.delivery_address =
              this.detail.user_companies[0].company.delivery_address;
            this.billing_address =
              this.detail.user_companies[0].company.billing_address;
          }
        })
        .catch(() => { });
    },

    onChange(e) {
      let files = e.target.files;
      this.file = files[0];
      if (e.target.files && e.target.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
          $("#img_hoder_1").attr("src", e.target.result);
        };
      }
      reader.readAsDataURL(e.target.files[0]);
      this.uploadFile();
    },
    uploadFile() {
      this.$api
        .uploadImageAPI({
          _action: "set/avatar",
          _key: "image",
          _file: this.file,
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
        })
        .catch((e) => {
          this.$notify({
              type: "error",
              text: e.message,
            });
        });
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.image =
        this.image === "/images/show.png"
          ? "/images/hide.png"
          : "/images/show.png";
    },
    switchVisibilityNew() {
      this.newPasswordFieldType =
        this.newPasswordFieldType === "password" ? "text" : "password";
      this.imageNew =
        this.imageNew === "/images/show.png"
          ? "/images/hide.png"
          : "/images/show.png";
    },
    switchVisibilityOld() {
      this.oldPasswordFieldType =
        this.oldPasswordFieldType === "password" ? "text" : "password";
      this.imageOld =
        this.imageOld === "/images/show.png"
          ? "/images/hide.png"
          : "/images/show.png";
    },
  },
};
</script>

