<template>
  <div class="page-content">
    <div class="row mb-4 justify-content-center">
      <div class="col-lg-6">
        <div class="row mb-md-4 mb-2 align-items-center">
          <div class="col-md-6 text-md-left text-center">
            <h2 class="page-title mb-md-0">
              <a class="cursor-pointer" v-on:click="this.$router.go(-1)">
                <img src="/images/back-arrow.svg" width="17" class="mr-2 align-top" alt="" /></a>Payment Detail
            </h2>
          </div>
          <div class="col-md-6 text-right d-flex justify-content-end"></div>
        </div>
        <div class="card rounded-l border-0 shadow">
          <div class="card-body border-bottom ">
            <div class="row justify-content-center">
              <div class="col-md-12">
                <div class="card mb-3">
                  <div class="">
                    <div class="row mb-2">
                      <div class="col-md-6">
                        <p class="mb-0 text-secondary">UTR Number</p>
                      </div>
                      <div class="col-md-6">
                        <p class="mb-0 font-medium">
                          {{ detail.utr_number }}
                        </p>
                      </div>
                    </div>

                    <div class="row mb-2">
                      <div class="col-md-6">
                        <p class="mb-0 text-secondary">Amount</p>
                      </div>
                      <div class="col-md-6">
                        <p class="mb-0 font-medium">
                          ₹ {{ detail.paid_amount }}
                        </p>
                      </div>
                    </div>

                    <div class="row mb-2">
                      <div class="col-md-6">
                        <p class="mb-0 text-secondary">Payment Date</p>
                      </div>
                      <div class="col-md-6">
                        <p class="mb-0 font-medium">
                          {{
                            $helperService.getFormatedDate(detail.payment_date)
                          }}
                        </p>
                      </div>
                    </div>

                    <div class="row mb-2">
                      <div class="col-md-6">
                        <p class="mb-0 text-secondary">Payment Status</p>
                      </div>
                      <div class="col-md-6">
                        <p class="mb-0 font-medium">
                          {{ $helperService.getTitleCase(detail.status) }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  name: "PaymentDetail",
  components: {},
  data() {
    return {
      user_type: localStorage.getItem("user_type"),
      detail: {
        order_no: "",
        booking: {
          trade: {},
        },
        status: "",
      },
      orderId: this.$route.params.orderId,
      bookingId: this.$route.params.bookingId,
    };
  },
  mounted() {
    this.user_type = localStorage.getItem("user_type");
    this.getDetail();
  },
  methods: {
    getDetail() {
      var url = "";
      if (this.user_type == 'BUYER') {
        url = "/buyer/booking/" + this.bookingId + "/order/" + this.orderId;
      }
      if (this.user_type == 'SELLER') {
        url = "/seller/booking/" + this.bookingId + "/order/" + this.orderId;
      }
      this.$api
        .getAPI({
          _action:
            url,
        })
        .then((res) => {
          this.detail = res.info;
        })
        .catch(() => { });
    },
  },
};
</script>

