<template>
  <aside class="left-sidebar" id="mob_sidebar">
    <div class="scroll-sidebar">
      <div class="logo py-lg-3 py-3 d-lg-flex d-none justify-content-center">
        <img
          src="/images/yarnmart-Logo.svg"
          width="250"
          height="40"
          alt="Logo"
          class="img-fluid mb-1 left-menu-logo"
        />
        <a href="javascript:void(0)" class="d-md-none d-block" id="menu-toggle"
          ><img src="/images/hamburger-menu.svg" width="30" class="hamburger-menu"
        /></a>
      </div>
      <nav class="sidebar-nav">
        <ul class="nav-bar pl-1" id="leftNav">
          <li class="nav-item">
            <a
              class="nav-link"
              id="home"
              :class="{ active: pageName.includes('home') }"
              style="cursor: pointer"
              v-on:click="$router.push('/home')"
              ><img src="/images/house.svg" width="20" class="side-nav-icon" /><span
                class="hide-menu"
                >Home</span
              ></a
            >
          </li>

          <li class="nav-item">
            <a
              class="nav-link"
              id="booking"
              :class="{ active: pageName.includes('booking') }"
              style="cursor: pointer"
              v-on:click="$router.push('/booking')"
              ><img src="/images/booking.svg" class="side-nav-icon" width="20" /><span
                class="hide-menu"
                >Booking
              </span>
              </a
            >
          </li>

          <li class="nav-item">
            <a
              class="nav-link"
              id="order"
              :class="{ active: pageName.includes('order') }"
              style="cursor: pointer"
              v-on:click="$router.push('/order')"
              ><img src="/images/order.svg" class="side-nav-icon" width="20" /><span
                class="hide-menu"
                >Order</span
              ></a
            >
          </li>

          <li class="nav-item">
            <a
              class="nav-link"
              id="payment"
              :class="{ active: pageName.includes('payment') }"
              style="cursor: pointer"
              v-on:click="$router.push('/payment')"
              ><img src="/images/payment.svg" class="side-nav-icon" width="20" /><span
                class="hide-menu"
                >Payment</span
              ></a
            >
          </li>

          <li class="nav-item">
            <a
              class="nav-link"
              id="comission"
              :class="{ active: pageName.includes('comission') }"
              v-on:click="$router.push('/comission')"
              style="cursor: pointer"
              ><img src="/images/order.svg" width="20" class="side-nav-icon" /><span
                class="hide-menu"
                >Comission</span
              ></a
            >
          </li>

          <li
            class="nav-item"
            v-if="meCall && meCall.user_type == 'BROKER' && user_type == 'SELLER'"
          >
            <a
              class="nav-link"
              id="lead"
              :class="{ active: pageName.includes('lead') }"
              v-on:click="$router.push('/lead')"
              style="cursor: pointer"
              ><img
                src="/images/manage-company.svg"
                width="20"
                class="side-nav-icon"
              /><span class="hide-menu">Lead</span></a
            >
          </li>

          <li class="nav-item" v-if="user_type == 'BUYER'">
            <a
              class="nav-link"
              id="preferred-supplier"
              :class="{ active: pageName.includes('preferred-supplier') }"
              v-on:click="$router.push('/preferred-supplier')"
              style="cursor: pointer"
              ><img src="/images/users.svg" width="20" class="side-nav-icon" /><span
                class="hide-menu"
                >Preferred Supplier</span
              ></a
            >
          </li>

          <!-- <li class="nav-item" v-if="user_type != 'BROKER'">
            <a
              class="nav-link"
              id="employee"
              :class="{ active: pageName.includes('employee') }"
              v-on:click="$router.push('/employee')"
              style="cursor: pointer"
              ><img src="/images/users.svg" width="20" class="side-nav-icon" /><span
                class="hide-menu"
                >Add User</span
              ></a
            >
          </li> -->

          <li class="nav-item" v-if="user_type == 'BUYER'">
            <a
              class="nav-link"
              id="address"
              :class="{ active: pageName.includes('address') }"
              v-on:click="$router.push('/address')"
              style="cursor: pointer"
              ><img src="/images/users.svg" width="20" class="side-nav-icon" /><span
                class="hide-menu"
                >Add Address</span
              ></a
            >
          </li>

          <li
            class="nav-item"
            v-if="user_type != 'BROKER' && this.detail.parent_id === null"
          >
            <a
              class="nav-link"
              id="ManageCompany"
              :class="{ active: pageName.includes('manage-company') }"
              v-on:click="$router.push('/manage-company')"
              style="cursor: pointer"
              ><img
                src="/images/manage-company.svg"
                width="20"
                class="side-nav-icon"
              /><span class="hide-menu">Manage Company </span></a
            >
          </li>

          <li class="nav-item">
            <a
              class="nav-link"
              id="terms_condition"
              :class="{ active: pageName.includes('terms_condition') }"
              v-on:click="$router.push('/terms_condition')"
              style="cursor: pointer"
              ><img src="/images/terms.svg" width="20" class="side-nav-icon" /><span
                class="hide-menu"
                >Terms & Condition</span
              ></a
            >
          </li>

          <li class="nav-item">
            <a
              class="nav-link"
              id="help_support"
              :class="{ active: pageName.includes('help_support') }"
              v-on:click="$router.push('/help_support')"
              style="cursor: pointer"
              ><img src="/images/support.svg" width="20" class="side-nav-icon" /><span
                class="hide-menu"
                >Help & Support</span
              ></a
            >
          </li>

          <li class="nav-item">
            <a
              class="nav-link"
              id="about_us"
              :class="{ active: pageName.includes('about_us') }"
              v-on:click="$router.push('/about_us')"
              style="cursor: pointer"
              ><img src="/images/about-us.svg" width="20" class="side-nav-icon" /><span
                class="hide-menu"
                >About Us</span
              ></a
            >
          </li>

          <li class="nav-item">
            <a
              class="nav-link"
              id="logout"
              style="cursor: pointer"
              @click="showDeletePopup()"
              ><img src="/images/logouts.svg" width="20" class="side-nav-icon" /><span
                class="hide-menu"
                >Log Out</span
              ></a
            >
          </li>
        </ul>
      </nav>
    </div>
  </aside>
  <DeleteModal @remove="logout" ref="deleteModal" />
</template>
<script>
import DeleteModal from "@/components/DeleteModal";
export default {
  name: "MyMenu",
  components: { DeleteModal },
  data() {
    return {
      pageName: "",
      detail: {},
      meCall: {},
      broker_view: localStorage.getItem("broker_view"),
      user_type: localStorage.getItem("user_type"),
      designation: localStorage.getItem("designation"),
      booking_count: 0,
      order_count: 0,
      payment_count: 0,
    };
  },
  computed: {},
  watch: {
    "$route.fullPath"(newValue) {
      this.pageName = newValue.replace("/", "");
    },
  },
  mounted() {
    this.pageName = this.$route.fullPath.replace("/", "");
    var swListener = new BroadcastChannel("swListener");
    var $this = this;
    swListener.onmessage = function (e) {
      console.log("swListener Received", e.data);
      if (localStorage.getItem("access_token")) {
        if (e.data == "UNSUBSCRIBED") {
          $this.unregister();
        }

        if (e.data == "SUBSCRIBED") {
          $this.register();
        }
      }
    };
    this.getMyProfile();
  },
  methods: {
    showDeletePopup() {
      this.$refs.deleteModal.showModal("Logout", "Are you sure you want to logout", 0);
    },
    unregister() {
      let subscription = window.localStorage.getItem("subscription");
      console.log("subscription " + subscription);
      if (subscription) {
        this.$api
          .postAPI({
            _action: "unregister-device",
            _body: {
              token: subscription,
              token_type: "WEB",
            },
          })
          .then(() => {})
          .catch((e) => {
            this.$notify({
              type: "error",
              text: e.message,
            });
          });
      }
    },
    register() {
      let subscription = window.localStorage.getItem("subscription");
      console.log("subscription " + subscription);
      if (subscription) {
        this.$api
          .postAPI({
            _action: "register-device",
            _body: {
              token: subscription,
              token_type: "WEB",
            },
          })
          .then(() => {})
          .catch((e) => {
            this.$notify({
              type: "error",
              text: e.message,
            });
          });
      }
    },
    logout() {
      let subscription = window.localStorage.getItem("subscription");
      console.log("subscription " + subscription);
      if (subscription) {
        this.$api
          .postAPI({
            _action: "unregister-device",
            _body: {
              token: subscription,
              token_type: "WEB",
            },
          })
          .then(() => {
            this.$sharedService.clearMap();
            localStorage.clear();
            this.$router.push("/select-type");
          })
          .catch((e) => {
            this.$notify({
              type: "error",
              text: e.message,
            });
          });
      } else {
        this.$sharedService.clearMap();
        localStorage.clear();
        this.$router.push("/select-type");
      }
    },

    getMyProfile() {
      this.$api
        .getAPI({
          _action: "me",
        })
        .then((res) => {
          this.$store.commit("setUser", res.info);
          this.meCall = res.info;
          this.detail = res.info;
        })
        .catch(() => {});
    },

    getCount() {
      this.user_type = localStorage.getItem("user_type");
      var url = "";
      if (this.user_type == "BUYER") {
        url = "buyer/count";
      }
      if (this.user_type == "SELLER") {
        url = "seller/count";
      }
      this.$api
        .getAPI({
          _action: url,
        })
        .then((res) => {
          this.booking_count = res.booking_count;
          this.order_count = res.order_count;
          this.payment_count = res.payment_count;
        })
        .catch(() => {});
    },
  },
};
</script>

