<template>
  <div>
    <div class="page-content">
      <div class="row mb-4 justify-content-center">
        <div class="col-lg-12">
          <div class="row mb-md-4 mb-2 align-items-center">
            <div class="col-md-6 text-md-left text-center">
              <h2 class="page-title mb-md-0">
                <a class="cursor-pointer" v-on:click="this.$router.go(-1)">
                  <img src="/images/back-arrow.svg" width="17" class="mr-2 align-top" alt="" /></a><span v-if="uuid">Edit
                  User</span><span v-else>Add User</span>
              </h2>
            </div>
            <div class="col-md-6 text-right d-flex justify-content-end"></div>
          </div>
          <div class="card mb-4 rounded-l border-0 shadow-sm">
            <div class="card-body">
              <div class="row justify-content-center">
                <div class="col-lg-12">
                  <Form @submit="signup" class="form-style floating-form">
                    <div class="row">
                      <!-- <div class="col-lg-6" v-if="user_type == 'BUYER'">
                        <label>Select Role <span class="text-danger">*</span></label>
                        <div class="form-group">
                          <Field as="select" class="custom-select" v-model="detail.role" name="role"
                            rules="required:role,true" @change="selectRole()">
                            <option value="">Select Role</option>
                            <option value="BROKER">Broker</option>
                            <option value="USER">User</option>
                          </Field>
                        </div>
                        <ErrorMessage name="role" class="validation-msg mb-1" />
                      </div> -->
                      <template v-if="detail.role == 'BROKER'">
                        <div class="form-group col-lg-6">
                          <label>Select Broker</label>
                          <span style="color: red">*</span>
                          <div class="input-group autocomplete">
                            <input type="text" id="broker" style="
                                width: 100% !important;
                                border-left: 1px solid rgb(221, 221, 221) !important;
                              " class="form-control border-left-0" placeholder="Search Broker" name="broker"
                              v-model="detail.broker_name" autocomplete="off" @input="onChangeBroker" />

                            <ul v-show="isOpen" class="autocomplete-results">
                              <li v-for="(result, i) in brokerList" :key="i" class="autocomplete-result"
                                v-on:click="selectBroker(result)">
                                {{ result.name }}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </template>
                      <template v-if="detail.role == 'USER'">
                        <div class="col-lg-12 mb-2">
                          <!-- <label
                            >Select Designation
                            <span class="text-danger">*</span></label
                          > -->
                          <div class="form-group">
                            <Field as="select" class="custom-select" v-model="detail.designation" name="designation"
                              rules="required:designation,true" @update:model-value="selectRole()">
                              <option value="">Select Designation</option>
                              <option value="MANAGER">Manager</option>
                              <option value="SECONDERY_OWNER">Secondary Owner</option>
                              <option value="VIEW">View</option>
                            </Field>
                          </div>
                          <ErrorMessage name="designation" class="validation-msg mb-1" />
                        </div>

                        <div class="col-lg-12 mb-2">
                          <div class="floating-form-group">
                            <Multiselect id="company" mode="tags" trackBy="company_name" label="company_name"
                              valueProp="id" v-model="company_ids" placeholder="Select Company " :options="companyList" />
                          </div>
                          <ErrorMessage name="company" class="validation-msg mb-1" />
                        </div>
                        <div class="col-lg-6 mb-2">
                          <div class="floating-form-group">
                            <Field type="text" id="phone" name="phone" :class="detail.mobile_number ? 'filled' : ''"
                              v-model="detail.mobile_number" rules="required:mobile number|phone" :validateOnInput="true"
                              maxlength="10" />
                            <label for="phone">Mobile Number</label><span class="text-danger">*</span>
                          </div>
                          <ErrorMessage name="phone" class="validation-msg mb-1" />
                        </div>

                        <div class="col-lg-6 mb-2">
                          <div class="floating-form-group">
                            <Field type="email" id="email" name="email" v-model="detail.email"
                              :class="detail.email ? 'filled' : ''" rules="required:email" :validateOnInput="true" />
                            <label for="email">Email</label><span class="text-danger">*</span>
                          </div>
                          <ErrorMessage name="email" class="validation-msg mb-1" />
                        </div>

                        <div class="col-lg-6 mb-2">
                          <div class="floating-form floating-form-error">
                            <div class="floating-form-group">
                              <Field type="text" id="fname" name="firstname" :class="detail.first_name ? 'filled' : ''"
                                v-model="detail.first_name" rules="required:first name|userName"
                                :validateOnInput="true" />
                              <label for="fname">First Name</label><span class="text-danger">*</span>
                            </div>
                          </div>
                          <ErrorMessage name="firstname" class="validation-msg mb-1" />
                        </div>

                        <div class="col-lg-6 mb-2">
                          <div class="floating-form-group">
                            <Field type="text" :class="detail.last_name ? 'filled' : ''" id="lname" name="lastname"
                              v-model="detail.last_name" rules="required:last name|userName" :validateOnInput="true" />
                            <label for="lname">Last Name</label><span class="text-danger">*</span>
                          </div>
                          <ErrorMessage name="lastname" class="validation-msg mb-1" />
                        </div>

                        <div class="col-lg-6 mb-2">
                          <div class="floating-form-group mb-4">
                            <Field :type="newPasswordFieldType" id="password" name="password"
                              :class="detail.password ? 'filled' : ''" v-model="detail.password"
                              v-on:keydown.space="$event.preventDefault()" rules="required:password|minLength:6|password"
                              :validateOnInput="true" />

                            <label for="password">Password</label><span class="text-danger">*</span>
                            <a class="psw-icon" v-on:click="switchVisibilityNew"><img :src="imageNew" width="20" /></a>
                          </div>
                          <ErrorMessage name="password" class="validation-msg mb-1" />
                        </div>

                        <div class="col-lg-6 mb-2">
                          <div class="floating-form-group mb-4">
                            <Field :type="passwordFieldType" id="conf_password" name="confirm_password"
                              :class="detail.confirm_password ? 'filled' : ''"
                              v-on:keydown.space="$event.preventDefault()"
                              rules="required:confirm password|confirmed:@password" v-model="detail.confirm_password"
                              :validateOnInput="true" />
                            <label for="conf_password">Confirm Password</label>
                            <span class="text-danger">*</span>
                            <a class="psw-icon" v-on:click="switchVisibility"><img :src="image" width="20" /></a>
                          </div>
                          <ErrorMessage name="confirm_password" class="validation-msg mb-1" />
                        </div>
                      </template>

                      <div class="col-md-12 text-center mt-3" v-if="detail.role == 'BROKER'">
                        <button class="btn btn-brand-1 mb-3" id="signup-button">
                          Add Broker
                        </button>
                      </div>
                      <div class="col-md-12 mt-3 text-center" v-else>
                        <button class="btn btn-brand-1 mb-3" id="signup-button">
                          Save
                        </button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import Multiselect from "@vueform/multiselect";
import $ from "jquery";
import router from "../../router";
export default {
  name: "AddEmployee",
  components: { Form, Field, ErrorMessage, Multiselect },
  data() {
    return {
      newPasswordFieldType: "password",
      passwordFieldType: "password",

      detail: {
        broker_name: "",
        designation: "",
        role: "USER",
      },
      company_ids: [],
      uuid: this.$route.params.uuid,
      isOpen: false,
      image: "/images/hide.png",
      imageNew: "/images/hide.png",
      companyList: [],

      user_type: localStorage.getItem("user_type"),
      designation: localStorage.getItem("designation"),
    };
  },
  mounted() {
    this.getCompanyList();
    if (this.uuid) {
      if (this.user_type == "SELLER") {
        this.detail.role = "USER";
      }
      this.getDetail();
    } else {
      if (this.user_type == "SELLER") {
        this.detail.role = "USER";
      } else {
        this.selectRole();
      }
    }
    $(
      ".floating-form-group mb-0 input,.floating-form-group mb-0 textarea,.floating-form select"
    ).change(function () {
      if ($(this).val() != "") {
        $(this).addClass("filled");
      } else {
        $(this).removeClass("filled");
      }
    });
  },
  methods: {
    onChangeBroker() {
      this.getBrokerList();
      this.isOpen = true;
    },
    selectBroker(selected_broker) {
      this.detail.broker_name = selected_broker.name;
      this.detail.broker_id = selected_broker.uuid;
      this.isOpen = false;
    },
    selectRole() {
      if (this.detail.role == "BROKER") {
        this.getBrokerList();
      }
    },
    getDetail() {
      this.$api
        .getAPI({
          _action: "employee/" + this.uuid,
        })
        .then((res) => {
          this.detail = res.info;
        })
        .catch(() => { });
    },
    getBrokerList() {
      var keyword = "";
      if ($("#broker").val()) {
        keyword = $("#broker").val();
      }
      this.$api
        .getAPI({
          _action: "broker/search",
          _body: {
            keyword: keyword,
          },
        })
        .then((res) => {
          this.brokerList = res.list;
          if (this.brokerList.length > 0) {
            this.isOpen = true;
          }
        })
        .catch(() => { });
    },

    signup() {
      if (this.company_ids.length == 0) {
        this.$notify({
          type: "error",
          text: "Please select at-least one company",
        });
        return false;
      }
      this.detail.company_ids = this.company_ids;
      if (this.detail.role == "BROKER") {
        this.$api
          .postAPI({
            _action: "employee/link",
            _body: { profile_id: this.detail.broker_id, designation: "BROKER" },
            _buttonId: "signup-button",
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            router.push("/employee");
          })
          .catch(() => { });
      } else {
        this.$api
          .postAPI({
            _action: "employee",
            _body: this.detail,
            _buttonId: "signup-button",
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            router.push("/employee");
          })
          .catch(() => { });
      }
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.image =
        this.image === "/images/show.png" ? "/images/hide.png" : "/images/show.png";
    },
    switchVisibilityNew() {
      this.newPasswordFieldType =
        this.newPasswordFieldType === "password" ? "text" : "password";
      this.imageNew =
        this.imageNew === "/images/show.png" ? "/images/hide.png" : "/images/show.png";
    },
    getCompanyList() {
      this.$api
        .getAPI({
          _action: "user/companies",
        })
        .then((res) => {
          this.companyList = [];
          for (var i = 0; i < res.list.length; i++) {
            this.companyList.push(res.list[i].company);

          }
        })
        .catch(() => { });
    },
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect {
  background: #f0f0f0;
  height: 55px;
  border-radius: 8px;
  border: 0;
}

.multiselect-tag {
  background: #9cc338 !important;
}


.autocomplete {
  position: relative;
  width: 100%;
}

.autocomplete-results {
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  height: 120px;
  min-height: 1em;
  max-height: 6em;
  overflow: scroll;
  width: 100%;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 2px;
  cursor: pointer;
  z-index: 999;
}

.autocomplete-result:hover {
  background-color: #4aae9b;
  color: white;
}
</style>