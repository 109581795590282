<template>
  <div>
    <OuterHeader />
    <main role="main">
      <div id="main-wrapper" class="login-wrap">
        <div class="container-fluid">
          <div
            class="row justify-content-md-center bg-white"
            style="height: 100vh !important"
          >
            <div class="col-lg-12 bg-white py-4 mt-3">
              <div class="row justify-content-center">
                <div class="col-lg-8 mt-5">
                  <div class="card card-body shadow">
                    <div
                      class="card-header bg-white rounded-10 text-center border-0"
                    >
                      <h4 class="mb-0">Document Verification</h4>
                    </div>
                    <div class="pt-lg-3">
                      <form class="form-style floating-form">
                        <Form @submit="onPanVerifying">
                          <h5
                            style="
                              border-bottom: 1px solid #cccccc;
                              line-height: 2;
                            "
                          >
                            PAN Details
                          </h5>
                          <div class="row">
                            <div class="col-md-6 mb-3">
                              <div class="floating-form floating-form-error">
                                <div class="floating-form-group">
                                  <Field
                                    style="text-transform: uppercase"
                                    type="text"
                                    id="name_as_per_pan"
                                    name="name_as_per_pan"
                                    :class="
                                      panInfo.name_as_per_pan ? 'filled' : ''
                                    "
                                    v-model="panInfo.name_as_per_pan"
                                    rules="required:name as per pan"
                                    :validateOnInput="true"
                                  />
                                  <label for="name_as_per_pan"
                                    >Name
                                    <small class="text-danger"
                                      >*(name as per pan)</small
                                    ></label
                                  >
                                </div>
                              </div>
                              <ErrorMessage
                                name="name_as_per_pan"
                                class="validation-msg mt-1"
                              />
                            </div>
                            <div class="col-md-6 mb-3">
                              <div class="floating-form floating-form-error">
                                <div class="floating-form-group">
                                  <Field
                                    :maxlength="10"
                                    style="text-transform: uppercase"
                                    v-on:keyup="checkPan()"
                                    type="text"
                                    id="pan"
                                    name="pan"
                                    :class="panInfo.pan ? 'filled' : ''"
                                    v-model="panInfo.pan"
                                    rules="required:PAN|PAN"
                                    :validateOnInput="true"
                                  />
                                  <label for="pan">PAN No</label
                                  ><span class="text-danger">*</span>
                                </div>
                              </div>
                              <div
                                class="d-flex flex-row align-items-center"
                                v-if="has_pan_verifying == true"
                              >
                                <span
                                  class="spinner-grow spinner-grow-sm text-danger mr-2"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span class="validation-msg mt-1"
                                  >Verifying...</span
                                >
                              </div>
                              <ErrorMessage
                                name="pan"
                                class="validation-msg mt-1"
                              />
                            </div>

                            <div class="col-md-4">
                              <label for="dob" class="form-label"
                                  >Date of Incorporation (as per PAN)<span
                                    class="text-danger"
                                    >*</span
                                  ></label
                                >
                              <div class="form-group">
                                <Datepicker
                                  id="dob"
                                  :clearable="false"
                                  v-model="panInfo.date_of_birth"
                                  hideInputIcon
                                  format="dd-MM-yyyy"
                                  placeholder="Select Date Of Incorporation"
                                ></Datepicker>
                              </div>
                            </div>
                            <!-- <div class="col-md-4">
                              <div class="floating-form floating-form-error">
                                <div class="floating-form-group">
                                  <Field
                                    style="text-transform: uppercase"
                                    type="date"
                                    id="dob"
                                    name="dob"
                                    :class="
                                      panInfo.date_of_birth ? 'filled' : ''
                                    "
                                    v-model.lazy="panInfo.date_of_birth"
                                    :value="panInfo.date_of_birth"
                                    rules="required:date of birth as per PAN"
                                    :validateOnInput="true"
                                  />
                                  <label for="dob" class="form-label"
                                    >Date Of Birth (as per PAN)<span
                                      class="text-danger"
                                      >*</span
                                    ></label
                                  >
                                </div>
                              </div>
                              <ErrorMessage
                                name="dob"
                                class="validation-msg mt-1"
                              />
                            </div> -->
                            <div class="col-md-4 d-flex flex-column">
                              <label class=""
                                >Upload PAN Card
                                <span class="text-danger">*</span></label
                              >
                              <div
                                class="picture mb-3 align-self-start"
                                style="margin: 0px !important"
                              >
                                <img
                                  :src="panFile != null ? panFile : tempImage"
                                  :title="detail.file"
                                  class="picture-src pl-4"
                                  id="img_hoder_1"
                                  style="
                                    width: 162px !important;
                                    height: 100px !important;
                                  "
                                />
                                <input
                                  type="file"
                                  id="AddPan"
                                  accept="image/*"
                                  @change="onChange($event, 'img_hoder_1')"
                                />
                              </div>
                            </div>
                            <!-- <div class="col-md-6"></div> -->
                            <div
                              class="col-md-6 pt-2 justify-content-center align-items-center"
                              v-show="panInfo.has_pan_verified === 0"
                            >
                              <div class="">
                                <button
                                  class="btn btn-brand-1 mb-3"
                                  id="signup-button"
                                >
                                  {{ verify_pan }}
                                </button>
                              </div>
                            </div>
                            <div
                              class="col-md-6 pt-2"
                              v-show="panInfo.has_pan_verified === 1"
                            >
                              <div class="">
                                <button
                                  type="button"
                                  @click="reverifyPan()"
                                  class="btn btn-brand-1 mb-3"
                                  id="signup-button"
                                >
                                  <img
                                    src="/img/eligible.png"
                                    width="25"
                                    class="icon"
                                  />
                                  Re-verify
                                </button>
                              </div>
                            </div>
                          </div>
                        </Form>
                        <div class="" v-show="panInfo.has_pan_verified === 1">
                          <Form @submit="onAddhaarVerify">
                            <h5
                              class="pt-4"
                              style="
                                border-bottom: 1px solid #cccccc;
                                line-height: 2;
                              "
                            >
                              Aadhaar Details
                            </h5>
                            <div class="row">
                              <div class="col-md-6 mb-3">
                                <div class="floating-form-group">
                                  <Field
                                    :maxlength="12"
                                    v-on:keyup="checkAadhaar()"
                                    type="text"
                                    :class="
                                      aadhaar.aadhaar_number ? 'filled' : ''
                                    "
                                    id="aadhaar_no"
                                    name="aadhaar_no"
                                    v-model="aadhaar.aadhaar_number"
                                    rules="required:Aadhaar no|aadhaar"
                                    :validateOnInput="true"
                                  />
                                  <label for="aadhaar_no">Aadhaar No </label
                                  ><span class="text-danger">*</span>
                                </div>
                                <div
                                  class="d-flex flex-row align-items-center"
                                  v-if="has_aadhaar_verifying == true"
                                >
                                  <span
                                    class="spinner-grow spinner-grow-sm text-danger mr-2"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  <span class="validation-msg mt-1"
                                    >Verifying...</span
                                  >
                                </div>
                                <ErrorMessage
                                  name="aadhaar_no"
                                  class="validation-msg mt-1"
                                />
                              </div>
                              <div class="col-md-6 mb-3"></div>
                              <div class="col-md-8 d-flex flex-column">
                                <label
                                  >Upload Aadhaar Card
                                  <span class="text-danger">*</span></label
                                >
                                <div
                                  class="picture mb-3 align-self-start"
                                  style="margin: 0px !important"
                                >
                                  <img
                                    :src="aadhaarFile ? aadhaarFile : tempImage"
                                    :title="detail.file"
                                    class="picture-src"
                                    id="img_hoder_2"
                                    style="
                                      width: 162px !important;
                                      height: 100px !important;
                                    "
                                  />
                                  <input
                                    type="file"
                                    id="AddAadhaar"
                                    accept="image/*"
                                    @change="onChange($event, 'img_hoder_2')"
                                  />
                                </div>
                              </div>
                              <div
                                class="col-md-6 pt-2"
                                v-show="aadhaar.has_aadhaar_verified === 0"
                              >
                                <div class="">
                                  <button
                                    type="submit"
                                    class="btn btn-brand-1 mb-3"
                                    id="aadhaar-verify-button"
                                  >
                                    {{ verify_aadhaar }}
                                  </button>
                                </div>
                              </div>
                              <div
                                class="col-md-6 pt-2"
                                v-show="aadhaar.has_aadhaar_verified === 1"
                              >
                                <div class="">
                                  <button
                                    type="button"
                                    @click="reverifyAadhaar()"
                                    class="btn btn-brand-1 mb-3"
                                  >
                                    <img
                                      src="/img/eligible.png"
                                      width="25"
                                      class="icon"
                                    />
                                    Re-verify
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Form>
                        </div>
                        <h5
                          class="pt-2"
                          style="
                            border-bottom: 1px solid #cccccc;
                            line-height: 2;
                          "
                        ></h5>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TextModal ref="aadhaarOTPModal">
        <div class="modal-header pt-3">
          <h5 class="modal-title">Aadhaar OTP Verification</h5>
          <button type="button" class="close" v-on:click="closeOTPModal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form class="form-style floating-form">
          <div class="container-fluid h-100">
            <div class="row justify-content-center align-items-center">
              <div class="card">
                <div class="card-body">
                  <div class="text-center">
                    <img
                      width="100"
                      height="100"
                      class="img-fluid svg-img"
                      src="/images/forgot-password.svg"
                    />
                    <p class="font-medium font-18">OTP Verification</p>
                    <p class="text-secondary font-14">
                      Please enter verification code.
                    </p>

                    <div class="form-group otp-element mb-0 col-md-10 mx-auto">
                      <form class="d-flex justify-content-between">
                        <input
                          id="codeBox1"
                          type="text"
                          class="otp-control"
                          maxlength="1"
                          @blur="check()"
                          v-on:keyup="onKeyUpEvent(1, $event)"
                        />
                        <input
                          id="codeBox2"
                          type="text"
                          class="otp-control"
                          maxlength="1"
                          @blur="check()"
                          v-on:keyup="onKeyUpEvent(2, $event)"
                        />
                        <input
                          id="codeBox3"
                          type="text"
                          class="otp-control"
                          maxlength="1"
                          @blur="check()"
                          v-on:keyup="onKeyUpEvent(3, $event)"
                        />
                        <input
                          id="codeBox4"
                          type="text"
                          class="otp-control"
                          maxlength="1"
                          @blur="check()"
                          v-on:keyup="onKeyUpEvent(4, $event)"
                        />
                        <input
                          id="codeBox5"
                          type="text"
                          class="otp-control"
                          maxlength="1"
                          @blur="check()"
                          v-on:keyup="onKeyUpEvent(5, $event)"
                        />
                        <input
                          id="codeBox6"
                          type="text"
                          class="otp-control"
                          maxlength="1"
                          @blur="check()"
                          v-on:keyup="onKeyUpEvent(6, $event)"
                        />
                      </form>
                    </div>
                    <div class="text-center mt-2">
                      <p class="text-secondary">
                        We have sent verification code on your aadhaar
                        registered Mobile no.
                      </p>
                      <p class="text-danger" id="digit">Please enter OTP</p>
                    </div>
                  </div>
                  <div
                    class="card-footer text-center bg-white border-0 px-md-5"
                  >
                    <button
                      type="button"
                      class="btn btn-brand-1 btn-block"
                      id="verify-otp"
                      @click="verifyAadhaarOTP()"
                    >
                      Verify
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </TextModal>
    </main>
  </div>
</template>
<script>
import OuterHeader from "@/components/OuterHeader";
import { Form, Field, ErrorMessage } from "vee-validate";
import TextModal from "@/components/TextModal";
import moment from "moment";
import Datepicker from "@vuepic/vue-datepicker";
import ShareService from "../../services/ShareService";
import router from "../../router";
import $ from "jquery";
export default {
  name: "VerificationS",
  components: {
    Form,
    Field,
    ErrorMessage,
    Datepicker,
    OuterHeader,
    TextModal,
  },
  data() {
    return {
      id: this.$route.params.uuid,
      detail: {
        user_type: localStorage.getItem("user_type"),
        designation: localStorage.getItem("designation"),
        has_pan_verified: 0,
        has_aadhaar_verified: 0,
        aadhaar_number: "",
        date_of_birth: moment().format("DD-MM-YYYY"),
        pan: "",
      },

      panInfo: {
        has_pan_verified: 0,
        date_of_birth: "",
        pan: "",
        category:"",
        type: "PAN",
      },
      aadhaar: {
        aadhaar_number: "",
        has_aadhaar_verified: 0,
        type: "AADHAAR",
      },
      is_checked: "N",
      image: "/images/show.png",
      imageNew: "/images/show.png",
      tempImage: "/img/img-placeholder.jpg",
      is_importer: "N",
      has_gst_verifying: false,
      panFile: null,
      aadhaarFile: null,
      has_pan_verifying: false,
      has_aadhaar_verifying: false,
      docs: [],
      button_text: "Submit",
      verify_pan: "verify",
      verify_aadhaar: "verify",
      reference_id: null,
      otpDetail: {
        reference_id: null,
        otp: null,
      },
    };
  },
  mounted() {
    if (this.id) {
      this.getDocVerificationInfo();
    }
    if (
      localStorage.getItem("is_importer") &&
      localStorage.getItem("is_importer") == "Y"
    ) {
      this.detail.user_type = "IMPOTER";
    }
    $(
      ".floating-form-group input,.floating-form-group textarea,.floating-form select"
    ).change(function () {
      if ($(this).val() != "") {
        $(this).addClass("filled");
      } else {
        $(this).removeClass("filled");
      }
    });
  },
  methods: {
    showOTPModal() {
      this.$refs.aadhaarOTPModal.showModal();
    },
    closeOTPModal() {
      this.$refs.aadhaarOTPModal.closeModal();
      this.otpDetail.otp = null;
      this.otpDetail.otpDetail = null;
    },
    reverifyPan() {
      this.panInfo.has_pan_verified = 0;
    },
    reverifyAadhaar() {
      this.aadhaar.has_aadhaar_verified = 0;
    },
    getDocVerificationInfo() {
      this.$api
        .getAPI({
          _action: "check-user-doc-verification/" + this.id,
        })
        .then((res) => {
          if (res.info != null) {
            this.panInfo.pan = res?.info?.pan;
            this.panInfo.date_of_birth = new Date(res?.info?.date_of_birth);
            console.log(
              "the Value of this.panInfo.date_of_birth",
              this.panInfo.date_of_birth
            );
            this.panInfo.name_as_per_pan = res.info.name_as_per_pan;
            this.panInfo.has_pan_verified = res.info.has_pan_verified;
            this.panFile = res.info.pan_doc;

            this.aadhaar.aadhaar_number = res?.info?.aadhaar_number;
            this.aadhaar.has_aadhaar_verified = res.info.has_aadhaar_verified;
            this.aadhaarFile = res?.info?.aadhaar_doc;
          }
        })
        .catch(() => {});
    },
    checkPan() {
      var pan = this.panInfo.pan.toUpperCase();
      this.panInfo.pan = pan;
    },
    checkAadhaar() {},
    onPanVerifying() {
      if (this.panInfo.date_of_birth === "") {
        this.$notify({
          type: "error",
          text: "Please select date of birth as mentioned in pan card",
        });
        return false;
      }
      if (this.panFile == null) {
        this.$notify({
          type: "error",
          text: "Please upload pan card",
        });
        return false;
      }

      this.panInfo.date_of_birth = moment(this.panInfo.date_of_birth).format(
        "DD/MM/YYYY"
      );

      if (this.panInfo.pan.length === 10) {
        this.has_pan_verifying = true;
        this.validationOfDoc("check-verification", this.panInfo);
      }
      console.log(this.detail, ">>>>>>>");
    },
    onAddhaarVerify() {
      if (this.aadhaarFile == null) {
        this.$notify({
          type: "error",
          text: "Please upload aadhaar card",
        });
        return false;
      }
      this.has_aadhaar_verifying = true;
      // this.validationOfDoc("check-verification", "PAN", this.aadhaar);
      this.generateAadhaarOTP();
    },
    generateAadhaarOTP() {
      this.$api
        .postAPI({
          _action: "verify-aadhaar",
          _body: this.aadhaar,
          _buttonId: "aadhaar-verify-button",
        })
        .then((res) => {
          this.has_aadhaar_verifying = false;
          if (res?.status === true) {
            this.reference_id =
              res?.info?.data?.reference_id != null
                ? res?.info?.data?.reference_id
                : res?.info?.data?.ref_id;
            this.$notify({
              type: "success",
              text: res?.info?.data?.message,
            });
            this.showOTPModal();
          } else {
            this.$notify({
              type: "error",
              text: res?.message?.error?.message,
            });
          }
        })
        .catch(() => {
          this.has_aadhaar_verifying = false;
        });
    },
    verifyAadhaarOTP() {
      var otp =
        document.getElementById("codeBox1").value +
        document.getElementById("codeBox2").value +
        document.getElementById("codeBox3").value +
        document.getElementById("codeBox4").value +
        document.getElementById("codeBox5").value +
        document.getElementById("codeBox6").value;
      var obj = {
        otp: otp,
        reference_id: this.reference_id.toString(),
      };
      this.$api
        .postAPI({
          _action: "check-otp",
          _body: obj,
          _buttonId: "verify-otp",
        })
        .then((res) => {
          this.has_aadhaar_verifying = false;
          if (res?.info?.code === 200) {
            if (res?.info?.data?.message === "Invalid OTP") {
              this.$notify({
                type: "error",
                text: res?.info?.data?.message,
              });
              return false;
            }
            if (res?.info?.data?.status === "VALID") {
              this.$notify({
                type: "success",
                text: "Aadhaar number verified successfully",
              });
              this.closeOTPModal();
              this.aadhaar.has_aadhaar_verified = 1;
              this.save(this.aadhaar);
            }else{
              this.$notify({
                type: "error",
                text: res?.info?.data?.message,
              });
              this.aadhaar.message = res?.info?.data?.message;
            this.closeOTPModal();
            this.aadhaar.has_aadhaar_verified = 0;
            this.save(this.aadhaar);
            }
           
          } else {
            this.$notify({
              type: "error",
              text: res?.message?.error?.message,
            });
          }
        })
        .catch(() => {
          this.has_aadhaar_verifying = false;
        });
    },

    save(body) {
      console.log(body, "The value");
      if (this.id) {
        this.$api
          .postAPI({
            _action: "validate-document/" + this.id,
            _body: body,
            _buttonId: "signup-button",
          })
          .then((res) => {
            console.log(res);
            if (body?.type === "PAN") {
              if (typeof this.panFile === "object") {
                this.uploadImage(res?.id, this.panFile, body?.type);
              }
            }
            if (body?.type === "AADHAAR") {
              if (typeof this.aadhaarFile === "object") {
                this.uploadImage(res?.id, this.aadhaarFile, body?.type);
              }
            }
            if (
              this.panInfo.has_pan_verified === 1 &&
              this.aadhaar.has_aadhaar_verified === 1
            ) {
              router.push("/verify-otp");
            }
          })
          .catch(() => {});
      }
    },
    validationOfDoc(url, body) {
      var verify_for = body?.type;
      var date_of_birth = body?.date_of_birth;
      this.$api
        .postAPI({
          _action: url,
          _body: body,
        })
        .then((res) => {
          this.has_pan_verifying = false;
          console.log(res, ">>>>>>");
          if (res?.info?.code === 200) {
            if (res?.info?.data?.status === "invalid") {
              this.$notify({
                type: "error",
                text: "Please enter valid PAN number.",
              });
              return false;
            }
            if (res?.info?.data?.name_as_per_pan_match === false) {
              this.$notify({
                type: "error",
                text: "Please enter name as mentioned in pan card",
              });
              return false;
            }
            if (res?.info?.data?.date_of_birth_match === false) {
              this.$notify({
                type: "error",
                text: "Please select date of birth as mentioned in pan card",
              });
              return false;
            }
            if (verify_for == "PAN") {
              this.panInfo.has_pan_verified = 1;
              body.category = res?.info?.data?.category;
              this.save(body);
              this.$notify({
                type: "success",
                text: "PAN number verified successfully",
              });
            }
          } else {
            this.panInfo.date_of_birth = new Date(date_of_birth);
            this.$notify({
              type: "error",
              text: res?.message?.error?.message || res?.message?.message,
            });
          }
        })
        .catch(() => {
          this.has_pan_verifying = false;
          this.detail.date_of_birth = new Date(date_of_birth);
        });
    },

    onChange(e, id) {
      var file = e.target.files[0];
      if (id == "img_hoder_1") {
        this.panFile = file;
      }
      if (id == "img_hoder_2") {
        this.aadhaarFile = file;
      }
      if (file) {
        let reader = new FileReader();
        reader.onload = (e) => {
          $("#" + id).attr("src", e.target.result);
          this.file = file;
        };
        reader.readAsDataURL(file);
      }
    },

    uploadImage(id, file, value) {
      this.$api
        .uploadImageAPI({
          _action: "upload-document/" + id,
          _key: "file",
          _file: file,
          _body: { type: value },
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          ShareService.setMapValue("home_tab_status", "REQUIREMENT");
        })
        .catch((e) => {
          this.$notify({
            type: "error",
            text: e.message,
          });
        });
    },
    checkKeyFunction(event) {
      console.log(event, ">>>>>");
      return event.charCode < 48 || event.charCode > 57;
    },
    check() {
      if (
        document.getElementById("codeBox1").value &&
        document.getElementById("codeBox2").value &&
        document.getElementById("codeBox3").value &&
        document.getElementById("codeBox4").value &&
        document.getElementById("codeBox5").value &&
        document.getElementById("codeBox6").value
      ) {
        document.getElementById("digit").style.display = "none";
        return true;
      } else {
        document.getElementById("digit").style.display = "block";
        return false;
      }
    },
    getCodeBoxElement(index) {
      return document.getElementById("codeBox" + index);
    },
    onKeyUpEvent(index, event) {
      const eventCode = event.which || event.keyCode;
      if (this.getCodeBoxElement(index).value.length === 1) {
        if (index !== 6) {
          this.getCodeBoxElement(index + 1).focus();
        } else {
          this.getCodeBoxElement(index).blur();
          // Submit code
          console.log("submit code ");
        }
      }
      if (eventCode === 8 && index !== 1) {
        this.getCodeBoxElement(index - 1).focus();
      }
    },
  },
};
</script>
