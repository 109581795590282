<template>
  <div>
    <div class="page-content">
      <div class="row mb-4 justify-content-center">
        <div class="col-lg-5">
          <div class="row mb-md-4 mb-2">
            <div class="col-lg-12 text-md-left text-center">
              <h2 class="page-title mb-md-0">
                <a class="cursor-pointer" v-on:click="this.$router.go(-1)">
                  <img
                    src="/images/back-arrow.svg"
                    width="17"
                    class="mr-2 align-top"
                    alt="" /></a
                ><span v-if="id">Edit Address</span
                ><span v-else>Add Address</span>
              </h2>
            </div>
            <div class="col-md-6 text-right d-flex justify-content-end"></div>
          </div>
          <div class="card mb-4 rounded-l border-0 shadow-sm">
            <div class="card-body ">
              <div class="row justify-content-center">
                <div class="col-md-12">
                  <Form @submit="signup" class="form-style floating-form">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="floating-form-error mb-3">
                          <div class="floating-form-group">
                            <Field
                              type="address"
                              id="address"
                              name="address"
                              class="filled"
                              v-model="detail.address"
                              rules="required:address"
                              :validateOnInput="true"
                            />
                            <label for="address">Address</label>
                          </div>
                          <ErrorMessage name="address" class="validation-msg mt-1" />
                        </div>
                      </div>

                      <div class="col-lg-12">
                        <label
                          >Select State
                          <span class="text-danger">*</span></label
                        >
                        <div class="form-group">
                          <Field
                            as="select"
                            class="custom-select"
                            v-model="detail.state_id"
                            name="state"
                            rules="required:state,true"
                            @update:model-value="getDistrictList()"
                          >
                            <option value="">Select State</option>
                            <option
                              v-for="(data, index) in stateList"
                              :key="index"
                              :value="data.id"
                            >
                              {{ data.name }}
                            </option>
                          </Field>
                        </div>
                        <ErrorMessage name="state" class="validation-msg mt-1" />
                      </div>

                      <div class="col-lg-12">
                        <label
                          >Select District
                          <span class="text-danger">*</span></label
                        >
                        <div class="form-group">
                          <Field
                            as="select"
                            class="custom-select"
                            v-model="detail.district_id"
                            name="district"
                            rules="required:district,true"
                            :disabled="!detail.state_id"
                          >
                            <option value="">Select District</option>
                            <option
                              v-for="(data, index) in districtList"
                              :key="index"
                              :value="data.id"
                            >
                              {{ data.name }}
                            </option>
                          </Field>
                        </div>
                        <ErrorMessage name="district" class="validation-msg mt-1" />
                      </div>

                      <div class="col-lg-12" v-if="is_show">
                        <label
                          >Select Type <span class="text-danger">*</span></label
                        >
                        <div class="form-group">
                          <Field
                            as="select"
                            class="custom-select"
                            v-model="detail.address_type"
                            name="address_type"
                            rules="required:address type,true"
                          >
                            <option value="">Select Type</option>
                            <option value="BILLING">Billing</option>
                            <option value="SHIPPING">Delivery</option>
                          </Field>
                        </div>
                        <ErrorMessage
                          name="address_type"
                          class="validation-msg mt-1"
                        />
                      </div>

                      <div class="col-md-12 mt-3">
                        <button
                          class="btn btn-brand-1 btn-block mb-3"
                          id="signup-button"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import $ from "jquery";
import router from "../../router";
import ShareService from "../../services/ShareService";
export default {
  name: "MyAddress",
  components: { Form, Field, ErrorMessage },
  data() {
    return {
      id: this.$route.params.id,
      
      user_type: localStorage.getItem("user_type"),
      designation: localStorage.getItem("designation"),
      detail: { address_type: "" },
      stateList: [],
      districtList: [],
      is_show: true,
    };
  },
  mounted() {
    this.user_type = localStorage.getItem("user_type");
    this.$store.commit("showSidebar", true);
    if (this.user_type == "SELLER") {
      this.detail.address_type = "BILLING";
      this.is_show = false;
    }

    if (this.user_type == "BUYER") {
      var type = ShareService.getMapValue("address_type");
      if (type) {
        this.detail.address_type = type;
      }
    }
    ShareService.deleteMapValue("address_type");
    this.getStateList();

    $(
      ".floating-form-group input,.floating-form-group textarea,.floating-form select"
    ).change(function () {
      if ($(this).val() != "") {
        $(this).addClass("filled");
      } else {
        $(this).removeClass("filled");
      }
    });
  },
  methods: {
    getStateList() {
      this.$api
        .getAPI({
          _action: "states",
        })
        .then((res) => {
          this.stateList = res.list;
          if (this.id) {
            this.getDetail();
          } else {
            this.getMe();
          }
        })
        .catch(() => {});
    },
    getDistrictList() {
      this.$api
        .getAPI({
          _action: "districts/" + this.detail.state_id,
        })
        .then((res) => {
          this.districtList = res.list;
        })
        .catch(() => {});
    },
    getDetail() {
      this.$api
        .getAPI({
          _action: "delivery/address/" + this.id,
        })
        .then((res) => {
          this.detail = res.info;
          if (this.detail.state_id) {
            this.getDistrictList();
          }
        })
        .catch(() => {});
    },

    getMe() {
      this.$api
        .getAPI({
          _action: "company/info",
        })
        .then((res) => {
          if (res.info.state_id) {
            this.detail.state_id = res.info.state_id;
            this.detail.district_id = res.info.district_id;
            this.getDistrictList();
          }
        })
        .catch(() => {});
    },

    signup() {
      if (this.id) {
        this.$api
          .putAPI({
            _action: "delivery/address/" + this.id,
            _body: this.detail,
            _buttonId: "signup-button",
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            router.push("/edit-profile");
          })
          .catch(() => {});
      } else {
        this.$api
          .postAPI({
            _action: "delivery/address",
            _body: this.detail,
            _buttonId: "signup-button",
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            router.push("/edit-profile");
          })
          .catch(() => {});
      }
    },
  },
};
</script>

