<template >
  <div>
    <header
      class="p-md-4 p-3 shadow-sm bg-white index-header position-fixed w-100"
      style="z-index: 1"
    >
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-md-3 col-3">
            <img
              src="/images/yarnmart-Logo.svg"
              width="200"
              height="70"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </header>
    <main role="main">
      <div id="main-wrapper" class="login-wrap" style="height: 100vh;">
        <div class="container-fluid h-100">
          <div
            class="
              row
              justify-content-md-center
              align-items-center
              h-100
              bg-white
            "
          >
            <div class="col-md-4 col-sm-6 bg-white card-body">
              <div class="row justify-content-center">
                <div class="col-lg-12">
                  <div class="">
                    <h4 class="font-weight-bold text-center mt-3">
                      Forgot password?
                    </h4>
                    <div
                      class="
                        card-header
                        bg-white
                        rounded-10
                        text-center
                        border-0
                      "
                    >
                      <img
                        src="/images/forgot-password.svg"
                        width="100"
                        height="100"
                        class="img-fluid svg-img"
                      />
                    </div>
                    <div class="">
                      <div class="mb-3 text-secondary">
                        Please enter your registered mobile number to get the
                        OTP
                      </div>
                      <Form
                        @submit="forgotPassword"
                        class="form-style floating-form"
                      >
                        <div class="floating-form floating-form-error">
                          <div class="floating-form-group mb-2">
                            <Field
                              type="mobile"
                              id="mobile"
                              name="mobile"
                              v-model="details.mobile_number"
                              rules="required:mobile number|phone"
                              :validateOnInput="true"
                              maxlength="10"
                            />
                            <label for="mobile">Mobile Number</label>
                          </div>

                          <ErrorMessage name="mobile" class="text-danger" />
                        </div>
                        <button
                          class="btn btn-brand-1 btn-block mb-3 mt-3"
                          id="submit-button"
                          style="cursor: pointer"
                        >
                          Submit
                        </button>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import api from "../../services/WebService";
import router from "../../router";
import $ from "jquery";
export default {
  name: "ForgotPassword",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      details: {},
    };
  },
  mounted() {
    //floating form
    $(document).ready(function () {
      $(
        ".floating-form-group input,.floating-form-group textarea,.floating-form select"
      ).change(function () {
        if ($(this).val() != "") {
          $(this).addClass("filled");
        } else {
          $(this).removeClass("filled");
        }
      });
    });
  },
  methods: {
    forgotPassword() {
      api
        .postAPI({
          _action: "forgot-password",
          _body: this.details,
          _buttonId: "submit-button",
        })
        .then((res) => {
          if (res) {
            localStorage.setItem("forgot_mobile", this.details.mobile_number);
            localStorage.setItem("otp-from", "FORGOT");
            router.push("/verify-otp");
          }
        })
        .catch(() => {});
    },
  },
};
</script>