<template>
  <div class="page-content">
    <div class="row mb-4 justify-content-center">
      <div class="col-lg-8">
        <div class="row mb-md-4 mb-2 align-items-center">
          <div class="col-md-6 text-md-left text-center">
            <h2 class="page-title mb-md-0">
              <a class="cursor-pointer" v-on:click="this.$router.push('/order')">
                <img src="/images/back-arrow.svg" width="17" class="mr-2 align-top" alt="" /></a>Order Detail
            </h2>
          </div>
          <div class="col-md-6 text-right d-flex justify-content-end"></div>
        </div>
        <div class="card mb-4 rounded-l border-0 shadow-sm">

          <form class="form-style floating-form">
            <div class="row justify-content-center">
              <div class="col-md-12">
                <div class="row mt-4">
                  <div class="col-6 text-center">
                    <h5 class="font-medium text-secondary">
                      Booking No. &nbsp; {{ detail.booking.booking_no }}
                    </h5>
                  </div>
                  <div class="col-6 text-center">
                    <h5 class="font-medium text-secondary">
                      Order No. &nbsp; {{ detail.order_no }}
                    </h5>
                  </div>
                </div>

                <hr />
                <div class="mt-2">
                  <div class="mb-4">
                    <div class="row justify-content-center">
                      <div class="col-md-11">
                        <h3 class="font-18 mb-3">
                          Order Details & Shipment Details
                        </h3>
                        <div class="card mb-3">
                          <div class="card-body">
                            <div class="row mb-2">
                              <div class="col-md-6">
                                <p class="mb-0 text-secondary">Delivered On</p>
                              </div>
                              <div class="col-md-6">
                                <p class="mb-0 font-medium">
                                  {{
                                    $helperService.getFormatedDate(
                                      detail.dispatch_at
                                    )
                                  }}
                                </p>
                              </div>
                            </div>

                            <div class="row mb-2">
                              <div class="col-md-6">
                                <p class="mb-0 text-secondary">Invoice No</p>
                              </div>
                              <div class="col-md-6">
                                <p class="mb-0 font-medium" v-if="detail.invoice_no">
                                  {{ detail.invoice_no }}
                                </p>
                                <p class="mb-0 font-medium" v-else>-</p>
                              </div>
                            </div>

                            <div class="row mb-2">
                              <div class="col-md-6">
                                <p class="mb-0 text-secondary">
                                  Transporter Name
                                </p>
                              </div>
                              <div class="col-md-6">
                                <p class="mb-0 font-medium" v-if="detail.transporter_name">
                                  {{ detail.transporter_name }}
                                </p>
                                <p class="mb-0 font-medium" v-else>-</p>
                              </div>
                            </div>

                            <div class="row mb-2">
                              <div class="col-md-6">
                                <p class="mb-0 text-secondary">L.R. Number</p>
                              </div>
                              <div class="col-md-6">
                                <p class="mb-0 font-medium" v-if="detail.lr_number">
                                  {{ detail.lr_number }}
                                </p>
                                <p class="mb-0 font-medium" v-else>-</p>
                              </div>
                            </div>

                            <div class="row mb-2">
                              <div class="col-md-6">
                                <p class="mb-0 text-secondary">
                                  Vehicle Number
                                </p>
                              </div>
                              <div class="col-md-6">
                                <p class="mb-0 font-medium" v-if="detail.vehicle_number">
                                  {{ detail.vehicle_number }}
                                </p>
                                <p class="mb-0 font-medium" v-else>-</p>
                              </div>
                            </div>

                            <div class="row mb-2">
                              <div class="col-md-6">
                                <p class="mb-0 text-secondary">Driver Number</p>
                              </div>
                              <div class="col-md-6">
                                <p class="mb-0 font-medium" v-if="detail.driver_number">
                                  {{ detail.driver_number }}
                                </p>
                                <p class="mb-0 font-medium" v-else>-</p>
                              </div>
                            </div>

                            <div class="row mb-2">
                              <div class="col-md-6">
                                <p class="mb-0 text-secondary">Net Quantity</p>
                              </div>
                              <div class="col-md-6">
                                <p class="mb-0 font-medium" v-if="detail.net_quantity">
                                  {{ detail.net_quantity }} (Kg)
                                </p>
                                <p class="mb-0 font-medium" v-else>-</p>
                              </div>
                            </div>

                            <div class="row mb-2">
                              <div class="col-md-6">
                                <p class="mb-0" style="color: #6243FD;">Invoice Amount</p>
                              </div>
                              <div class="col-md-6">
                                <p class="mb-0 font-medium" v-if="detail.invoice_amount">
                                  ₹ {{ detail.invoice_amount }}
                                </p>
                                <p class="mb-0 font-medium" v-else>-</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row" v-if="detail.booking.bid.payment_term">
                          <div class="col-md-6">
                            <h3 class="font-18  mb-3">Payment Information</h3>
                          </div>
                          <div class="col-md-6">
                            <span class="font-medium text-secondary float-right" v-if="detail.booking.bid.payment_term">{{
                              detail.booking.bid.payment_term.title }}</span>
                            <span class="font-medium text-secondary float-right" v-else>-</span>
                          </div>
                        </div>
                        <div class="card mb-3">
                          <div class="card-body">
                            <span class="font-medium text-secondary">Billing Address</span>
                            <p>
                              {{ detail.billing_address.address }}, {{
                                detail.billing_address.district.name
                              }}
                              <br />{{ detail.billing_address.state.name }}
                            </p>
                          </div>
                        </div>
                        <div class="card mb-3">
                          <div class="card-body">
                            <span class="font-medium text-secondary">Billing Address</span>
                            <p>
                              {{ detail.delivery_address.address }}, {{
                                detail.delivery_address.district.name
                              }}
                              <br />{{ detail.delivery_address.state.name }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "OrderDetail",
  components: {},
  data() {
    return {

      user_type: localStorage.getItem("user_type"),
      designation: localStorage.getItem("designation"),
      detail: {
        billing_address: { state: {}, district: {} },
        delivery_address: { state: {}, district: {} },
        order_no: "",
        booking: {
          buyer: {},
          bid: { payment_term: {} },
        },
      },
      orderId: this.$route.params.orderId,
      bookingId: this.$route.params.bookingId,
    };
  },
  mounted() {
    this.user_type = localStorage.getItem("user_type");
    this.getDetail();
  },
  methods: {
    getDetail() {
      var url = "";
      if (this.user_type == "SELLER") {
        url = "/seller/booking/" + this.bookingId + "/order/" + this.orderId;
      } else {
        url = "/buyer/booking/" + this.bookingId + "/order/" + this.orderId;
      }
      this.$api
        .getAPI({
          _action: url,
        })
        .then((res) => {
          this.detail = res.info;
        })
        .catch(() => { });
    },
  },
};
</script>

