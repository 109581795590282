<template>
  <div>
    <div class="page-content">
      <div class="row mb-4 justify-content-center">
        <div class="col-lg-8">
          <div class="row mb-md-4 mb-2 align-items-center">
            <div class="col-md-6 text-md-left text-center">
              <h2 class="page-title mb-md-0">
                <a class="cursor-pointer" v-on:click="this.$router.go(-1)">
                  <img
                    src="/images/back-arrow.svg"
                    width="17"
                    class="mr-2 align-top"
                    alt="" /></a
                >Booking Details
              </h2>
            </div>
          </div>
          <div class="card rounded-l border-0 shadow mb-3">
            <div class="ml-3 mt-3"><h3>Parameters</h3></div>
            <div class="card-body border-bottom expert-list">
              <div
                class="row mb-2"
                v-for="(data, index) in detail.trade.fields"
                :key="index"
              >
                <div class="col-md-6">
                  <p class="mb-0 text-secondary">{{ data.title }}</p>
                </div>
                <div class="col-md-6" v-if="data.option && data.field_type == 'DROPDOWN'">
                  <p class="mb-0 font-medium">
                    {{ data.option.title }}
                  </p>
                </div>
                <div class="col-md-6" v-else>
                  <p class="mb-0 font-medium">
                    {{ data.value }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="card rounded-l border-0 shadow mb-3" v-if="user_type == 'SELLER'">
            <div class="ml-3 mt-3"><h3>Buyer’s Proposal</h3></div>
            <div class="card-body border-bottom expert-list">
              <div class="row mb-2">
                <div class="col-md-6">
                  <p class="mb-0 text-secondary">Quantity</p>
                </div>
                <div class="col-md-6">
                  <p class="mb-0 font-medium">
                    {{ detail.quantity }} {{ detail.trade.unit }}
                  </p>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-md-6">
                  <p class="mb-0 text-secondary">Bid Rate</p>
                </div>
                <div class="col-md-6">
                  <p class="mb-0 font-medium">₹ {{ detail.bid_rate }}</p>
                </div>
              </div>

              <div class="row mb-2" v-if="detail.passed_at">
                <div class="col-md-6">
                  <p class="mb-0 text-secondary">Passing & Weighment at</p>
                </div>
                <div class="col-md-6">
                  <p class="mb-0 font-medium">
                    {{ detail.passed_at }} ₹{{ detail.bid_rate }}
                  </p>
                </div>
              </div>

              <div class="row mb-2" v-if="detail.payment_at">
                <div class="col-md-6">
                  <p class="mb-0 text-secondary">Payment Terms</p>
                </div>
                <div class="col-md-6">
                  <p class="mb-0 font-medium" v-if="detail.payment_term">
                    {{ detail.payment_term.title }}
                  </p>
                  <p class="mb-0 font-medium" v-else>-</p>
                </div>
              </div>
            </div>
          </div>

          <div
            class="card rounded-l border-0 shadow mb-3"
            v-if="
              user_type == 'BUYER' || (user_type == 'BROKER' && broker_view == 'BUYER')
            "
          >
            <div class="ml-3 mt-3"><h3>Quantity & Rate</h3></div>
            <div class="card-body border-bottom expert-list">
              <div class="row mb-2">
                <div class="col-md-6">
                  <p class="mb-0 text-secondary">Quantity</p>
                </div>
                <div class="col-md-6">
                  <p class="mb-0 font-medium">
                    {{ detail.quantity }} {{ detail.trade.unit }}
                  </p>
                </div>
              </div>

              <div class="row mb-2">
                <div class="col-md-6">
                  <p class="mb-0 text-secondary">Bid Rate</p>
                </div>
                <div class="col-md-6">
                  <p class="mb-0 font-medium">₹ {{ detail.bid_rate }}</p>
                </div>
              </div>

              <div class="row mb-2" v-if="detail.passed_at">
                <div class="col-md-6">
                  <p class="mb-0 text-secondary">Passing & Weighment at</p>
                </div>
                <div class="col-md-6">
                  <p class="mb-0 font-medium">
                    {{ detail.passed_at }}
                  </p>
                </div>
              </div>

              <div class="row mb-2" v-if="detail.payment_at">
                <div class="col-md-6">
                  <p class="mb-0 text-secondary">Payment Terms</p>
                </div>
                <div class="col-md-6">
                  <p class="mb-0 font-medium" v-if="detail.payment_term">
                    {{ detail.payment_term.title }}
                  </p>
                  <p class="mb-0 font-medium" v-else>-</p>
                </div>
              </div>
            </div>
          </div>

          <div class="card rounded-l border-0 shadow mb-3">
            <div class="ml-3 mt-3"><h3>Other Information</h3></div>
            <div class="card-body border-bottom expert-list">
              <div class="row mb-2">
                <div class="col-md-6">
                  <p class="mb-0 text-secondary">Trade ID</p>
                </div>
                <div class="col-md-6">
                  <p
                    style="color: #9cc338"
                    class="mb-0 font-medium"
                    v-on:click="$router.push('/trade-detail/' + detail.trade.uuid)"
                  >
                    {{ detail.trade.trade_no }}
                  </p>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-md-6">
                  <p class="mb-0 text-secondary">Notes</p>
                </div>
                <div class="col-md-6">
                  <p class="mb-0 font-medium">
                    {{ detail.trade.narration }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="card rounded-l border-0 shadow mb-3"
            v-if="this.detail.image_1 || this.detail.image_2 || this.detail.video"
          >
            <div class="ml-3 mt-3"><h3>Photo & Video</h3></div>
            <div class="card-body border-bottom expert-list">
              <div class="row mb-2" v-if="this.detail.image_1 || this.detail.image_2">
                <div class="col-md-6" v-if="this.detail.image_1">
                  <img
                    :src="
                      this.detail.trade.image_1
                        ? this.detail.trade.image_1
                        : '/img/placeholder-image.jpg'
                    "
                    width="345"
                    height="200"
                  />
                </div>
                <div class="col-md-6" v-if="this.detail.image_2">
                  <img
                    :src="
                      this.detail.trade.image_2
                        ? this.detail.trade.image_2
                        : '/img/placeholder-image.jpg'
                    "
                    width="345"
                    height="200"
                  />
                </div>
              </div>
              <div class="row mb-2" v-if="this.detail.video">
                <div class="col-md-12">
                  <video width="730" height="270" controls :key="detail.trade.video">
                    <source :src="detail.trade.video" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3 justify-content-center">
            <div class="col-md-6" v-if="detail.status == 'PENDING'">
              <button
                class="btn btn-brand-1 btn-block mb-3"
                id="login-button"
                style="cursor: pointer; min-width: 155px"
                v-if="
                  user_type == 'BUYER' ||
                  (user_type == 'BROKER' &&
                    broker_view == 'BUYER' &&
                    (designation == 'PRIMARY_OWNER' || designation == 'SECONDERY_OWNER'))
                "
                v-on:click="showBookPopup()"
              >
                Edit Bid
              </button>
            </div>
            <div class="col-md-6" v-if="detail.status == 'PENDING'">
              <button
                class="btn cancel-btn"
                id="login-button"
                style="cursor: pointer; min-width: 155px"
                v-if="
                  user_type == 'BUYER' ||
                  (user_type == 'BROKER' &&
                    broker_view == 'BUYER' &&
                    (designation == 'PRIMARY_OWNER' || designation == 'SECONDERY_OWNER'))
                "
                v-on:click="showStatusPopup('CANCEL')"
              >
                CANCEL
              </button>
            </div>
            <div class="col-md-6" v-if="detail.status == 'PENDING'">
              <button
                class="accepted-btn"
                id="login-button"
                style="cursor: pointer; min-width: 155px"
                v-on:click="showStatusPopup('ACCEPT')"
                v-if="
                  user_type == 'SELLER' &&
                  booking_status == 'PENDING' &&
                  (designation == 'PRIMARY_OWNER' || designation == 'SECONDERY_OWNER')
                "
              >
                Accept
              </button>
            </div>
            <div class="col-md-6" v-if="detail.status == 'PENDING'">
              <button
                class="btn rejected-btn"
                id="login-button"
                style="cursor: pointer; min-width: 155px"
                v-if="
                  user_type == 'SELLER' &&
                  booking_status == 'PENDING' &&
                  (designation == 'PRIMARY_OWNER' || designation == 'SECONDERY_OWNER')
                "
                v-on:click="showStatusPopup('REJECT')"
              >
                Reject
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <TextModal ref="addModal">
    <Form ref="addForm" @submit="checkOut()">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            v-on:click="this.$refs.addModal.closeModal()"
          >
            <img src="/images/close.svg" width="15" />
          </button>
        </div>
        <div class="modal-body floating-form">
          <h4 class="font-22 modal-tital text-center mb-4">Edit Trade</h4>
          <div class="row">
            <div class="col-md-12 align-self-end">
              <div class="floating-form-error mb-3">
                <div class="floating-form-group">
                  <Field
                    type="text"
                    id="quantity"
                    name="quantity"
                    :class="book.quantity ? 'filled' : ''"
                    v-model="book.quantity"
                    rules="required:quantity"
                    :validateOnInput="true"
                  />
                  <label for="quantity">Quantity</label>
                  <span class="text-danger">*</span>
                </div>
                <ErrorMessage name="quantity" class="validation-msg mt-1" />
              </div>
            </div>
            <div class="col-12">
              <label class="font-medium">Bid Type</label>
              <div class="d-md-flex mb-3">
                <div class="custom-control custom-radio mb-2 mr-3">
                  <input
                    type="radio"
                    id="Spot"
                    name="bid_type"
                    value="SPOT"
                    class="custom-control-input"
                    v-model="book.bid_type"
                  />
                  <label class="custom-control-label" for="Spot">SPOT</label>
                </div>
                <div
                  class="custom-control custom-radio mb-2 mr-3"
                  v-if="this.detail.trade.product.has_for_enabled"
                >
                  <input
                    type="radio"
                    id="For"
                    name="bid_type"
                    value="FOR"
                    class="custom-control-input"
                    v-model="book.bid_type"
                  />
                  <label class="custom-control-label" for="For">FOR</label>
                </div>
                <div
                  class="custom-control custom-radio mb-2 mr-3"
                  v-if="this.detail.trade.product.has_fmd_enabled"
                >
                  <input
                    type="radio"
                    id="Fmd"
                    name="bid_type"
                    value="FMD"
                    class="custom-control-input"
                    v-model="book.bid_type"
                  />
                  <label class="custom-control-label" for="Fmd">FMD</label>
                </div>
              </div>
            </div>

            <div class="col-md-12 align-self-end">
              <div class="row">
                <div class="col-md-6">
                  <label class="font-medium">Ask Rate (Per {{ detail.unit }})</label>
                </div>
                <div class="col-md-6 text-right">
                  <label class="font-medium">₹ {{ detail.trade.ask_rate }}</label>
                </div>
              </div>
            </div>

            <div class="col-md-12 align-self-end">
              <div class="row">
                <div class="col-md-6">
                  <label class="font-medium">Avaliable Quantity</label>
                </div>
                <div class="col-md-6 text-right">
                  <label class="font-medium">
                    {{ detail.trade.available_quantity }}
                    {{ detail.trade.unit }}</label
                  >
                </div>
              </div>
            </div>

            <div
              class="col-md-6 align-self-end mt-3"
              v-if="detail.trade.product.has_passed_at"
            >
              <div class="floating-form-error mb-3">
                <label>Passing & Weighment at <span class="text-danger">*</span></label>
                <div class="floating-form-group mb-0">
                  <Field
                    as="select"
                    class="custom-select"
                    name="passed_at"
                    id="passed_at"
                    rules="required:Passing & Weighment at,true"
                    v-model="book.passed_at"
                    :validateOnInput="true"
                  >
                    <option value="">Select Passing & Weighment at</option>
                    <option value="SPOT">Spot</option>
                    <option value="MILL">Mill</option>
                  </Field>
                </div>
                <ErrorMessage name="passed_at" class="validation-msg mt-1" />
              </div>
            </div>

            <div
              class="col-md-6 align-self-end"
              v-if="detail.trade.product.payment_terms_type == 'POST'"
            >
              <div class="floating-form-error mb-3">
                <label>Payment Term <span class="text-danger">*</span></label>
                <div class="floating-form-group mb-0">
                  <Field
                    as="select"
                    class="custom-select"
                    name="paymentTerm"
                    id="paymentTerm"
                    rules="required:Payment Term"
                    v-model="book.payment_at"
                    :validateOnInput="true"
                  >
                    <option value="">Select Payment Term</option>
                    <option
                      v-for="(obj, i) in detail.trade.product.payment_term"
                      :key="i"
                      :value="obj.value"
                    >
                      {{ obj.title }}
                    </option>
                  </Field>
                </div>
                <ErrorMessage name="paymentTerm" class="validation-msg mt-1" />
              </div>
            </div>

            <div class="col-md-12 align-self-end">
              <div class="floating-form-error mb-3">
                <div class="floating-form-group">
                  <Field
                    type="text"
                    name="bid_rate"
                    id="bid_rate"
                    :class="book.bid_rate ? 'filled' : ''"
                    v-model="book.bid_rate"
                    rules="required:bid rate|numeric"
                    :validateOnInput="true"
                  />
                  <label for="bid_rate">Bid Rate</label>
                </div>
                <ErrorMessage name="bid_rate" class="validation-msg mt-1" />
              </div>
            </div>

            <div class="col-md-12 align-self-end">
              <div class="floating-form-error mb-3">
                <label>Search Broker <span class="text-danger">*</span></label>
                <div class="floating-form-group mb-0">
                  <Field
                    as="select"
                    class="custom-select"
                    name="broker"
                    id="broker"
                    rules="required:broker,true"
                    v-model="book.broker_id"
                    :validateOnInput="true"
                  >
                    <option value="">Select Broker</option>
                    <option v-for="(obj, i) in brokerList" :key="i" :value="obj.uuid">
                      {{ obj.name }}
                    </option>
                  </Field>
                </div>
                <ErrorMessage name="broker" class="validation-msg mt-1" />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 justify-content-center mb-3">
          <button class="btn btn-brand-1 px-4" id="bid-button">Save</button>
        </div>
      </div>
    </Form>
  </TextModal>
  <TextModal ref="statusModal" class="modal-small">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" v-on:click="$refs.statusModal.closeModal">
          <img src="/images/close.svg" width="15" />
        </button>
      </div>
      <div class="modal-body text-center px-md-5">
        <h5 class="modal-title font-22 mb-4">
          {{ $helperService.getTitleCase(status) }} Bid
        </h5>

        <p class="font-18">
          Are you sure <br />
          you want to {{ $helperService.getTitleCase(status) }} bid ?
        </p>
        <div class="row" v-if="status == 'REJECT'">
          <div class="col-md-12">
            <label for="" class="float-left">Reasons </label>
            <div class="form-group">
              <select name="" class="form-control" id="" v-model="Narration.narration">
                <option value="Required higher Rate">Required higher Rate</option>
                <option value="Change Broker">Change Broker</option>
                <option value="Change Buyer">Change Buyer</option>
                <option value="Goods are sold">Goods are sold</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </div>
          <div class="col-md-12" v-show="Narration.narration == 'Other'">
            <label for="" class="float-left">Other</label>
            <div class="form-group">
              <input type="text" v-model="OtherNarration" class="form-control" />
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer justify-content-center border-0 pb-4 pt-0">
        <div class="row">
          <div class="col-md-6">
            <button
              type="button"
              class="btn btn-brand-1 btn-block mb-3"
              id="status-button"
              style="min-width: 115px"
              v-on:click="updateStatus()"
            >
              Yes
            </button>
          </div>
          <div class="col-md-6">
            <button
              type="button"
              class="btn cancel-btn"
              style="min-width: 115px"
              v-on:click="$refs.statusModal.closeModal"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  </TextModal>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import TextModal from "@/components/TextModal.vue";
import ShareService from "../../services/ShareService";
import $ from "jquery";
import router from "../../router";
export default {
  name: "BookingDetail",
  components: { TextModal, Form, Field, ErrorMessage },
  data() {
    return {
      user_type: localStorage.getItem("user_type"),
      designation: localStorage.getItem("designation"),
      broker_view: localStorage.getItem("broker_view"),
      detail: { trade: { product: {} } },
      uuid: this.$route.params.uuid,
      OtherNarration: "",
      Narration: { narration: "Required higher Rate" },
      book: {
        quantity: "",
        bid_type: "SPOT",
        bid_rate: "",
        broker_id: "",
        passed_at: "",
        payment_at: "",
      },
      brokerList: [],
      booking_status: "",
      status: "",
    };
  },
  mounted() {
    this.user_type = localStorage.getItem("user_type");
    this.booking_status = ShareService.getMapValue("booking_status");
    if (!this.booking_status) {
      this.booking_status = "PENDING";
    }
    this.getDetail();
    $(
      ".floating-form-group mb-0 input,.floating-form-group mb-0 textarea,.floating-form select"
    ).change(function () {
      if ($(this).val() != "") {
        $(this).addClass("filled");
      } else {
        $(this).removeClass("filled");
      }
    });
  },
  methods: {
    updateStatus() {
      if (this.status == "ACCEPT") {
        this.$api
          .postAPI({
            _action: "seller/bid/accept/" + this.detail.uuid,
            _buttonId: "status-button",
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.$refs.statusModal.closeModal();
            ShareService.setMapValue("booking_status", "ACCEPTED");
            router.push("/booking");
          })
          .catch(() => {});
      } else if (this.status == "REJECT") {
        if (this.OtherNarration.length > 0) {
          this.Narration.narration = "Other - " + this.OtherNarration;
        }
        this.$api
          .postAPI({
            _action: "seller/bid/reject/" + this.detail.uuid,
            _body: this.Narration,
            _buttonId: "status-button",
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.$refs.statusModal.closeModal();
            ShareService.setMapValue("booking_status", "REJECTED");
            router.push("/booking");
          })
          .catch(() => {});
      } else {
        this.$api
          .deleteAPI({
            _action: "buyer/bid/" + this.detail.uuid,
            _buttonId: "status-button",
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.$refs.statusModal.closeModal();
            ShareService.setMapValue("booking_status", "PENDING");
            router.push("/booking");
          })
          .catch(() => {});
      }
    },
    showStatusPopup(type) {
      this.status = type;
      this.$refs.statusModal.showModal();
    },
    showBookPopup() {
      this.getBrokerList();
      this.book = {
        quantity: this.detail.quantity,
        bid_type: this.detail.bid_type,
        bid_rate: this.detail.bid_rate,
        passed_at: this.detail.passed_at,
        payment_at: this.detail.payment_at,
        broker_id: this.detail.broker.uuid,
      };
      this.$refs.addModal.showModal();
    },
    getDetail() {
      var url = "";
      if (this.user_type == "SELLER") {
        url = "seller/bid/" + this.uuid;
      } else {
        url = "buyer/bid/" + this.uuid;
      }
      this.$api
        .getAPI({
          _action: url,
        })
        .then((res) => {
          this.detail = res.info;
        })
        .catch(() => {});
    },
    getBrokerList() {
      this.$api
        .getAPI({
          _action: "brokers",
        })
        .then((res) => {
          this.brokerList = res.list;
        })
        .catch(() => {});
    },
    checkOut() {
      this.book.trade_id = this.detail.trade.uuid;
      this.$api
        .putAPI({
          _action: "buyer/bid/" + this.uuid,
          _body: this.book,
          _buttonId: "bid-button",
        })
        .then((res) => {
          this.getDetail();
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$refs.addModal.closeModal();
        })
        .catch(() => {});
    },
  },
};
</script>
