import {
  createWebHistory,
  createRouter
} from "vue-router";
import Login from "@/views/Onboarding/login.vue";
import Home from "@/views/Home/home.vue";
import Booking from "@/views/Home/booking.vue";
import Order from "@/views/Home/order.vue";
import Payment from "@/views/Home/payment.vue";
import Notification from "@/views/Home/notification.vue";
import Employee from "@/views/Home/employee.vue";
import About from "@/views/Home/about.vue";
import Help from "@/views/Home/help.vue";
import Terms from "@/views/Home/terms.vue";
import ForgotPassword from "@/views/Onboarding/forgot-password.vue";
import ResetPassword from "@/views/Onboarding/reset-password.vue";
import SelectType from "@/views/Onboarding/select-type.vue";
import SearchCompany from "@/views/Onboarding/search-company.vue";
import SignUp from "@/views/Onboarding/signup.vue";
import Verification from "@/views/Onboarding/verification.vue";
import VerifyOTP from "@/views/Onboarding/verify-otp.vue";
import EditProfile from "@/views/Home/editProfile.vue";
import Comission from "@/views/Home/comission.vue";
import Address from "@/views/Home/address.vue";
import MainView from "@/views/main.vue";
import BookingDetail from "@/views/Trade/bookingDetail.vue";
import AddProduct from "@/views/Trade/addProduct.vue";
import EditProduct from "@/views/Trade/addProduct.vue";
import AddBuyerProduct from "@/views/Trade/addBuyerProduct.vue";
import EditBuyerProduct from "@/views/Trade/addBuyerProduct.vue";
import AddVehicle from "@/views/Trade/addVehicle.vue";
import BrokerNote from "@/views/Trade/brokerNote.vue";
import OrderDetail from "@/views/Trade/orderDetail.vue";
import PaymentDetail from "@/views/Trade/paymentDetail.vue";
import AddPaymentDetail from "@/views/Trade/addPaymentDetail.vue";
import SellerPaymentDetail from "@/views/Trade/sellerPaymentDetails.vue";
import AddAddress from "@/views/Home/saveAddress.vue";
import EditAddress from "@/views/Home/saveAddress.vue";
import AddEmployee from "@/views/Home/saveEmployee.vue";
import EditEmployee from "@/views/Home/saveEmployee.vue";
import ManageCompany from "@/views/ManageCompany/manageCompany.vue";
import AddCompany from "@/views/ManageCompany/addEditCompany.vue";
import EditCompany from "@/views/ManageCompany/addEditCompany.vue";
import SellerList from "@/views/Home/sellerList.vue";
import PreferredSupplier from "@/views/Home/preferred-supplier.vue";
import Lead from "@/views/Home/lead.vue";

const routes = [{
    path: "/",
    redirect: "/select-type",
  },
  {
    path: "/select-type",
    name: "SelectType",
    component: SelectType,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    component: ForgotPassword,
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    component: ResetPassword,
  },
  {
    path: "/signup",
    name: "SignUp",
    component: SignUp,
  },
  {
    path: "/verification/:uuid",
    name: "Verification",
    component: Verification,
  },
  {
    path: "/verify-otp",
    name: "OTP",
    component: VerifyOTP,
  },

  {
    path: "/search-company",
    name: "SearchCompany",
    meta: {
      requiresAuth: true,
    },
    component: SearchCompany,
  },

  {
    path: "",
    name: "Main",
    component: MainView,
    meta: {
      requiresAuth: true,
    },
    children: [{
        path: "/home",
        name: "Home",
        component: Home,
      },
      {
        path: "/preferred-supplier",
        name: "PreferredSupplier",
        component: PreferredSupplier,
      },
      {
        path: "/lead",
        name: "Lead",
        component: Lead,
      },
      {
        path: "/booking",
        name: "Booking",
        component: Booking,
      },
      {
        path: "/order",
        name: "Order",
        component: Order,
      },
      {
        path: "/payment",
        name: "Payment",
        component: Payment,
      },
      {
        path: "/comission",
        name: "Comission",
        component: Comission,
      },
      {
        path: "/address",
        name: "Address",
        component: Address,
      },
      {
        path: "/notifications",
        name: "Notification",
        component: Notification,
      },
      {
        path: "/employee",
        name: "Employee",
        component: Employee,
      },
      {
        path: "/about_us",
        name: "About",
        component: About,
      },
      {
        path: "/terms_condition",
        name: "Terms",
        component: Terms,
      },
      {
        path: "/help_support",
        name: "Help",
        component: Help,
      },
      {
        path: "/view-price/:id",
        name: "SellerList",
        component: SellerList,
      },

      {
        path: "/add-product",
        name: "AddProduct",
        component: AddProduct,
      },

      {
        path: "/edit-product/:id",
        name: "EditProduct",
        component: EditProduct,
      },


      {
        path: "/add-buyer-product",
        name: "AddBuyerProduct",
        component: AddBuyerProduct,
      },

      {
        path: "/edit-buyer-product/:id",
        name: "EditProduc",
        component: EditBuyerProduct,
      },


      {
        path: "/booking-detail/:uuid",
        name: "BookingDetail",
        component: BookingDetail,
      },
      {
        path: "/add-vehicle/:uuid",
        name: "AddVehicle",
        component: AddVehicle,
      },
      {
        path: "/broker-note/:orderId/:bookingId",
        name: "BrokerNote",
        component: BrokerNote,
      },

      {
        path: "/order-detail/:orderId/:bookingId",
        name: "OrderDetail",
        component: OrderDetail,
      },

      {
        path: "/add-payment-detail/:uuid",
        name: "AddPaymentDetail",
        component: AddPaymentDetail,
      },
      {
        path: "/seller-payment-detail/:uuid",
        name: "SellerPaymentDetail",
        component: SellerPaymentDetail,
      },

      {
        path: "/payment-detail/:orderId/:bookingId",
        name: "PaymentDetail",
        component: PaymentDetail,
      },

      {
        path: "/edit-profile",
        name: "EditProfile",
        component: EditProfile,
      },


      {
        path: "/add-address",
        name: "AddAddress",
        component: AddAddress,
      },

      {
        path: "/edit-address/:id",
        name: "SaveAddress",
        component: EditAddress,
      },



      {
        path: "/add-employee",
        name: "AddEmployee",
        component: AddEmployee,
      },

      {
        path: "/edit-employee/:uuid",
        name: "EditEmployee",
        component: EditEmployee,
      },


      {
        path: "/manage-company",
        name: "ManageCompany",
        component: ManageCompany,
      },
      {
        path: "/add-company",
        name: "AddCompany",
        component: AddCompany,
      },

      {
        path: "/edit-company/:uuid",
        name: "EditAddress",
        component: EditCompany,
      },

    ],
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("access_token") == null) {
      next({
        path: "/select-type",
        params: {
          nextUrl: to.fullPath,
        },
      });
    } else {
      next();
    }
  } else {
    if (to.path === "/") {
      if (localStorage.getItem("access_token") != null) {
        next({
          path: "/home",
          params: {
            nextUrl: to.fullPath,
          },
        });
      } else {
        next();
      }
    } else {
      next();
    }
  }
});

export default router;