<template>
  <header class="header">
    <div class="row main-row">
      <div
        class="col-lg-12 col-md-2 col-12 d-flex justify-content-end align-items-center"
      >
        <ul class="header-nav ms-auto nav">
          <li class="nav-item" v-if="user.user_type == 'BROKER' && user_type == 'BUYER'">
            <div style="margin-right: 20px">
              Seller
              <label class="switch">
                <input type="checkbox" checked v-on:click="updateUserType('SELLER')" />
                <span class="slider"></span>
              </label>
              Buyer
            </div>
          </li>

          <li class="nav-item" v-if="user.user_type == 'BROKER' && user_type == 'SELLER'">
            <div style="margin-right: 20px">
              Buyer
              <label class="switch">
                <input type="checkbox" checked v-on:click="updateUserType('BUYER')" />
                <span class="slider"></span>
              </label>
              Seller
            </div>
          </li>

          <li v-if="user_type == 'BUYER'">
            <div class="buy-box">
              Available Bids: <span class="number">{{ bids }}</span>
              <span class="bid" v-on:click="openPopup" style="cursor: pointer">Buy</span>
            </div>
          </li>

          <!-- <li class="nav-item dropdown notification-dd">
            <a class="nav-link dropdown-toggle waves-effect waves-dark mr-md-3" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false" id="notification_dd" v-on:click="getNotification(0)">
              <span class="badge badge-danger count" v-show="this.count != 0">{{
                this.count
              }}</span>
              <img src="/images/notification.svg" width="22" />
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="notification_dd">
              <a class="dropdown-item" href="#" v-for="(data, index) in notificationList" :key="index"
                v-on:click="redirectTo(data)">
                <div class="">
                  <p class="font-16 mb-2">{{ data.title }}</p>
                  <p class="font-14 mb-2">{{ data.message }}</p>
                  <p class="font-12 text-secondary mb-0">
                    <img src="/images/clock.svg" width="12" class="align-text-top" />
                    <em>{{ data.created_at }}</em>
                  </p>
                </div>
              </a>

              <p class="text-center mb-0">
                <router-link class="dropdown-item text-warning" to="/notifications" style="text-decoration: underline"
                  v-show="this.length > 3">Show More
                </router-link>
              </p>
            </div>
          </li> -->

          <li
            style="display: none"
            class="nav-item"
            v-if="
              user_type == 'BUYER' ||
              user_type == 'SELLER' ||
              (user_type == 'BROKER' && broker_view == 'BROKER')
            "
          >
            <a
              class="cursor-pointer nav-link"
              title="Profile"
              :class="currentTab == 'profile' ? 'active' : ''"
              v-on:click="$router.push('/edit-profile')"
            >
              <img src="/images/profile_bottom.svg" alt="" width="18" height="18" />
            </a>
          </li>
        </ul>

        <div
          class="d-flex align-items-center pb-2 pt-3 px-2 mb-2 mx-0 cursor-pointer"
          v-on:click="$router.push('/edit-profile')"
        >
          <!-- <img :src="this.detail.profile_img ? this.detail.profile_img : '/images/profile.svg'
            " width="45" height="45" class="rounded-circle object-cover mr-2" /> -->
          <div class="text-truncate">
            {{ detail.first_name }} {{ detail.last_name }}
            <div class="font-14 mb-0 text-truncate">{{ detail.email }}</div>
          </div>
        </div>
      </div>
    </div>
    <TextModal ref="addModal">
      <Form ref="addForm" @submit="checkOut()">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              v-on:click="this.$refs.addModal.closeModal()"
            >
              <img src="/images/close.svg" width="15" />
            </button>
          </div>
          <div class="modal-body floating-form" style="max-height: 500px; overflow: auto">
            <h4 class="font-22 modal-tital text-center mb-4">Buy Bids</h4>
            <div class="row">
              <div class="col-md-12 align-self-end">
                <div class="floating-form-error mb-3">
                  <label>Package <span class="text-danger">*</span></label>
                  <div class="floating-form-group mb-0">
                    <Field
                      as="select"
                      class="custom-select"
                      name="package"
                      id="package"
                      rules="required:Package"
                      v-model="package_id"
                      :validateOnInput="true"
                      @update:model-value="setPackageDetail"
                    >
                      <option value="">Select Package</option>
                      <option v-for="(obj, i) in packageList" :key="i" :value="obj.id">
                        {{ obj.title }}
                      </option>
                    </Field>
                  </div>
                  <ErrorMessage name="package" class="validation-msg mt-1" />
                </div>
              </div>

              <div class="col-md-12 align-self-end" v-if="packageDetail.amount">
                <div class="row">
                  <div class="col-md-6">
                    <label class="font-medium">Amount</label>
                  </div>
                  <div class="col-md-6 text-right">
                    <label class="font-medium">₹ {{ packageDetail.amount }}</label>
                  </div>
                </div>
              </div>

              <div class="col-md-12 align-self-end" v-if="packageDetail.bid">
                <div class="row">
                  <div class="col-md-6">
                    <label class="font-medium">Bids</label>
                  </div>
                  <div class="col-md-6 text-right">
                    <label class="font-medium"> {{ packageDetail.bid }} </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer border-0 justify-content-center">
            <button class="btn btn-brand-1 px-4" id="bid-button">Buy</button>
          </div>
        </div>
      </Form>
    </TextModal>
  </header>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import moment from "moment";
import $ from "jquery";
import TextModal from "@/components/TextModal.vue";
export default {
  name: "InnerHeader",
  components: { TextModal, Form, Field, ErrorMessage },

  data() {
    return {
      currentTab: location.pathname.replace("/", ""),
      searchObj: {
        offset: 0,
      },
      notificationList: [],
      count: "",
      length: "",
      loader: "",
      user: {},
      user_type: localStorage.getItem("user_type"),
      designation: localStorage.getItem("designation"),
      broker_view: localStorage.getItem("broker_view"),
      company_id: localStorage.getItem("company_uuid"),
      status: "",
      view_id: "",
      packageList: [],
      packageDetail: {},
      package_id: "",
    };
  },

  computed: {
    bids() {
      return this.$store.getters.getBid ? this.$store.getters.getBid : 0;
    },
    detail() {
      return this.$store.getters.getUser ? this.$store.getters.getUser : {};
    },
  },

  watch: {
    "$route.fullPath"(newValue) {
      this.currentTab = newValue.replace("/", "");
    },
  },

  mounted() {
    // this.getNotification(0);
    setInterval(() => {
      clearInterval(0);
      // this.getUnreadCount();
    }, 100000);
    $("#menu-toggle,#hear_menu_toggle").click(function (e) {
      e.stopPropagation();
      $("#main-wraper").toggleClass("toggled");
    });
    var user = this.$store.getters.getUser;
    this.$helperService.checkAddress(user);
    this.getMe();
  },

  methods: {
    openPopup() {
      this.package_id = "";
      this.packageDetail = {};
      this.getPackageList();
    },
    setPackageDetail() {
      for (var i = 0; i < this.packageList.length; i++) {
        if (this.package_id == this.packageList[i].id) {
          this.packageDetail = this.packageList[i];
          break;
        }
      }
    },
    showDropdown() {
      var dropdownId = document.getElementById("dropdown-id");
      if (dropdownId) {
        if (dropdownId.className.includes("show")) {
          dropdownId.classList.remove("show");
        } else {
          dropdownId.classList.add("show");
        }
      }
      var dropdownMenuId = document.getElementById("dropdown-menu-id");
      if (dropdownMenuId) {
        if (dropdownMenuId.className.includes("show")) {
          dropdownMenuId.classList.remove("show");
        } else {
          dropdownMenuId.classList.add("show");
        }
      }
    },
    getPackageList() {
      this.$api
        .getAPI({
          _action: "packages",
        })
        .then((res) => {
          this.packageList = res.list;
          this.$refs.addModal.showModal();
        })
        .catch(() => {});
    },
    checkOut() {
      this.$api
        .postAPI({
          _action: "buyer/checkout",
          _body: { package_id: this.package_id },
          _buttonId: "bid-button",
        })
        .then((res) => {
          this.subscribe(res);
          this.$refs.addModal.closeModal();
        })
        .catch(() => {});
    },
    subscribe(data) {
      var $this = this;
      var options = {
        key: data.razorpay_sdk_key, // Enter the Key ID generated from the Dashboard
        amount: data.amount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: "Yarnmart",
        description: "Yarnmart Description",
        order_id: data.subscription,
        handler: function (response) {
          if (response.razorpay_payment_id) {
            $this.checkoutSuccess(response, data.transaction_token);
          }
        },
        prefill: {
          name: $this.detail.name,
          email: $this.detail.email,
          contact: $this.detail.mobile_number,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };
      var rzp1 = new window.Razorpay(options);
      rzp1.on("payment.failed", function () {
        this.$noty.error("Payment Failed, Please try again");
      });

      rzp1.open();
    },
    checkoutSuccess(response, transaction_token) {
      var obj = {};
      obj.razorpay_payment_id = response.razorpay_payment_id;
      obj.transaction_token = transaction_token;
      obj.razorpay_signature = response.razorpay_signature;
      obj.razorpay_order_id = response.razorpay_order_id;
      this.$api
        .postAPI({
          _action: "buyer/checkout/success",
          _body: obj,
        })
        .then(() => {
          this.$notify({
            type: "success",
            text: "Payment Received SuccsessFully. Bid added to you account",
          });
          this.getMe();
        });
    },
    getMe() {
      this.$api
        .getAPI({
          _action: "me",
        })
        .then((res) => {
          this.user = res.info;
          this.bids = this.user.avaliable_bid;
          this.$store.commit("setBid", res.info.avaliable_bid);
        })
        .catch(() => {
          localStorage.removeItem("access_token");
        });
    },
    updateUserType(userType) {
      localStorage.setItem("user_type", userType);
      location.reload();
    },
    getNotification(offset) {
      this.loader = true;
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateLoader(true);
      }

      this.searchObj.offset = offset;
      if (offset == 0) {
        this.notificationList = [];
      }
      this.$api
        .getAPI({
          _action: "notifications",
          _body: this.searchObj,
        })
        .then((res) => {
          this.length = res.list.length;
          this.count = res.unread_count;
          if (res.list.length <= 2) {
            for (var j in res.list) {
              res.list[j].created_at = moment(res.list[j].created_at).format(
                "DD MMM YYYY | HH:mm A"
              );
              this.notificationList.push(res.list[j]);
            }
          } else {
            for (var i = 0; i < 3; i++) {
              res.list[i].created_at = moment(res.list[i].created_at).format(
                "DD MMM YYYY | HH:mm A"
              );
              this.notificationList.push(res.list[i]);
            }
          }
        })
        .catch(() => {});
    },
    getUnreadCount() {
      if (this.company_id) {
        this.$api
          .getAPI({
            _action: "notification/count",
          })
          .then((res) => {
            this.count = res.unread_count;
          })
          .catch(() => {});
      }
    },
    redirectTo(detail) {
      this.unreadNotification(detail.id);
    },
    unreadNotification(id) {
      this.$api
        .postAPI({
          _action: "notification/read/" + id,
        })
        .then(() => {
          this.getNotification(0);
          this.getUnreadCount();
        })
        .catch(() => {});
    },
  },
};
</script>

