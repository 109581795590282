<template>
  <div>
    <div class="page-content">
      <div class="row mb-4 justify-content-center">
        <div class="col-lg-12">
          <div class="row mb-md-4 mb-2 align-items-center">
            <div class="col-md-6 text-md-left text-center">
              <h2 class="page-title mb-md-0">
                <a class="cursor-pointer" v-on:click="this.$router.go(-1)">
                  <img
                    src="/images/back-arrow.svg"
                    width="17"
                    class="mr-2 align-top"
                    alt="" /></a
                ><span v-if="id">Edit Product</span><span v-else>Add Product</span>
              </h2>
            </div>
            <div class="col-md-6 text-right d-flex justify-content-end"></div>
          </div>
          <div class="card mb-4 rounded-l border-0 shadow-sm">
            <div class="card-body px-md-5">
              <div class="row justify-content-center">
                <div class="col-md-10">
                  <Form @submit="signup" class="form-style floating-form">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="mb-3">
                          <div class="floating-form-group">
                            <Field
                              type="text"
                              id="name"
                              name="name"
                              :class="detail.name ? 'filled' : ''"
                              v-model="detail.name"
                              disabled="true"
                            />
                            <label for="name">Name</label
                            ><span class="text-danger">*</span>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6" v-if="is_importer == 'Y'">
                        <div class="mb-3">
                          <div class="form-group">
                            <Field
                              as="select"
                              class="custom-select"
                              v-model="detail.country_id"
                              name="country"
                              rules="required:country,true"
                              :disabled="!detail.yarn_id"
                            >
                              <option value="">Select Country</option>
                              <option
                                v-for="(data, index) in countryList"
                                :key="index"
                                :value="data.id"
                              >
                                {{ data.name }}
                              </option>
                            </Field>
                          </div>
                          <ErrorMessage name="country" class="validation-msg mb-1" />
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="mb-3">
                          <div class="form-group">
                            <Field
                              as="select"
                              class="custom-select"
                              v-model="detail.yarn_id"
                              name="yarn"
                              rules="required:Type of Category,true"
                              @update:model-value="setName('YARN')"
                            >
                              <option value="">Select Type of Category</option>
                              <option
                                v-for="(data, index) in yarnList"
                                :key="index"
                                :value="data.id"
                              >
                                {{ data.name }}
                              </option>
                            </Field>
                          </div>
                          <ErrorMessage name="yarn" class="validation-msg mb-1" />
                        </div>
                      </div>

                      <div class="col-md-6" v-if="detail.yarn_id">
                        <div class="mb-3">
                          <div class="form-group">
                            <Field
                              as="select"
                              class="custom-select"
                              v-model="detail.yarn_sub_id"
                              name="sub_yarn"
                              :rules="'required:' + yarn_name + ' Category Types,true'"
                              @update:model-value="setName('SUB_YARN')"
                              :disabled="!detail.yarn_id"
                            >
                              <option value="">
                                Select {{ yarn_name }} Category Types
                              </option>
                              <option
                                v-for="(data, index) in yarnSubList"
                                :key="index"
                                :value="data.id"
                              >
                                {{ data.name }}
                              </option>
                            </Field>
                          </div>
                          <ErrorMessage name="sub_yarn" class="validation-msg" />
                        </div>
                      </div>

                      <div class="col-md-6" v-if="detail.yarn_sub_id">
                        <div class="mb-3">
                          <div class="form-group">
                            <Field
                              as="select"
                              class="custom-select"
                              v-model="detail.yarn_value_id"
                              name="yarn_value_id"
                              rules="required:Category Value,true"
                              :disabled="!detail.yarn_id"
                            >
                              <option value="">Select Category Value</option>
                              <option
                                v-for="(data, index) in valueList"
                                :key="index"
                                :value="data.id"
                              >
                                {{ data.value }}
                              </option>
                            </Field>
                          </div>
                          <ErrorMessage name="yarn_value_id" class="validation-msg" />
                        </div>
                      </div>

                      <template v-if="detail.yarn_sub_id">
                        <template v-for="(item, i) in compositionlist" :key="i">
                          <div class="col-md-6">
                            <div class="mb-3">
                              <div class="floating-form-group">
                                <label for="name">{{ item.name }}</label>
                                <Field
                                  type="text"
                                  :id="'value_' + i"
                                  :name="'value_' + i"
                                  class="form-control"
                                  rules="credit_limit"
                                  :placeholder="item.name"
                                  :class="item.value ? 'filled' : ''"
                                  v-model="item.value"
                                  @keyup="setValue()"
                                />
                                <ErrorMessage
                                  :name="'value_' + i"
                                  class="validation-msg mt-2"
                                />
                              </div>
                            </div>
                          </div> </template
                      ></template>

                      <div class="col-md-6" v-if="detail.yarn_sub_id">
                        <div class="mb-3">
                          <div class="floating-form-group">
                            <Field
                              type="text"
                              id="min_order_quantity"
                              name="min_order_quantity"
                              :class="detail.min_order_quantity ? 'filled' : ''"
                              rules="required:min order quantity|decimal"
                              v-model="detail.min_order_quantity"
                            />
                            <label for="min_order_quantity">Min order quantity (Kg)</label
                            ><span class="text-danger">*</span>
                          </div>
                        </div>
                        <ErrorMessage
                          name="min_order_quantity"
                          class="validation-msg mb-1"
                        />
                      </div>

                      <div class="col-md-6" v-if="detail.yarn_sub_id">
                        <div class="mb-3">
                          <div class="floating-form-group">
                            <Field
                              type="text"
                              id="total_quantity"
                              name="total_quantity"
                              :class="detail.total_quantity ? 'filled' : ''"
                              :rules="
                                'required:total quantity|decimal|minValue:' +
                                this.detail.min_order_quantity
                              "
                              v-model="detail.total_quantity"
                            />
                            <label for="total_quantity">Total quantity (Kg)</label
                            ><span class="text-danger">*</span>
                          </div>
                        </div>
                        <ErrorMessage name="total_quantity" class="validation-msg mb-1" />
                      </div>

                      <div class="col-md-6" v-if="detail.yarn_sub_id">
                        <div class="mb-3">
                          <div class="floating-form-group">
                            <Field
                              type="text"
                              id="price"
                              name="price"
                              :class="detail.price ? 'filled' : ''"
                              rules="required:price|decimal"
                              v-model="detail.price"
                              @blur="setMillPrice()"
                            />
                            <label for="price">Price (Per Kg)</label
                            ><span class="text-danger">*</span>
                          </div>
                        </div>
                        <ErrorMessage name="price" class="validation-msg mb-1" />
                      </div>

                      <div class="col-md-6" v-if="detail.yarn_sub_id">
                        <div class="form-group">
                          <Field
                            as="select"
                            class="custom-select"
                            v-model="detail.gst_percentage"
                            name="gst_percentage"
                            rules="required:GST percentage,true"
                            @update:model-value="setMillPrice()"
                            :disabled="!detail.price"
                          >
                            <option value="">Select GST Percentage</option>
                            <option value="5">5 %</option>
                            <option value="12">12 %</option>
                            <option value="18">18 %</option>
                          </Field>
                        </div>
                        <ErrorMessage name="gst_percentage" class="validation-msg mb-1" />
                      </div>

                      <div class="col-md-6" v-if="detail.yarn_sub_id">
                        <div class="mb-3">
                          <div class="floating-form-group">
                            <Field
                              type="text"
                              id="ex_mill_price"
                              name="ex_mill_price"
                              :class="detail.ex_mill_price ? 'filled' : ''"
                              v-model="detail.ex_mill_price"
                              disabled="true"
                            />
                            <label for="ex_mill_price">Ex-Mill Price (Per Kg)</label
                            ><span class="text-danger">*</span>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12 mt-3 text-center">
                        <button
                          class="btn btn-brand-1 mb-3"
                          id="signup-button"
                          :disabled="is_disabled"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import $ from "jquery";
import router from "../../router";
export default {
  name: "AddTrade",
  components: { Form, Field, ErrorMessage },
  data() {
    return {
      detail: { gst_percentage: "" },
      id: this.$route.params.id,
      user_type: localStorage.getItem("user_type"),
      designation: localStorage.getItem("designation"),
      is_importer: localStorage.getItem("is_importer"),
      yarnList: [],
      yarnSubList: [],
      countryList: [],
      parent_id: 0,
      yarn_name: "",
      subYarn: {},
      compositionlist: [],
      is_disabled: true,
      valueList: [],
    };
  },
  mounted() {
    var user = this.$store.getters.getUser;
    this.$helperService.checkAddress(user);
    this.getYarnList("YARN");
    this.getCountryList();
    if (this.id) {
      this.getDetail();
    }
    $(
      ".floating-form-group mb-0 input,.floating-form-group mb-0 textarea,.floating-form select"
    ).change(function () {
      if ($(this).val() != "") {
        $(this).addClass("filled");
      } else {
        $(this).removeClass("filled");
      }
    });
  },
  methods: {
    setValue() {
      var value = 0;
      for (var i = 0; i < this.compositionlist.length; i++) {
        if (this.compositionlist[i].value && this.compositionlist[i].value > 0) {
          value += parseInt(this.compositionlist[i].value);
        }
      }
      console.log(value);
      if (value == 100) {
        this.is_disabled = false;
      } else {
        this.is_disabled = true;
      }
    },
    setMillPrice() {
      setTimeout(() => {
        var ExMillPrice,
          price,
          gst = 0;
        price = parseFloat(this.detail.price);
        gst = parseFloat(this.detail.gst_percentage);
        if (gst) {
          ExMillPrice = price + (price * gst) / 100;
          this.detail.ex_mill_price = parseFloat(ExMillPrice);
        } else {
          this.detail.ex_mill_price = "";
        }
      }, 500);
    },
    getCountryList() {
      this.$api
        .getAPI({
          _action: "countries",
        })
        .then((res) => {
          this.countryList = res.list;
        })
        .catch(() => {});
    },
    getYarnList(type) {
      this.$api
        .getAPI({
          _action: "yarns",
          _body: { parent_id: this.parent_id },
        })
        .then((res) => {
          if (type == "YARN") {
            this.yarnList = res.list;
          } else {
            this.yarnSubList = res.list;
          }
        })
        .catch(() => {});
    },
    getDetail() {
      this.$api
        .getAPI({
          _action: "seller/product/" + this.id,
        })
        .then((res) => {
          this.detail = res.info;
          this.subYarn = this.detail.sub_yarn;
          this.parent_id = this.detail.yarn_id;
          this.getYarnList("SUB_YARN");
          this.getYarnValuesList();
          this.getCompositionList();
        })
        .catch(() => {});
    },
    getCompositionList() {
      this.$api
        .getAPI({
          _action: "yarn-composition-list",
          _body: { yarn_id: this.detail.yarn_sub_id },
        })
        .then((res) => {
          this.compositionlist = res;
          for (var i = 0; i < this.compositionlist.length; i++) {
            if (this.id) {
              for (var j = 0; j < this.detail.list.length; j++) {
                if (
                  this.compositionlist[i].id == this.detail.list[j].composition_id &&
                  this.detail.list[j].value
                ) {
                  this.compositionlist[i].value = this.detail.list[j].value;
                }
              }
              this.setValue();
            } else {
              this.compositionlist[i].value = "";
            }
          }
        })
        .catch(() => {});
    },
    getYarnValuesList() {
      if (this.detail.yarn_sub_id) {
        this.$api
          .getAPI({
            _action: "yarn-values/" + this.detail.yarn_sub_id,
          })
          .then((res) => {
            this.valueList = res.list;
          })
          .catch(() => {});
      }
    },
    setName(type) {
      this.detail.name = "";
      if (type == "YARN") {
        this.detail.yarn_sub_id = "";
        this.subYarn = {};
      }
      for (var i = 0; i < this.yarnList.length; i++) {
        if (this.yarnList[i].id == this.detail.yarn_id) {
          this.detail.name = this.yarnList[i].name;
          this.yarn_name = this.yarnList[i].name;
        }
      }

      for (var j = 0; j < this.yarnSubList.length; j++) {
        if (this.yarnSubList[j].id == this.detail.yarn_sub_id) {
          this.detail.name = this.detail.name + " " + this.yarnSubList[j].name;
          if (type == "SUB_YARN") {
            this.subYarn = this.yarnSubList[j];
            this.getYarnValuesList();
            this.getCompositionList();
          }
        }
      }

      if (this.detail.yarn_id && type == "YARN") {
        this.parent_id = this.detail.yarn_id;
        this.getYarnList("SUB_YARN");
      }
    },
    signup() {
      var finalList = [];
      for (var i = 0; i < this.compositionlist.length; i++) {
        if (this.compositionlist[i].value && this.compositionlist[i].value > 0) {
          finalList.push(this.compositionlist[i]);
        }
      }
      this.detail.list = finalList;
      this.$api
        .postAPI({
          _action: "seller/product",
          _body: this.detail,
          _buttonId: "signup-button",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          router.push("/home");
        })
        .catch(() => {});
    },
  },
};
</script>

