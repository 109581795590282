<template>
  <div class="page-content">
    <div class="row mb-4 justify-content-center">
      <div class="col-lg-12">
        <div class="row mb-md-4 mb-2 align-items-center">
          <div class="col-md-6 text-md-left text-center">
            <h2 class="page-title mb-md-0">
              <a class="cursor-pointer" v-on:click="this.$router.push('/order')">
                <img src="/images/back-arrow.svg" width="17" class="mr-2 align-top" alt="" /></a>Complete Dispatch Details
            </h2>
          </div>
          <div class="col-md-6 text-right d-flex justify-content-end"></div>
        </div>
        <div class="card mb-4 rounded-l border-0 shadow-sm">

          <form class="form-style floating-form">
            <div class="row justify-content-center">
              <div class="col-md-11">
                <div class="row mt-4">
                  <div class="col-6 text-center">
                    <div class="font-medium text-secondary">
                      <h5> Booking No. &nbsp; {{ detail.booking.booking_no }}</h5>
                    </div>
                  </div>
                  <div class="col-6 text-center">
                    <div class="font-medium text-secondary">
                      <h5> Order No. &nbsp; {{ detail.order_no }} </h5>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="mt-2">
                  <div class="mb-4">
                    <div class="row justify-content-center">
                      <div class="col-md-12">
                        <Form @submit="signup" class="form-style floating-form">
                          <div class="row">
                            <div class="col-md-6">
                              <h3 class="font-20 mb-4">
                                Order Details & Shipment Details
                              </h3>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <div class="mb-3">
                                <div class="">
                                  <label for="dispatch_on">Dispatch On</label>
                                  <Field type="dispatch_on" id="dispatch_on" name="dispatch_on" class="form-control"
                                    :class="broker_note.dispatch_on ? 'filled' : ''
                                      " v-model="broker_note.dispatch_on" disabled="true" />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="mb-3">
                                <div class="">
                                  <label for="invoice_no">Invoice No.<span class="text-danger">*</span></label>
                                  <Field type="invoice_no" id="invoice_no" name="invoice_no" placeholder="Invoice No."
                                    class="form-control" :class="broker_note.invoice_no ? 'filled' : ''
                                      " v-model="broker_note.invoice_no" rules="required:invoice no"
                                    :validateOnInput="true" />
                                </div>
                                <ErrorMessage name="invoice_no" class="validation-msg mt-1" />
                              </div>
                            </div>

                            <div class="col-md-6">
                              <div class="mb-3">
                                <div class="">
                                  <label for="driver_number">Driver Number<span class="text-danger">*</span></label>
                                  <Field type="driver_number" id="driver_number" name="driver_number" class="form-control"
                                    placeholder="Driver Number" :class="broker_note.driver_number ? 'filled' : ''
                                      " v-model="broker_note.driver_number"
                                    rules="required:driver number|phone:driver number" :validateOnInput="true" />
                                </div>
                                <ErrorMessage name="driver_number" class="validation-msg mt-1" />
                              </div>
                            </div>

                            <div class="col-md-6">
                              <div class="mb-3">
                                <div class="">
                                  <label for="transporter_name">Transporter Name<span class="text-danger">*</span></label>
                                  <Field type="transporter_name" id="transporter_name" name="transporter_name"
                                    placeholder="Transporter Name" class="form-control" :class="broker_note.transporter_name ? 'filled' : ''
                                      " v-model="broker_note.transporter_name" rules="required:transporter name"
                                    :validateOnInput="true" />
                                </div>
                                <ErrorMessage name="transporter_name" class="validation-msg mt-1" />
                              </div>
                            </div>

                            <div class="col-md-6">
                              <div class="mb-3">
                                <div class="">
                                  <label for="lr_number">L.R. Number<span class="text-danger">*</span></label>
                                  <Field type="lr_number" id="lr_number" name="lr_number" placeholder="L.R. Number"
                                    class="form-control" :class="broker_note.lr_number ? 'filled' : ''
                                      " v-model="broker_note.lr_number" rules="required:lr number"
                                    :validateOnInput="true" />
                                </div>
                                <ErrorMessage name="lr_number" class="validation-msg mt-1" />
                              </div>
                            </div>

                            <div class="col-md-6">
                              <div class="mb-3">
                                <div class="">
                                  <label for="vehicle_number">Vehicle Number<span class="text-danger">*</span></label>
                                  <Field type="vehicle_number" id="vehicle_number" name="vehicle_number"
                                    placeholder="Vehicle Number" class="form-control" :class="broker_note.vehicle_number ? 'filled' : ''
                                      " v-model="broker_note.vehicle_number" rules="required:vehicle number"
                                    :validateOnInput="true" />
                                </div>
                                <ErrorMessage name="vehicle_number" class="validation-msg mt-1" />
                              </div>
                            </div>

                            <div class="col-md-6">
                              <div class="mb-3">
                                <div class="">
                                  <label for="net_quantity">Net Quantity (in Kg)<span class="text-danger">*</span></label>
                                  <Field type="net_quantity" id="net_quantity" name="net_quantity" class="form-control"
                                    placeholder="Net Quantity" :class="broker_note.net_quantity ? 'filled' : ''
                                      " v-model="broker_note.net_quantity" rules="required:net quantity|numeric"
                                    :validateOnInput="true" />
                                </div>
                                <ErrorMessage name="net_quantity" class="validation-msg mt-1" />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="mb-3">
                                <div class="">
                                  <label for="invoice_amount">Net Invoice Amount<span class="text-danger">*</span></label>
                                  <Field type="invoice_amount" id="invoice_amount" name="invoice_amount"
                                    class="form-control" placeholder="Invoice Amount" :class="broker_note.invoice_amount ? 'filled' : ''
                                      " v-model="broker_note.invoice_amount" rules="required:invoice amount|numeric"
                                    :validateOnInput="true" />
                                </div>
                                <ErrorMessage name="invoice_amount" class="validation-msg mt-1" />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <h3 class="font-18">Billing Address</h3>
                            </div>
                          </div>
                          <div class="card mb-3">
                            <div class="card-body">
                              <div>
                                {{
                                  detail.booking.buyer.billing_address.address
                                }},<br />{{
  detail.booking.buyer.billing_address.district
    .name
}},{{
  detail.booking.buyer.billing_address.state
    .name
}}
                              </div>
                            </div>
                          </div>
                          <h3 class="font-18">Shipping/Delivery Address</h3>
                          <div class="card mb-3">
                            <div class="card-body">
                              <div>
                                {{
                                  detail.booking.bid.delivery_address.address
                                }},<br />{{
  detail.booking.bid.delivery_address.district
    .name
}},{{
  detail.booking.bid.delivery_address.state
    .name
}}
                              </div>
                            </div>
                          </div>
                          <div class="input-group mb-3">
                            <div class="custom-file">
                              <input type="file" class="custom-file-input" id="inputGroupFile02"
                                @change="onChange($event)" />
                              <label class="custom-file-label" for="inputGroupFile02"
                                aria-describedby="inputGroupFileAddon02">Upload Invoice</label>
                            </div>
                          </div>

                          <div class="input-group mb-3">
                            <div class="custom-file">
                              <input type="file" class="custom-file-input" id="inputGroupFile01"
                                @change="onChange1($event)" />
                              <label class="custom-file-label" for="inputGroupFile01"
                                aria-describedby="inputGroupFileAddon01">L.R Copy or Transporter Copy</label>
                            </div>
                          </div>

                          <div class="col-md-12 text-center mt-3">
                            <button class="btn btn-brand-1 mb-3" id="signup-button">
                              Save and Dispatch
                            </button>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import ShareService from "../../services/ShareService";
import $ from "jquery";
import router from "../../router";
export default {
  components: { Form, Field, ErrorMessage },
  data() {
    return {
      user_type: localStorage.getItem("user_type"),
      designation: localStorage.getItem("designation"),
      broker_view: localStorage.getItem("broker_view"),
      detail: {
        order_no: "",
        booking: {
          trade: { product: {} },
          bid: {
            payment_term: {},
            delivery_address: { state: {}, district: {} },
          },
          buyer: {
            billing_address: { state: {}, district: {} },
            delivery_address: { state: {}, district: {} },
          },
        },
      },
      orderId: this.$route.params.orderId,
      bookingId: this.$route.params.bookingId,
      broker_note: { dispatch_on: "" },
      uuid: "",
    };
  },
  mounted() {
    this.getDetail();

    $(
      ".floating-form-group input,.floating-form-group textarea,.floating-form select"
    ).change(function () {
      if ($(this).val() != "") {
        $(this).addClass("filled");
      } else {
        $(this).removeClass("filled");
      }
    });

    $("#inputGroupFile02").on("change", function () {
      //get the file name
      var fileName = $(this).val();
      //replace the "Choose a file" label
      $(this).next(".custom-file-label").html(fileName);
    });

    var date = new Date();
    var month = date.getMonth();
    var day = date.getDate();
    this.broker_note.dispatch_on =
      (day < 10 ? "0" + day : day) +
      "-" +
      (month < 10 ? "0" + month : month) +
      "-" +
      date.getFullYear();
  },
  methods: {
    getDetail() {
      this.$api
        .getAPI({
          _action:
            "/seller/booking/" + this.bookingId + "/order/" + this.orderId,
        })
        .then((res) => {
          this.detail = res.info;
        })
        .catch(() => { });
    },
    onChange(e) {
      let files = e.target.files;
      this.file = files[0];
    },
    onChange1(e) {
      let file = e.target.files;
      this.copy_file = file[0];
    },
    signup() {
      if (!this.file) {
        this.$notify({
          type: "error",
          text: "Please select invoice",
        });
        return false;
      }

      if (!this.copy_file) {
        this.$notify({
          type: "error",
          text: "Please select L.R Copy or Transporter Copy",
        });
        return false;
      }

      var date = new Date();
      var month = date.getMonth();
      var day = date.getDate();
      this.broker_note.dispatch_at =
        date.getFullYear() +
        "-" +
        (month < 10 ? "0" + month : month) +
        "-" +
        (day < 10 ? "0" + day : day);

      this.$api
        .putAPI({
          _action:
            "/seller/booking/" + this.bookingId + "/order/" + this.orderId,
          _body: this.broker_note,
          _buttonId: "signup-button",
        })
        .then((res) => {
          this.uuid = res.id;
          if (this.file) {
            this.uploadImage();
          }
        })
        .catch(() => { });
    },
    uploadImage() {
      this.$api
        .uploadImageAPI({
          _action: "seller/order/" + this.orderId + "/invoice",
          _key: "invoice",
          _file: this.file,
        })
        .then(() => {
          if (this.copy_file) {
            this.uploadCopy();
          }
        })
        .catch(() => { });
    },

    uploadCopy() {
      this.$api
        .uploadImageAPI({
          _action: "seller/order/" + this.orderId + "/lr_copy",
          _key: "lr_copy",
          _file: this.copy_file,
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          ShareService.setMapValue("order_status", "OPEN");
          router.push("/order");
        })
        .catch(() => { });
    },
  },
};
</script>

