<template>
  <div class="page-content">
    <div class="row mb-4 justify-content-center">
      <div class="col-lg-12">
        <div class="row mb-md-4 mb-2 align-items-center">
          <div class="col-md-6 text-md-left text-center">
            <h2 class="page-title mb-md-0">
              <a class="cursor-pointer" v-on:click="this.$router.go(-1)">
                <img
                  src="/images/back-arrow.svg"
                  width="17"
                  class="mr-2 align-top"
                  alt="" /></a
              >Add Vehicle
            </h2>
          </div>
          <div class="col-md-6 text-right d-flex justify-content-end"></div>
        </div>
        <div class="">
          <!-- card mb-4 rounded-l border-0 shadow-sm -->
          <div class="">
            <!--card-body px-md-5-->
            <form class="form-style floating-form">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-12 text-left mb-2">
                      <h5 class="font-medium text-secondary">
                        {{ detail.booking_no }}
                      </h5>
                    </div>
                  </div>
                  <div class="card mb-3">
                    <div class="card-header" style="border-radius: 0">
                      <div class="row">
                        <div class="col-4"><b>Vehicle</b></div>
                        <div class="col-4"><b>Quantity (in Kg)</b></div>
                        <div class="col-4"></div>
                      </div>
                    </div>
                    <div
                      class="card-body"
                      style="border-radius: 0"
                      v-for="(data, index) in detail.orders"
                      :key="index"
                    >
                      <div class="row">
                        <div class="col-4 mb-2">{{ index + 1 }}</div>
                        <div class="col-4 mb-2">
                          <input
                            type="text"
                            class="form-control"
                            style="width: 110px"
                            :value="data.quantity"
                            disabled
                          />
                        </div>
                        <div class="col-4 mb-2 text-right">
                          <button type="button" class="btn btn-brand-1">
                            {{ data.order_no }}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      class="card-body"
                      v-for="(data, index) in orderList"
                      :key="index"
                    >
                      <div class="row">
                        <div class="col-4">
                          {{ detail.orders.length + (index + 1) }}
                        </div>
                        <div class="col-4">
                          <input
                            type="number"
                            class="form-control"
                            style="width: 110px"
                            v-model="data.quantity"
                          />
                        </div>
                        <div class="col-4 text-right">
                          <button
                            type="button"
                            class="btn btn-brand-1"
                            :id="'vehicle_' + index"
                            @click="openOrder(index)"
                            v-if="
                              designation == 'PRIMARY_OWNER' ||
                              designation == 'SECONDERY_OWNER'
                            "
                          >
                            Open Order
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="text-right mr-2" v-if="detail.avail_open_qty > 0">
                    <button type="button" class="btn btn-brand-1" @click="addOrder()">
                      + Add
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AddVehicle",
  components: {},
  data() {
    return {
      user_type: localStorage.getItem("user_type"),
      designation: localStorage.getItem("designation"),
      broker_view: localStorage.getItem("broker_view"),
      detail: { booking_no: "", avail_open_qty: 0, orders: [], product: {} },
      uuid: this.$route.params.uuid,
      orderList: [],
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.$api
        .getAPI({
          _action: "/seller/booking/" + this.uuid,
        })
        .then((res) => {
          this.detail = res;
          console.log(this.detail);
          if (this.detail.avail_open_qty > 0) {
            this.addOrder();
          }
        })
        .catch(() => {});
    },
    openOrder(index) {
      if (this.orderList[index].quantity < 1) {
        this.$notify({
          type: "error",
          text: "Quantity should be greater then 0",
        });
        return false;
      }

      this.$api
        .postAPI({
          _action: "/seller/booking/" + this.uuid + "/order",
          _body: { quantity: this.orderList[index].quantity },
          _buttonId: "vehicle_" + index,
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.getDetail();
          this.orderList = [];
        })
        .catch(() => {});
    },
    addOrder() {
      var obj = { quantity: "" };
      this.orderList.push(obj);
    },
  },
};
</script>

