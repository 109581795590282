import moment from "moment";
import WebService from "./WebService";
import Store from "./StoreService";
import router from "../router";
import ShareService from "./ShareService";
class HelperService {
  sorting(sortBy, id) {
    var orderBy = "ASC";
    var className = "sorting_asc";
    var temp = document.getElementById(id);
    if (temp) {
      if (temp.className.includes("sorting_asc")) {
        className = "sorting_desc";
      }
      if (className == "sorting_asc") {
        temp.classList.add("sorting_asc");
        temp.classList.remove("sorting_desc");
        orderBy = "ASC";
      } else if (className == "sorting_desc") {
        temp.classList.add("sorting_desc");
        temp.classList.remove("sorting_asc");
        orderBy = "DESC";
      }
    }
    var obj = {
      order_by: orderBy,
      sort_by: sortBy,
    };
    return obj;
  }

  getFormatedTime(t) {
    return moment(t).format("hh:mm A");
  }

  getFormatedDateAndTime(dt) {
    // return moment.utc(dt).local().format("DD/MM/YYYY hh:mm A");
    return moment(dt).format("DD/MM/YYYY hh:mm A");
  }

  getFormatedDate(dt) {
    return moment.utc(dt).local().format("DD/MM/YYYY");
    // return moment(dt).format("DD/MM/YYYY");
  }

  sendFormatedDate(d, type) {
    if (type == "date") {
      return moment(d).format("YYYY-MM-DD");
    } else if (type == "month_year") {
      return moment(d).format("YYYY-MM");
    } else if (type == "year") {
      return moment(d).format("YYYY");
    } else {
      return moment(d).format("YYYY-MM-DD");
    }
  }

  allowOnlyNumericValue(e) {
    var numbers = /^[0-9]$/;
    if (!e.key.match(numbers) && e.keyCode != 8) {
      e.preventDefault();
      return false;
    }
  }

  getTitleCase(value) {
    if (!value) {
      return "";
    } else {
      var str = "";
      var arr = value.split("_");
      for (var i in arr) {
        if (i == 0) {
          str =
            arr[i].charAt(0).toUpperCase() + arr[i].substring(1).toLowerCase();
        } else {
          str +=
            "" +
            arr[i].charAt(0).toUpperCase() +
            arr[i].substring(1).toLowerCase();
        }
      }
      return str;
    }
  }
  getSummary() {
    WebService.getAPI({
        _action: "summary",
      })
      .then((res) => {
        Store.commit("setSummary", res);
      })
      .catch(() => {});
  }

  checkAddress(user) {
    if (
      user.user_type == "SELLER" &&
      !user.user_companies[0].company.billing_address
    ) {
      router.push("/add-address");
    } else if (
      (user.user_type == "BUYER" || user.user_type == "BROKER") &&
      !user.user_companies[0].company.billing_address
    ) {
      ShareService.setMapValue("address_type", 'BILLING');
      router.push("/add-address");
    } else if (
      (user.user_type == "BUYER" || user.user_type == "BROKER") &&
      !user.user_companies[0].company.delivery_address
    ) {
      ShareService.setMapValue("address_type", 'SHIPPING');
      router.push("/add-address");
    }
  }
}

export default new HelperService({});