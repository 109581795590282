<template>
  <div>
    <header class="p-md-4 p-3 shadow-sm bg-white index-header position-fixed w-100" style="z-index: 1">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-md-3 col-3">
            <img src="/images/yarnmart-Logo.svg" width="200" height="70" class="img-fluid" />
          </div>
        </div>
      </div>
    </header>
    <main role="main">
      <div id="main-wrapper " class="login-wrap pt-0">
        <div class="container-fluid h-100">
          <div class="row justify-content-center min-vh-100 align-items-center">
            <div class="col-md-4">
              <div class="card">
                <div class="card-body text-center">
                  <h4 class="font-18 mb-4">Select who are you?</h4>
                  <div>
                    <button type="button" class="btn btn-brand-1 w-100 mb-3" @click="setUserType('BUYER')">
                      Buyer
                      <span class="float-right"><i class="fa fa-arrow-circle-right" aria-hidden="true"></i></span>
                    </button>
                    <button type="button" class="btn btn-brand-1 w-100 mb-3" @click="setUserType('SELLER')">
                      Seller
                      <span class="float-right"><i class="fa fa-arrow-circle-right" aria-hidden="true"></i></span>
                    </button>
                    <button type="button" class="btn btn-brand-1 w-100 mb-3" @click="setUserType('BROKER')">
                      Dealer
                      <span class="float-right"><i class="fa fa-arrow-circle-right" aria-hidden="true"></i></span>
                    </button>
                    <button type="button" class="btn btn-brand-1 w-100 mb-3" @click="setUserType('IMPOTER')">
                      Impoter
                      <span class="float-right"><i class="fa fa-arrow-circle-right" aria-hidden="true"></i></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import router from "../../router";
export default {
  name: "SelectType",
  components: {},
  data() {
    return {};
  },
  mounted() {
    localStorage.clear();
  },
  methods: {
    setUserType(user_type) {
      if (user_type == 'IMPOTER') {
        localStorage.setItem("user_type", 'SELLER');
        localStorage.setItem("is_importer", 'Y');
      } else {
        localStorage.setItem("user_type", user_type);
        localStorage.setItem("is_importer", 'N');
      }
      router.push("/login");
    },
  },
};
</script>

